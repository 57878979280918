export enum RecurranceType {
  none,
  daily,
  weeklyOnDay,
  monthlyOnDay,
  anuallyOnDay,
  everyWeekday,
  custom
}
export interface IMeetingData {
  createdDate: Date;
  visitingSubDivisionId: Array<string>;
  hostingSubDivisionId: string;
  meetingType: string;
  meetingDate: any;
  meetingTime: string;
  isRepeating: boolean;
  repeatingNumber: number;
  repeatBy: string;
  repeatOn: Array<number>;
  isEnding: boolean;
  endOn?: any;
  endAfter: number;
  events: Array<Date>;
  status?: string;
  isParent: boolean;
  parentID?: string;
  recurrenceDetail: string;
  endType: string;
  monthlyRepeatingType: string;
}

export class MeetingData implements IMeetingData {
  $key: string;
  createdDate: Date;
  visitingSubDivisionId: Array<string>;
  hostingSubDivisionId: string;
  meetingType: string;
  meetingDate: any;
  isRepeating: boolean;
  repeatingNumber: number;
  repeatBy: string;
  repeatOn: Array<number>;
  isEnding: boolean;
  endOn?: any;
  endAfter: number;
  events: Array<Date>;
  recurrenceDetail: string;
  status?: string;
  isParent: boolean;
  parentID?: string;
  meetingTime: string;
  endType: string;
  monthlyRepeatingType: string;

  constructor(data: IMeetingData, id: string) {
    this.$key = id;
    this.status = data.status;
    this.meetingTime = data.meetingTime;
    this.isParent = data.isParent;
    this.parentID = data.parentID
    this.recurrenceDetail = data.recurrenceDetail;
    this.createdDate = data.createdDate;
    this.visitingSubDivisionId = data.visitingSubDivisionId;
    this.hostingSubDivisionId = data.hostingSubDivisionId;
    this.meetingType = data.meetingType;
    this.meetingDate = data.meetingDate.toDate();
    this.isRepeating = data.isRepeating;
    this.repeatingNumber = data.repeatingNumber;
    this.repeatBy = data.repeatBy;
    this.repeatOn = data.repeatOn;
    this.isEnding = data.isEnding;
    this.endOn = data.endOn;
    this.endAfter = data.endAfter;
    this.events = data.events;
    this.endType = data.endType;
    this.monthlyRepeatingType = data.monthlyRepeatingType;
  }
}
