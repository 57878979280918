import { Component, Injectable, Input, Output, EventEmitter} from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class MeetingMenuService {
  public isShowCalender = false;
  @Output() fire: EventEmitter<any> = new EventEmitter();
  @Output() Filterfire: EventEmitter<any> = new EventEmitter();
  @Output() AddedMeetingfire: EventEmitter<any> = new EventEmitter();
  constructor() { }

  showHideCalender() {
    return this.fire;
  }

  changeCanlenderView() {
    this.isShowCalender = !this.isShowCalender;
    this.fire.emit(this.isShowCalender);
  }

  filterData() {
    return this.Filterfire;
  }
  sendFilterDataType(result: any) {
    console.log("filter by result",result)
    this.Filterfire.emit(result);
  }

  meetingAddEmitter() {
    return this.AddedMeetingfire;
  }

  meetingAdded() {
    this.AddedMeetingfire.emit(true);
  }
}

export enum MeetingSortType {
  all,
  hosting,
  visiting,
  meetingType
}
