import { Component, OnInit, Inject } from '@angular/core';
import {
  FormGroup,
  FormBuilder,
  AbstractControl
} from '@angular/forms';
import { AngularFireFunctions } from '@angular/fire/functions';
import { NgxSpinnerService } from 'ngx-spinner';
import { LocalityService } from '../../locality/locality.service';
import { Locality, ILocality } from '../../_models/locality';
import { SubdivisionService } from '../../subdivision/subdivision.service';
import { Subdivision, ISubdivision } from '../../_models/subDivision';
import { MAT_DIALOG_DATA } from '@angular/material';
import { User } from '../../_models/user';
import { AngularFirestore } from '@angular/fire/firestore';
import { FirebaseConstant } from 'src/app/util/database';
import { ToastrService } from 'ngx-toastr';
@Component({
  selector: 'app-dialog-add-user',
  templateUrl: './dialog-add-user.component.html',
  styleUrls: ['./dialog-add-user.component.scss']
})
export class DialogAddUserComponent implements OnInit {
  public userInfo: FormGroup;
  localities: Locality[] = [];
  subDivision: Subdivision[] = [];
  emailAddressExists: boolean;
  public show: boolean = false;
  public reShow: boolean = false

  constructor(
    private formBuilder: FormBuilder,
    private fns: AngularFireFunctions,
    private spinner: NgxSpinnerService,
    private localityService: LocalityService,
    private afs: AngularFirestore,
    private subDivisionService: SubdivisionService,
    private toastr: ToastrService,
    @Inject(MAT_DIALOG_DATA) public user: User
  ) { }

  ngOnInit() {
    if (this.user) {
      this.setupForm(this.user);
    } else {
      this.setupForm();
    }
    this.fetchLocality();
  }
  setupForm(userData?: User) {
    if (userData) {
      this.fetchSpecificSubDivisions(userData.localityId);
    }
    this.userInfo = this.formBuilder.group({
      firstName: [userData ? this.user.firstName : ''],
      lastName: [userData ? this.user.lastName : ''],
      email: [userData ? this.user.email : ''],
      passwords: this.formBuilder.group(
        {
          password: [''],
          verifyPassword: ['']
        },
        { validator: this.passwordConfirming }
      ),
      localityId: [userData ? this.user.localityId : ''],
      subDivisionId: [userData ? this.user.subDivisionId : '']
    });
  }
  passwordConfirming(c: AbstractControl): { invalid: boolean } {
    if (c.get('password').value !== c.get('verifyPassword').value) {
      return { invalid: true };
    }
  }
  updateUser() {
    if (this.user.firstName !== this.userInfo.value.firstName) {
      this.saveFirstNameToDb(this.userInfo.value.firstName, this.user.key$);
    }
    if (this.user.lastName !== this.userInfo.value.lastName) {
      this.saveLastNameToDb(this.userInfo.value.lastName, this.user.key$);
    }
    if (this.user.localityId !== this.userInfo.value.localityId) {
      this.savelocalityIdToDb(this.userInfo.value.localityId, this.user.key$);
    }
    if (this.user.subDivisionId !== this.userInfo.value.subDivisionId) {
      this.saveSubDivisionIdToDb(
        this.userInfo.value.subDivisionId,
        this.user.key$
      );
    }
  }
  saveFirstNameToDb(fName: string, id) {
    this.afs
      .collection(FirebaseConstant.USER_COLL_REF)
      .doc(id)
      .update({ firstName: fName })
      .then(() => {
        this.toastr.success('Success', `First Name Edited to ${fName} Successfully`);
      })
      .catch(err => {
        alert(err);
      });
  }
  saveLastNameToDb(lName: string, id) {
    this.afs
      .collection(FirebaseConstant.USER_COLL_REF)
      .doc(id)
      .update({ lastName: lName })
      .then(() => {
        this.toastr.success('Success', `Last Name Edited to ${lName} Successfully`);

      })
      .catch(err => {
        alert(err);
      });
  }
  savelocalityIdToDb(lId: string, id) {
    this.afs
      .collection(FirebaseConstant.USER_COLL_REF)
      .doc(id)
      .update({ localityId: lId })
      .then(() => {
        this.toastr.success('Success', `Locality of User is Edited Successfully`);
      })
      .catch(err => {
        alert(err);
      });
  }
  saveSubDivisionIdToDb(sId: string, id) {
    this.afs
      .collection(FirebaseConstant.USER_COLL_REF)
      .doc(id)
      .update({ subDivisionId: sId })
      .then(() => {
        this.toastr.success('Success', `Sub Division of User is Edited Successfully`);
      })
      .catch(err => {
        alert(err);
      });
  }

  fetchSpecificSubDivisions(id: string) {
    this.subDivision = [];
    this.subDivisionService
      .fetchSubDivisionsOfSpecificLocality(id)
      .subscribe(res => {
        res.forEach(doc => {
          this.subDivision.push(
            new Subdivision(doc.data() as ISubdivision, doc.id)
          );
        });
      });
  }
  fetchLocality() {
    this.localityService.fetchLocalities().subscribe(res => {
      res.forEach(doc => {
        this.localities.push(
          new Locality(doc.payload.doc.data() as ILocality, doc.payload.doc.id)
        );
      });
    });
  }
  addUser() {
    this.spinner.show();
    this.userInfo.value.password = this.userInfo.value.passwords.password;
    this.fns
      .httpsCallable('createUser')(this.userInfo.value)
      .subscribe(res => {
        this.spinner.hide();
        this.toastr.success('Success', `A New User is Added Successfully`);
      });
  }

  checkEmailAlreadyExist(email) {
    this.fns.httpsCallable('checkEmail')(email).subscribe((res) => {
      if (res.status === '404') {
        console.log('exist')
        this.emailAddressExists = true
      }
      else {
        console.log('doesnot')
        this.emailAddressExists = false
      }
    })
  }

  showHidePwd() {
    this.show = !this.show;

  }

  reShowHidePwd() {
    this.reShow = !this.reShow
  }


}



export interface Food {
  value: string;
  viewValue: string;
}
