export interface IUser {
  email: string;
  firstName: string;
  lastName: string;
  status: string;
  userType: string;
  localityId?: string;
  subDivisionId?: string;
  fcmToken?: string;
}
export class User implements IUser {
  key$: string;
  email: string;
  firstName: string;
  lastName: string;
  status: string;
  userType: string;
  fullName: string;
  localityId?: string;
  subDivisionId?: string;
  fcmToken?: string;
  constructor(admin: any, id: string) {
    this.email = admin.email;
    this.firstName = admin.firstName;
    this.lastName = admin.lastName;
    this.status = admin.status;
    this.userType = admin.userType;
    this.key$ = id;
    this.fullName = `${admin.firstName} ${admin.lastName}`;
    this.subDivisionId = admin.subDivisionId;
    this.localityId = admin.localityId;
    this.fcmToken = admin.fcmToken;
  }
}
