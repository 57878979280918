import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DashboardComponent } from './dashboard.component';
import { DashboardRoutingModule } from './dashboard-routing.module';
import { MeetingComponent } from './meeting/meeting.component';
import { LocalityComponent } from './locality/locality.component';
import { SubdivisionComponent } from './subdivision/subdivision.component';
import { NotificationComponent } from './notification/notification.component';
import { SettingComponent } from './setting/setting.component';
import { AdminComponent } from './admin/admin.component';
import { UserComponent } from './user/user.component';
import { HomeComponent } from './home/home.component';
import { AngularFireFunctionsModule } from '@angular/fire/functions';
import { AgmCoreModule } from '@agm/core';
import { DateRangePickerModule } from '@uiowa/date-range-picker';
import {
  MatTableModule,
  MatPaginatorModule,
  MatDialogModule,
  MatButtonModule,
  MatSelectModule,
  MatDatepickerModule,
  MatNativeDateModule,
  MatRadioModule,
  MatSortModule,
  MatMenuModule,
  MatIconModule,
  MatExpansionModule,
  MatCheckboxModule,
  MatChipsModule,
  
} from '@angular/material';
import { MatTableExporterModule } from 'mat-table-exporter';
import { DialogAddAdminComponent } from './dialogBox/dialog-add-admin/dialog-add-admin.component';
import { DialogAddUserComponent } from './dialogBox/dialog-add-user/dialog-add-user.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { DialogAddLocalityComponent } from './dialogBox/dialog-add-locality/dialog-add-locality.component';
import { DialogAddSubDivisionComponent } from './dialogBox/dialog-add-sub-division/dialog-add-sub-division.component';
// tslint:disable-next-line:max-line-length
import { DialogShowAssociatedSubDivsComponent } from './dialogBox/dialog-show-associated-sub-divs/dialog-show-associated-sub-divs.component';
import { DialogAddMeetingComponent } from './dialogBox/dialog-add-meeting/dialog-add-meeting.component';
import { NgxMaterialTimepickerModule } from 'ngx-material-timepicker';
import { DialogMeetingRecurrenceComponent } from './dialogBox/dialog-meeting-recurrence/dialog-meeting-recurrence.component';
import { CalenderViewComponent } from './template/calender-view/calender-view.component';
import { CalendarModule, DateAdapter } from 'angular-calendar';
import { adapterFactory } from 'angular-calendar/date-adapters/date-fns';
import { NgbModalModule } from '@ng-bootstrap/ng-bootstrap';
import { ViewSubDivisionComponent } from './view-sub-division/view-sub-division.component';
import { HttpClientModule } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { ConfirmationDialogComponent } from './dialogBox/confirmation-dialog/confirmation-dialog.component';
import { DeleteMetingDialogComponent } from './dialogBox/delete-meting-dialog/delete-meting-dialog.component';
import { EnterOldPasswordComponent } from './dialogBox/enter-old-password/enter-old-password.component';
import { AddMeetingTypeComponent } from './dialogBox/add-meeting-type/add-meeting-type.component';
import { DialogMatSelectComponent } from './dialogBox/dialog-mat-select/dialog-mat-select.component';
import { MultiSelectMatComponent } from './dialogBox/multi-select-mat/multi-select-mat.component';
import { DialogMeetingTypeComponent } from './dialogBox/dialog-meeting-type/dialog-meeting-type.component';
import { MeetingMenuComponent } from './template/meeting-menu/meeting-menu.component';
import { DialogFilterComponent } from './dialogBox/dialog-filter/dialog-filter.component';
import { MultiSelectMatOldComponent } from './dialogBox/multi-select-mat-old/multi-select-mat-old.component';
@NgModule({
  declarations: [
    DashboardComponent,
    MeetingComponent,
    LocalityComponent,
    SubdivisionComponent,
    NotificationComponent,
    SettingComponent,
    AdminComponent,
    UserComponent,
    HomeComponent,
    DialogAddAdminComponent,
    DialogAddUserComponent,
    DialogAddLocalityComponent,
    DialogAddSubDivisionComponent,
    DialogShowAssociatedSubDivsComponent,
    DialogAddMeetingComponent,
    DialogMeetingRecurrenceComponent,
    CalenderViewComponent,
    ViewSubDivisionComponent,
    ConfirmationDialogComponent,
    DeleteMetingDialogComponent,
    EnterOldPasswordComponent,
    AddMeetingTypeComponent,
    DialogMatSelectComponent,
    MultiSelectMatComponent,
    MultiSelectMatOldComponent,

    DialogMeetingTypeComponent,
    MeetingMenuComponent,
    DialogFilterComponent,
  ],
  imports: [
    CommonModule,
    DashboardRoutingModule,
    MatTableModule,
    MatPaginatorModule,
    MatDialogModule,
    MatButtonModule,
    MatSelectModule,
    MatRadioModule,
    FormsModule,
    MatSortModule,
    MatMenuModule,
    MatIconModule,
    MatChipsModule,
    MatTableExporterModule,
    ReactiveFormsModule,
    AngularFireFunctionsModule,
    AgmCoreModule.forRoot({
      apiKey: environment.g_apiKey,
      libraries: ['places']
    }),
    CalendarModule.forRoot({
      provide: DateAdapter,
      useFactory: adapterFactory
    }),
    NgbModalModule,
    MatDatepickerModule,
    MatNativeDateModule,
    MatExpansionModule,
    NgxMaterialTimepickerModule,
    HttpClientModule,
    MatCheckboxModule,
    DateRangePickerModule,
    
  ],
  entryComponents: [
    DialogAddAdminComponent,
    DialogAddUserComponent,
    DialogAddLocalityComponent,
    DialogAddSubDivisionComponent,
    DialogShowAssociatedSubDivsComponent,
    DialogAddMeetingComponent,
    DialogMeetingRecurrenceComponent,
    ConfirmationDialogComponent,
    DeleteMetingDialogComponent,
    EnterOldPasswordComponent,
    AddMeetingTypeComponent,
    DialogMatSelectComponent,
    MultiSelectMatComponent,
    MultiSelectMatOldComponent,
    DialogMeetingTypeComponent,
    DialogFilterComponent
  ]
})
export class DashboardModule {}
