import { Component, OnInit, ViewChild } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { MatDialog, TransitionCheckState } from '@angular/material';
import { AngularFireAuth } from '@angular/fire/auth';
import { DialogAddUserComponent } from '../dialogBox/dialog-add-user/dialog-add-user.component';
import { UserService } from './user.service';
import { User } from '../_models/user';
import { AngularFireFunctions } from '@angular/fire/functions';
import { NgxSpinnerService } from 'ngx-spinner';
import {
  DialogData,
  ConfirmationDialogComponent
} from '../dialogBox/confirmation-dialog/confirmation-dialog.component';
import { DELETE_USER_TITLE, DELETE_USER_BODY } from 'src/app/util/database';
import { LocalityService } from '../locality/locality.service';
import { SubdivisionService } from '../subdivision/subdivision.service';
import { Locality } from '../_models/locality';
import { Subdivision } from '../_models/subDivision';
import { IUser } from 'src/app/auth/model/user';
import { ToastrService } from 'ngx-toastr';
@Component({
  selector: 'app-user',
  templateUrl: './user.component.html',
  styleUrls: ['./user.component.scss']
})
export class UserComponent implements OnInit {
  public displayedColumns: string[] = [
    'fullName',
    'email',
    'localityId',
    'subDivisionId',
    'actionsColumn'
  ];
  public dataSourceForTable;
  public uniqueLocalityID: Set<string> = new Set();
  public uniqueSubDivisionID: Set<string> = new Set();
  public localities: Locality[] = [];
  public subdivisions: Subdivision[] = [];
  public userList: User[] = [];
  @ViewChild(MatPaginator, { static: false }) paginator: MatPaginator;
  constructor(
    public dialog: MatDialog,
    private userService: UserService,
    private localityService: LocalityService,
    private subdivisionService: SubdivisionService,
    private fns: AngularFireFunctions,
    private spinner: NgxSpinnerService,
    private toastr: ToastrService,
    private auth:AngularFireAuth,
  ) {}

  ngOnInit() {
    this.fetchUsers();
  }
  fetchUsers() {
    this.spinner.show();
    this.userService.fetchUsers().subscribe(res => {
      this.userList = [];
      res.forEach(doc => {
        const user = new User(doc.payload.doc.data() as IUser, doc.payload.doc.id);
        this.userList.push(user);
        this.uniqueSubDivisionID.add(user.subDivisionId);
        this.uniqueLocalityID.add(user.localityId);
      });
      this.getAllLocalities(() => {
        this.getAllSubdivisions(() => {
          this.setDataToTable(this.userList);
          this.spinner.hide();
        });
      });
    });
  }

  getAllSubdivisions(callback: () => void) {
    let count = 0;
    if (this.uniqueLocalityID.size === 0) {
      callback();
    } else {
      this.uniqueSubDivisionID.forEach(id => {
        this.subdivisionService.fetchSpecifcSubDivision(id).then(snap => {
          this.subdivisions.push(snap);
          count++;
          if (count === this.uniqueSubDivisionID.size) {
            callback();
          }
        });
      });
    }
  }
  getAllLocalities(callback: () => void) {
    let count = 0;
    if (this.uniqueLocalityID.size === 0) {
      callback();
    } else {
      this.uniqueLocalityID.forEach(id => {
        this.localityService.fetchLocality(id).then(snap => {
          this.localities.push(snap);
          count++;
          if (count === this.uniqueLocalityID.size) {
            callback();
          }
        });
      });
    }
  }

  deleteUser(user: User) {
    const dialogData: DialogData = { title: '', body: '', isDeleteAble: false };
    dialogData.title = DELETE_USER_TITLE;
    dialogData.body = DELETE_USER_BODY(user);
    dialogData.isDeleteAble = true;
    const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
      width: '400px',
      height: 'auto',
      data: dialogData
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result.isDeleteAble) {
        this.spinner.show();
        this.fns
          .httpsCallable('deleteUser')(user.key$)
          .subscribe(res => {
            this.spinner.hide();
            this.userList = [];
            this.fetchUsers();
          });
      }
    });
  }

  getSubdivision(id: string) {
    const subdivision = this.subdivisions.find(x => x.key$ === id);
    return subdivision.subDivisionName;
  }

  getLocality(id: string) {
    const locality = this.localities.find(x => x.key$ === id);
    return locality.localityName;
  }
  setDataToTable(listOfUser: User[]) {
    this.dataSourceForTable = new MatTableDataSource<User>(listOfUser);
    this.dataSourceForTable.paginator = this.paginator;
  }

  applyFilter(filterValue: string) {
    this.dataSourceForTable.filter = filterValue.trim().toLowerCase();
  }
  editUser(admin: User) {
    const dialogRef = this.dialog.open(DialogAddUserComponent, {
      width: '450px',
      minHeight: '200px',
      data: admin
    });
    dialogRef.afterClosed().subscribe(result => {
      this.userList = [];
      this.fetchUsers();
    });
  }
  addUser() {
    const dialogRef = this.dialog.open(DialogAddUserComponent, {
      width: '450px',
      minHeight: '200px'
    });
    dialogRef.afterClosed().subscribe(result => {
      this.userList = [];
      this.fetchUsers();
    });
  }

  resetPassword(email:string){
    this.spinner.show();
    this.auth.auth.sendPasswordResetEmail(email)
    .then(()=>{
      this.spinner.hide()
      this.toastr.success('Send Reset Link', `The Reset Link is Send To The ${email}`);
    })
    .catch((err)=>{
      this.spinner.hide()
      this.toastr.error('Error', err.message);
      console.log(err)
    })
  }


}
