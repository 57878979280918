import { Component, Inject, OnInit } from '@angular/core';
import { MeetingType, IMeetingType, filterByMeetingType } from '../../_models/meetingType';
import { MeetingService } from '../../meeting/meeting.service';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';

@Component({
  selector: 'app-dialog-meeting-type',
  templateUrl: './dialog-meeting-type.component.html',
  styleUrls: ['./dialog-meeting-type.component.scss']
})
export class DialogMeetingTypeComponent implements OnInit {
  public listOfMeetingType: MeetingType[] = [];
  public dataLoaded = false;
  public listOfCheckData: Set<MeetingType> = new Set();
  constructor(
    private dialogRef: MatDialogRef<DialogMeetingTypeComponent>,
    private meetingSerivices: MeetingService,
    @Inject(MAT_DIALOG_DATA) public selectedMeetingType = [],

  ) {}

  ngOnInit() {
    this.fetchMeetingType();
  
  }
  fetchMeetingType() {
    this.meetingSerivices.fetchAllMeetingType().subscribe(res => {
      res.forEach(doc => {
        this.listOfMeetingType.push(
          new filterByMeetingType(doc.data() as IMeetingType, doc.id, false)
        );
      });
      console.log(this.listOfMeetingType)

      console.log(this.selectedMeetingType)
      if(this.selectedMeetingType){
        this.selectedMeetingType.forEach((t)=>{
          this.listOfMeetingType.forEach((data,index)=>{
            if(data.key$== t.key$){
              this.listOfMeetingType[index] = t
              
              let check = {
                      checked: true,
                    }
              this.checkBoxClicked(check,t)
            }
          })
        })
      }
      this.dataLoaded = true;


    });

  }
  checkBoxClicked(event, subDivisionId) {
    if (event.checked) {
      subDivisionId.isChecked = true;
      this.listOfCheckData.add(subDivisionId);
    } else {
      // subDivisionId.ischecked = false;

      this.listOfCheckData.delete(subDivisionId);
    }
  }
  sendData() {
    this.dialogRef.close(this.listOfCheckData);
  }
}
