import { Component, OnInit, Inject } from '@angular/core';
import { LocalityService } from '../../locality/locality.service';
import { MAT_DIALOG_DATA } from '@angular/material';
import { Locality } from '../../_models/locality';
import { AngularFirestore } from '@angular/fire/firestore';
import { FirebaseConstant } from 'src/app/util/database';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-dialog-add-locality',
  templateUrl: './dialog-add-locality.component.html',
  styleUrls: ['./dialog-add-locality.component.scss']
})
export class DialogAddLocalityComponent implements OnInit {
  public localityName: string;
  constructor(
    private localityService: LocalityService,
    private afs: AngularFirestore,
    private spinner: NgxSpinnerService,
    private toastr: ToastrService,
    @Inject(MAT_DIALOG_DATA) public locality: Locality
  ) {}

  ngOnInit() {
    if (this.locality) {
      this.localityName = this.locality.localityName;
    }
  }
  updateLocality() {
    this.afs
      .collection(FirebaseConstant.LOCALITY_COLL_REF)
      .doc(this.locality.key$)
      .update({ localityName: this.localityName })
      .then(() => {
        this.toastr.success(
          'Success',
          'Successfully Updated Locality to ' + this.localityName
        );
      })
      .catch(err => {
        this.toastr.error('Error', err.message);
      });
  }
  addLocality() {
    if (this.localityName) {
      this.localityService
        .addLocality_(this.localityName)
        .then(res => {
          this.toastr.success(
            'Success',
            'A New Locality is Added Successfully'
          );
        })
        .catch(err => {
          this.toastr.error(
            'Error',
            `${err.message}`
          );
        });
    }
  }
}
