export interface IUser {
    email: string;
    password: string;
}
export class User implements IUser {
    email: string;
    password: string;
    constructor(data: any) {
        this.email = data.email;
        this.password = data.password;
    }
}
