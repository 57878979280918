import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import { FirebaseConstant, Status } from '../../util/database';
@Injectable({
  providedIn: 'root'
})
export class AdminService {
  constructor(private afs: AngularFirestore) {}
  fetchAdmins() {
    return this.afs
      .collection(FirebaseConstant.ADMIN_COLL_REF, ref =>
        ref.where('status', '==', Status.ACTIVE)
      )
      .snapshotChanges();
  }
  fetchSpecificAdmin(id:string){
    return this.afs
    .collection(FirebaseConstant.ADMIN_COLL_REF, ref =>
      ref.where('status', '==', Status.ACTIVE)
    ).doc(id)
    .get();
  }

  
}
