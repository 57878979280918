import { Injectable, Injector } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import { FirebaseConstant, Status } from 'src/app/util/database';
import { Subdivision, ISubdivision } from '../_models/subDivision';
@Injectable({
  providedIn: 'root'
})
export class SubdivisionService {
  static injector: Injector;
  constructor(private afs: AngularFirestore) {}
  addSubDivision_(subDivision: Subdivision) {
    subDivision.status = Status.ACTIVE;
    return this.afs
      .collection(FirebaseConstant.SUBDIVISION_COLL_REF)
      .add(subDivision);
  }
  updateSubDivision_(subDivision: Subdivision, id: string) {
    subDivision.status = Status.ACTIVE;
    return this.afs
      .collection(FirebaseConstant.SUBDIVISION_COLL_REF)
      .doc(id)
      .update(subDivision);
  }
  fetchSubDivisions() {
    return this.afs
      .collection(FirebaseConstant.SUBDIVISION_COLL_REF, ref =>
        ref.where('status', '==', Status.ACTIVE).orderBy('subDivisionName')
      )
      .snapshotChanges();
  }
  fetchAllSubDivisions() {
    return this.afs
      .collection(FirebaseConstant.SUBDIVISION_COLL_REF, ref =>
        ref.where('status', '==', Status.ACTIVE).orderBy('subDivisionName')
      )
      .get()
      .toPromise()
  }
  fetchSubDivisionsOfSpecificLocality(localityId: string) {
    return this.afs
      .collection(FirebaseConstant.SUBDIVISION_COLL_REF, ref =>
        ref
          .where('status', '==', Status.ACTIVE)
          .where('localityId', '==', localityId)
      )
      .get();
  }
  fetchSubDivisionsOfSpecificLocalitySnap(localityId: string) {
    return this.afs
      .collection(FirebaseConstant.SUBDIVISION_COLL_REF, ref =>
        ref
          .where('status', '==', Status.ACTIVE)
          .where('localityId', '==', localityId)
      )
      .snapshotChanges();
  }
  deleteSubDivision(id: string) {
    return this.afs
      .collection(FirebaseConstant.SUBDIVISION_COLL_REF)
      .doc(id)
      .update({ status: Status.DELETED });
  }
  fetchSpecifcSubDivision(id: string): Promise<Subdivision> {
    return this.afs
      .collection(FirebaseConstant.SUBDIVISION_COLL_REF)
      .doc(id)
      .get()
      .toPromise()
      .then(snap => {
        return new Subdivision(snap.data() as ISubdivision, snap.id);
      });
  }
  hasAssociatiedHostingMeeting(id: string): Promise<boolean> {
    return this.afs
      .collection(FirebaseConstant.Meeting_COLL_REF, ref =>
        ref
          .where('hostingSubDivisionId', '==', id)
          .where('status', '==', Status.ACTIVE)
      )
      .get()
      .toPromise()
      .then(snap => {
        if (snap.empty) {
          return false;
        } else {
          return true;
        }
      });
  }
  hasAssociatiedVisitingMeeting(id: string): Promise<boolean> {
    return this.afs
      .collection(FirebaseConstant.Meeting_COLL_REF, ref =>
        ref
          .where('visitingSubDivisionId', 'array-contains', id)
          .where('status', '==', Status.ACTIVE)
      )
      .get()
      .toPromise()
      .then(snap => {
        if (snap.empty) {
          return false;
        } else {
          return true;
        }
      });
  }
  hasAssociatiedHUser(id: string): Promise<boolean> {
    return this.afs
      .collection(FirebaseConstant.USER_COLL_REF, ref =>
        ref
          .where('subDivisionId', '==', id)
          .where('status', '==', Status.ACTIVE)
      )
      .get()
      .toPromise()
      .then(snap => {
        console.log(snap.size);
        console.log(snap.empty);
        if (snap.empty) {
          return false;
        } else {
          return true;
        }
      });
  }
}
