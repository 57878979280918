import { Component, OnInit, ViewChild, Inject } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { MatDialog, MAT_DIALOG_DATA } from '@angular/material';
import { DialogAddAdminComponent } from '../dialogBox/dialog-add-admin/dialog-add-admin.component';
import { AngularFireAuth } from '@angular/fire/auth';
import { AdminService } from './admin.service';
import { User, IUser } from '../_models/user';
import { AngularFireFunctions } from '@angular/fire/functions';
import { NgxSpinnerService } from 'ngx-spinner';
import { MatSort } from '@angular/material/sort';
import { DialogData, ConfirmationDialogComponent } from '../dialogBox/confirmation-dialog/confirmation-dialog.component';
import { DELETE_ADMIN_TITLE, DELETE_ADMIN_BODY } from 'src/app/util/database';
import { ToastrService } from 'ngx-toastr';
@Component({
  selector: 'app-admin',
  templateUrl: './admin.component.html',
  styleUrls: ['./admin.component.scss']
})
export class AdminComponent implements OnInit {
  displayedColumns: string[] = ['fullName', 'email', 'actionsColumn'];
  dataSourceForTable;
  @ViewChild(MatPaginator, { static: false }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: false }) sort: MatSort;
  constructor(
    public dialog: MatDialog,
    private adminService: AdminService,
    private fns: AngularFireFunctions,
    private spinner: NgxSpinnerService,
    private toastr: ToastrService
  ) {}

  ngOnInit() {
    this.fetchAdmin();
  }
  deleteAdmin(admin: User) {
    const dialogData: DialogData = { title: '', body: '', isDeleteAble: false};
    dialogData.title = DELETE_ADMIN_TITLE;
      dialogData.body = DELETE_ADMIN_BODY(admin);
      dialogData.isDeleteAble= true
    const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
      width: '400px',
      height: 'auto',
      data: dialogData
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result.isDeleteAble) {
        this.spinner.show()
        this.fns
          .httpsCallable('deleteAdmin')(admin.key$)
          .subscribe(res => {
            this.spinner.hide();   
          });
      }
    });
  }
  editAdmin(admin:User){
    const dialogRef = this.dialog.open(DialogAddAdminComponent, {
      width: '450px',
      minHeight: '200px',
      data:admin
    });
    dialogRef.afterClosed().subscribe(result => {
    });
  }
  fetchAdmin() {
    this.spinner.show()
    this.adminService.fetchAdmins().subscribe(res => {
      const listOfAdmin: User[] = res.map(doc => {
        return new User(doc.payload.doc.data() as IUser, doc.payload.doc.id);
      });
      this.setDataToTable(listOfAdmin);
    this.spinner.hide()
    });
  }
  setDataToTable(listOfAdmin: User[]) {
    this.dataSourceForTable = new MatTableDataSource<User>(listOfAdmin);
    this.dataSourceForTable.paginator = this.paginator;
    this.dataSourceForTable.sort = this.sort;

  }
  applyFilter(filterValue: string) {
    this.dataSourceForTable.filter = filterValue.trim().toLowerCase();
  }
  addAdmin() {
    const dialogRef = this.dialog.open(DialogAddAdminComponent, {
      width: '450px',
      minHeight: '200px'
    });
    dialogRef.afterClosed().subscribe(result => {
    });
  }
}
