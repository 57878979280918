import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import { FirebaseConstant, Status } from 'src/app/util/database';

@Injectable({
  providedIn: 'root'
})
export class UserService {
  constructor(private afs: AngularFirestore) {}
  fetchUsers() {
    return this.afs
      .collection(FirebaseConstant.USER_COLL_REF, ref =>
        ref.where('status', '==', Status.ACTIVE)
      ).snapshotChanges()
  }
  fetchSpecificSubDivisionUsers(subDivId: string) {
    return this.afs
      .collection(FirebaseConstant.USER_COLL_REF, ref =>
        ref
          .where('status', '==', Status.ACTIVE)
          .where('subDivisionId', '==', subDivId)
          .where("isNotificationEnabled","==",true)
      )
      .get();
  }
}
