// dev
// export const environment = {
//   production: false,
//   firebaseConfig: {
//     apiKey: "AIzaSyA8i8zFexWsS0BgkJt9KK506GgJhRpqHh8",
//     authDomain: "assemble-test-webo.firebaseapp.com",
//     databaseURL: "https://assemble-test-webo.firebaseio.com",
//     projectId: "assemble-test-webo",
//     storageBucket: "assemble-test-webo.appspot.com",
//     messagingSenderId: "858488748251",
//     appId: "1:858488748251:web:0f5d8bdee842ad1f2fb4fe",
//     measurementId: "G-TJX2GDCL8C",
//   },
//   g_apiKey: "AIzaSyAx5hdI0vqey34S0jkyiOU3RV6yWvalxSQ",
// };

// staging
// export const environment = {
//   production: false,
//   firebaseConfig: {
//     apiKey: "AIzaSyBhaXY6R4avkeXWvj7wD8cdI6ELMm9a_5Y",
//     authDomain: "staging-assemble.firebaseapp.com",
//     projectId: "staging-assemble",
//     storageBucket: "staging-assemble.appspot.com",
//     messagingSenderId: "324244155699",
//     appId: "1:324244155699:web:403757752bc29fb3cf6246",
//     measurementId: "G-SLW8ZC3YYB"
//   },
//   g_apiKey: "AIzaSyAx5hdI0vqey34S0jkyiOU3RV6yWvalxSQ",
//   apiUrl: "https://assemble.webo.dev/get_meeting_data?fbclient=staging"
// };

// live
export const environment = {
  production: false,
  firebaseConfig: {
    apiKey: "AIzaSyAwXrJ4awoUjUR0fWC1c14u-z4JZyxquwI",
    authDomain: "assemble-webo.firebaseapp.com",
    databaseURL: "https://assemble-webo.firebaseio.com",
    projectId: "assemble-webo",
    storageBucket: "assemble-webo.appspot.com",
    messagingSenderId: "494145345473",
    appId: "1:494145345473:web:87e86f1bbf10a56aa74140",
  },
  g_apiKey: "AIzaSyAx5hdI0vqey34S0jkyiOU3RV6yWvalxSQ",
  apiUrl: "https://assemble.webo.dev/get_meeting_data"

};
/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
