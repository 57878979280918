import { Component, ViewEncapsulation, OnInit } from "@angular/core";
import { isSameDay, isSameMonth } from "date-fns";
import { CalendarEvent, CalendarView } from "angular-calendar";
import * as moment from "moment";
import { MeetingService } from "../../meeting/meeting.service";
import { Meeting, IMeeting } from "../../_models/meeting";
import { LocalityService } from "../../locality/locality.service";
import { SubdivisionService } from "../../subdivision/subdivision.service";
import { NgxSpinnerService } from "ngx-spinner";
import {
  MeetingSortType,
  MeetingMenuService,
} from "../meeting-menu/meeting-menu.service";
import { FilterData } from "../../dialogBox/dialog-filter/dialog-filter.component";
import { Status } from "src/app/util/database";
import { DateRange } from "@uiowa/date-range-picker";
const colors: any = {
  red: {
    primary: "#ad2121",
    secondary: "#FAE3E3",
  },
  blue: {
    primary: "#1e90ff",
    secondary: "#D1E8FF",
  },
  yellow: {
    primary: "#e3bc08",
    secondary: "#FDF1BA",
  },
};

@Component({
  selector: "app-calender-view",
  styleUrls: ["./calender-view.component.scss"],
  templateUrl: "./calender-view.component.html",
  encapsulation: ViewEncapsulation.None,
})
export class CalenderViewComponent implements OnInit {
  public view: CalendarView = CalendarView.Month;
  public CalendarView = CalendarView;
  public viewDate: Date = new Date();
  public events: CalendarEvent[] = [];
  public activeDayIsOpen = false;
  public isLoadingData;
  public showCalender = false;
  public meetingDateList = [];
  public groupOfMeetingOfParticularDate: MeetingGroup[] = [];
  constructor(
    private meetingService: MeetingService,
    private localityService: LocalityService,
    private subDivisionService: SubdivisionService,
    private spinner: NgxSpinnerService,
    private MenuService: MeetingMenuService
  ) {}
  ngOnInit() {
    this.fetchMeetingOfSpecificMonth();
    this.MenuService.filterData().subscribe((result) => {
      this.filterData(result);
    });
  }
  eliminateDublicate() {
    const tempEventList = this.events;
    this.events = [];
    this.events = tempEventList.filter(
      (item, index) => this.events.indexOf(item) === index
    );
  }

  fetchMeetingOfSpecificMonth() {
    this.spinner.show();
    this.eliminateDublicate();
    this.groupOfMeetingOfParticularDate = [];
    const startDateOfmonth = moment(this.viewDate).startOf("month").format();
    const endDateOfmonth = moment(this.viewDate).endOf("month").format();
    // this.isLoadingData = this.meetingService.fetchMeetingOfSpecificInterval(
    //   new Date(startDateOfmonth),
    //   new Date(endDateOfmonth)
    // );
    this.isLoadingData =this.meetingService.fetchMeetingFromApi({
      hostingSubDivisionIds: [],
      vistingSubDivisionIds: [],
      meetingTypeName: [],
      duration: new DateRange(new Date(startDateOfmonth),
      new Date(endDateOfmonth))
    } as FilterData); 
  //  this.isLoadingData = this.meetingService.fetchMeetingFromApi(duration)

    const promise = [];
    this.isLoadingData.subscribe((res) => {
      const listOfMeeting: Meeting[] = [];
      console.log("res",res)
      res.data.forEach((doc) => {
        try{
          if (doc.status != Status.DELETED) {
            const meeting = new Meeting(doc as IMeeting, doc.id);
            listOfMeeting.push(meeting);
            promise.push(
              this.fetchLocalityAndSubDivName(meeting.hostingSubDivisionId)
            );
          }
        }
        catch(err){
          console.log(err)
        }
        
      });
      Promise.all(promise).then((datas) => {
        this.spinner.hide();
        this.showCalender = true;
        datas.forEach((data, index) => {
          this.events.push({
            start: listOfMeeting[index].meetingDate,
            title:
              listOfMeeting[index].meetingType +
              ` | ${listOfMeeting[index].meetingTime} | ${data}`,
            color: colors.yellow,
          });
        });
        res.data.forEach((doc) => {
          this.groupingOfMeetingForParticularDate(
            new Meeting(doc as IMeeting, doc.id)
          );
        });
        this.showCalender = true;
      });
    });
  }
  filterMeeting(meeting: Meeting[]) {
    const promise = [];
    this.eliminateDublicate();
    this.groupOfMeetingOfParticularDate = [];
    this.meetingDateList = [];
    const listOfMeeting: Meeting[] = [];
    meeting.forEach((doc) => {
      listOfMeeting.push(doc);
      promise.push(this.fetchLocalityAndSubDivName(doc.hostingSubDivisionId));
    });
    Promise.all(promise).then((datas) => {
      this.spinner.hide();
      datas.forEach((data, index) => {
        this.events.push({
          start: listOfMeeting[index].meetingDate,
          title:
            listOfMeeting[index].meetingType +
            ` | ${listOfMeeting[index].meetingTime} | ${data}`,
          color: colors.yellow,
        });
      });
      meeting.forEach((doc) => {
        console.log(doc);
        this.groupingOfMeetingForParticularDate(doc);
      });
      console.log(this.groupOfMeetingOfParticularDate);
      this.showCalender = true;
    });
  }
  groupingOfMeetingForParticularDate(meeting: Meeting) {
    if (!this.meetingDateList.includes(meeting.meetingDate.getDate())) {
      this.meetingDateList.push(meeting.meetingDate.getDate());
      this.groupOfMeetingOfParticularDate.push(
        new MeetingGroup(meeting, this.events)
      );
    }
  }
  reloadData() {
    this.showCalender = false;
    this.fetchMeetingOfSpecificMonth();
  }
  async fetchLocalityAndSubDivName(id: string) {
    const subdivdata = await this.subDivisionService.fetchSpecifcSubDivision(
      id
    );
    const localityData = await this.localityService.fetchLocality(
      subdivdata.localityId
    );
    return `${subdivdata.subDivisionName} | ${localityData.localityName}`;
  }
  formatDataSetForCalender(meeting: Meeting) {
    this.fetchLocalityAndSubDivName(meeting.hostingSubDivisionId).then(
      (data) => {
        this.events.push({
          start: meeting.meetingDate,
          title: meeting.meetingType + ` | ${meeting.meetingTime} | ${data}`,
          color: colors.yellow,
        });
      }
    );
  }
  dayClicked({ date, events }: { date: Date; events: CalendarEvent[] }): void {
    if (isSameMonth(date, this.viewDate)) {
      if (
        (isSameDay(this.viewDate, date) && this.activeDayIsOpen === true) ||
        events.length === 0
      ) {
        this.activeDayIsOpen = false;
      } else {
        this.activeDayIsOpen = true;
      }
      this.viewDate = date;
    }
  }
  closeOpenMonthViewDay() {
    this.activeDayIsOpen = false;
  }
  setView(view: CalendarView) {
    this.view = view;
  }
  filterData(result: any) {
    result.showAllData ? this.showAllData() : this.filterByDialog(result.data);
  }
  filterByDialog(result: FilterData) {
    const temp: Meeting[] = [];
    this.showCalender = false;
    this.isLoadingData.subscribe((data) => {
      data.forEach((snap) => {
        const doc = new Meeting(snap.data() as IMeeting, snap.id);
        if (
          result.hostingSubDivisionIds.includes(doc.hostingSubDivisionId) &&
          moment(doc.meetingDate).isBetween(
            moment(result.duration.start),
            moment(result.duration.end).add(1, "day")
          )
        ) {
          if (result.meetingTypeName.includes(doc.meetingType)) {
            result.vistingSubDivisionIds.forEach((visitingId) => {
              if (doc.visitingSubDivisionId.includes(visitingId)) {
                temp.push(doc);
              }
            });
          }
        }
      });
      this.filterMeeting(temp);
    });
  }
  filterByDialogMeetingType(result: any) {
    this.events = [];
    this.showCalender = false;
    const temp: Meeting[] = [];
    this.isLoadingData.subscribe((data) => {
      data.forEach((res) => {
        const doc = new Meeting(res.data() as IMeeting, res.id);
        result.forEach((meeting) => {
          if (doc.meetingType === meeting.meetingTypeName) {
            temp.push(doc);
          }
        });
      });
    });
  }
  showAllData() {
    this.showCalender = false;
    this.isLoadingData.subscribe((data) => {
      const temp = [];
      data.forEach((res) => {
        temp.push(new Meeting(res.data() as IMeeting, res.id));
      });
      this.filterMeeting(temp);
    });
  }
  filterByDialogHostingSubDiv(result: any) {
    this.events = [];
    this.showCalender = false;
    const temp: Meeting[] = [];
    this.isLoadingData.subscribe((data) => {
      data.forEach((res) => {
        const doc = new Meeting(res.data() as IMeeting, res.id);
        result.forEach((meetingData) => {
          if (
            doc.hostingSubDivisionId === meetingData.key$ &&
            !temp.includes(doc)
          ) {
            temp.push(doc);
          }
        });
      });
      this.filterMeeting(temp);
    });
  }
  filterByDialogVisitingSubDiv(result: any) {
    this.events = [];
    this.showCalender = false;
    const temp: Meeting[] = [];
    this.isLoadingData.subscribe((data) => {
      data.forEach((res) => {
        const doc = new Meeting(res.data() as IMeeting, res.id);
        result.forEach((meetingData) => {
          if (
            doc.visitingSubDivisionId.includes(meetingData.key$) &&
            !temp.includes(doc)
          ) {
            temp.push(doc);
          }
        });
      });
      this.filterMeeting(temp);
    });
  }
}
export class MeetingGroup {
  nameOfMeeting: string[] = [];
  dateOfMeeting: string;
  constructor(meeting: Meeting, meetingSetOfMonth: CalendarEvent[]) {
    this.dateOfMeeting = moment(meeting.meetingDate).format("MMMM Do YYYY");
    meetingSetOfMonth.forEach((meetingset) => {
      if (this.isTwoDateEqual(meetingset.start, meeting.meetingDate)) {
        this.nameOfMeeting.push(meetingset.title);
      }
    });
  }
  isTwoDateEqual(date1: Date, date2: Date) {
    const momdate1 = moment(date1).format("YYYY-MM-DD");
    const momdate2 = moment(date2).format("YYYY-MM-DD");
    return moment(momdate2).isSame(momdate1);
  }
}
