import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import { FirebaseConstant, Status } from 'src/app/util/database';
import { INotification, Notification } from '../_models/notification';
import * as moment from 'moment';

@Injectable({
  providedIn: 'root'
})
export class NotificationService {
  constructor(private afs: AngularFirestore) {}
  scheduleNotification(data: INotification) {
    const scheduleDateData = this.mergeDateAndTime(
      data.scheduleTime,
      data.scheduleDate
    );
    return this.afs
      .collection(FirebaseConstant.NotificationSchedule_COLL_REF)
      .add({
        title: data.title,
        message: data.message,
        scheduleDate: scheduleDateData,
        scheduleTime: data.scheduleTime,
        subDivisionId: data.subDivisionId,
        status: Status.ACTIVE
      })//.then((collectionID)=>{
      //  this.afs
      // .collection(FirebaseConstant.NotificationHistory)
      // .doc(collectionID.id)
      // .set({
      //   title: data.title,
      //   message: data.message,
      //   scheduleDate: scheduleDateData,
      //   subDivisionId: data.subDivisionId
      // })
      // })
  }
  fetchNotification() {
    return this.afs
      .collection(FirebaseConstant.NotificationSchedule_COLL_REF, ref =>
        ref.where('status', '==', Status.ACTIVE)
      )
      .snapshotChanges();
  }
  deleteNotification(notification: Notification) {
    return this.afs
      .collection(FirebaseConstant.NotificationSchedule_COLL_REF)
      .doc(notification.key$)
      .update({
        status: Status.DELETED
      });
  }
  mergeDateAndTime(time: string, date: Date): Date {
    const dateString = moment(date).format('ddd DD-MMM-YYYY');
    return moment(`${dateString}, ${time}`, 'ddd DD-MMM-YYYY, hh:mm A')
      .utc()
      .toDate();
  }
  fetchNotificationHistory() {
    let now = new Date();
    return this.afs
      .collection(FirebaseConstant.NotificationHistory, ref =>
        ref.where('status', '==', Status.ACTIVE)
      )
      .snapshotChanges();
  }
  deleteSentNotification(notification: Notification){
    return this.afs
      .collection(FirebaseConstant.NotificationHistory)
      .doc(notification.key$)
      .update({
        status: Status.DELETED
      });
  }
}
