import { Component, OnInit, ViewChild } from "@angular/core";
import { MatPaginator } from "@angular/material/paginator";
import { MatTableDataSource } from "@angular/material/table";
import { MatDialog } from "@angular/material/dialog";
import { MatSort } from "@angular/material/sort";
import { MatTableExporterDirective } from "mat-table-exporter";
import { LocalityService } from "../locality/locality.service";
import { DialogAddSubDivisionComponent } from "../dialogBox/dialog-add-sub-division/dialog-add-sub-division.component";
import {
  ConfirmationDialogComponent,
  DialogData,
} from "../dialogBox/confirmation-dialog/confirmation-dialog.component";
import { SubdivisionService } from "./subdivision.service";
import { Subdivision, ISubdivision } from "../_models/subDivision";
import { Router } from "@angular/router";
import { NgxSpinnerService } from "ngx-spinner";
import {
  DELETE_SUBDIVISION_TITLE,
  DELETE_SUBDIVISION_CONFIRMATION_BODY,
  DELETE_SUBDIVISION_BODY,
} from "src/app/util/database";
@Component({
  selector: "app-subdivision",
  templateUrl: "./subdivision.component.html",
  styleUrls: ["./subdivision.component.scss"],
})
export class SubdivisionComponent implements OnInit {
  displayedColumns: string[] = [
    "subDivisionName",
    "localityId",
    "location",
    "actionsColumn",
  ];
  dataSourceForTable;

  @ViewChild(MatPaginator, { static: false }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: false }) sort: MatSort;
  @ViewChild(MatTableExporterDirective, { static: false })
  exporter: MatTableExporterDirective;
  constructor(
    public dialog: MatDialog,
    private subDivisionService: SubdivisionService,
    private locationService: LocalityService,
    private router: Router,
    private spinner: NgxSpinnerService
  ) {}

  ngOnInit() {
    this.getallDatas((list) => {
      this.setDataToTable(list);
    });
  }

  exportToExcel() {
    this.exporter.exportTable("xlsx", { fileName: "Sub-Divisions" });
  }
  exportToCSV() {
    this.exporter.exportTable("csv", { fileName: "Sub-Divisions" });
  }

  getallDatas(callback: (listofSubdivion: Subdivision[]) => void) {
    this.spinner.show();
    let index = 0;
    this.subDivisionService.fetchSubDivisions().subscribe((res) => {
      const listofSubdivion: Subdivision[] = [];
      res.forEach((doc) => {
        const newSubDivison = new Subdivision(
          doc.payload.doc.data() as ISubdivision,
          doc.payload.doc.id
        );
        this.locationService
          .fetchLocality(newSubDivison.localityId)
          .then((locality) => {
            newSubDivison.localityName = locality.localityName;
            listofSubdivion.push(newSubDivison);
            if (res.length - 1 === index) {
              callback(listofSubdivion);
            }
            index++;
          });
      });
      this.spinner.hide();
    });
  }
  viewSubDivision(id: string) {
    this.router.navigate(["subdivisions", id]);
  }
  deleteSubDivision(subDiv: Subdivision) {
    const dialogData: DialogData = { title: "", body: "", isDeleteAble: false };
    dialogData.title = DELETE_SUBDIVISION_TITLE;
    this.hasAssociatiatedDat(subDiv).then((result) => {
      if (result) {
        dialogData.body = DELETE_SUBDIVISION_CONFIRMATION_BODY;
      } else {
        dialogData.body = DELETE_SUBDIVISION_BODY(subDiv);
        dialogData.isDeleteAble = true;
      }
      const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
        data: dialogData,
        width: "450px",
        minHeight: "100px",
      });
      dialogRef.afterClosed().subscribe((resultAfter) => {
        if (resultAfter.isDeleteAble) {
          this.subDivisionService
            .deleteSubDivision(subDiv.key$)
            .then(() => {})
            .catch((err) => {
              console.log(err);
            });
        }
      });
    });
  }
  hasAssociatiatedDat(subDiv: Subdivision): Promise<boolean> {
    return this.subDivisionService
      .hasAssociatiedHUser(subDiv.key$)
      .then((result) => {
        if (result) {
          return true;
        } else {
          return this.subDivisionService
            .hasAssociatiedHostingMeeting(subDiv.key$)
            .then((result1) => {
              if (result1) {
                return true;
              } else {
                return this.subDivisionService
                  .hasAssociatiedVisitingMeeting(subDiv.key$)
                  .then((result2) => {
                    if (result2) {
                      return true;
                    } else {
                      return false;
                    }
                  });
              }
            });
        }
      });
  }
  setDataToTable(listOfAdmin: Subdivision[]) {
    this.dataSourceForTable = new MatTableDataSource<Subdivision>(listOfAdmin);
    this.dataSourceForTable.paginator = this.paginator;
    this.dataSourceForTable.sort = this.sort;
  }
  applyFilter(filterValue: string) {
    this.dataSourceForTable.filter = filterValue.trim().toLowerCase();
  }
  addSubDivision() {
    const dialogRef = this.dialog.open(DialogAddSubDivisionComponent, {
      data: { action: "add" },
      width: "450px",
      minHeight: "100px",
    });

    dialogRef.afterClosed().subscribe((result) => {
      console.log("The dialog was closed");
    });
  }
  editSubDivision(subDivisionData: Subdivision) {
    const dialogRef = this.dialog.open(DialogAddSubDivisionComponent, {
      data: { action: "edit", subDivision: subDivisionData },
      width: "450px",
      minHeight: "100px",
    });

    dialogRef.afterClosed().subscribe((result) => {
      console.log("The dialog was closed");
    });
  }
}
