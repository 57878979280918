import { Component, OnInit } from '@angular/core';
import { SubdivisionService } from '../../subdivision/subdivision.service';
import { Subdivision, ISubdivision } from '../../_models/subDivision';
import { Locality, ILocality } from '../../_models/locality';
import { LocalityService } from '../../locality/locality.service';
import { MatDialogRef } from '@angular/material';
@Component({
  selector: 'app-dialog-mat-select',
  templateUrl: './dialog-mat-select.component.html',
  styleUrls: ['./dialog-mat-select.component.scss']
})
export class DialogMatSelectComponent implements OnInit {
  public listOfSubDivision: Subdivision[] = [];
  public listOfLocality: Locality[] = [];
  public dataLoaded = false;
  public hostingSubDivision: Subdivision;
  constructor(
    private localityService: LocalityService,
    private subDivisionService: SubdivisionService,
    private dialogRef: MatDialogRef<DialogMatSelectComponent>,
  ) {}

  ngOnInit() {
    this.fetchLocality();
  }
  fetchSubDivision() {
    this.subDivisionService.fetchSubDivisions().subscribe(res => {
      res.forEach(doc => {
        const newSubDivison = new Subdivision(
          doc.payload.doc.data() as ISubdivision,
          doc.payload.doc.id
        );
        this.listOfSubDivision.push(newSubDivison);
      });
      this.dataLoaded = true;
    });
  }
  fetchLocality() {
    this.localityService.fetchLocalities().subscribe(res => {
      res.forEach(doc => {
        this.listOfLocality.push(
          new Locality(doc.payload.doc.data() as ILocality, doc.payload.doc.id)
        );
      });
      this.fetchSubDivision();
    });
  }
  sendData() {
    this.dialogRef.close(this.hostingSubDivision);
  }
}
