import { Component, OnInit, Inject } from "@angular/core";
import { SubdivisionService } from "../../subdivision/subdivision.service";
import { Subdivision, ISubdivision } from "../../_models/subDivision";
import { Locality, ILocality } from "../../_models/locality";
import { LocalityService } from "../../locality/locality.service";
import {
  MatCheckboxChange,
  MatDialogRef,
  ThemePalette,
} from "@angular/material";
import { MAT_DIALOG_DATA } from "@angular/material/dialog";

export interface Task {
  name: string;
  completed: boolean;
  color: ThemePalette;
  subtasks?: Task[];
}
@Component({
  selector: "app-multi-select-mat",
  templateUrl: "./multi-select-mat.component.html",
  styleUrls: ["./multi-select-mat.component.scss"],
})
export class MultiSelectMatComponent implements OnInit {
  // public listOfSubDivision: CheckBoxData[] = [];
  // public listOfLocality: CheckBoxLocalityData[] = [];
  public listOfCheckData: Set<CheckBoxData> = new Set();
  public dataLoaded = false;
  public selectedAllLocality = [];
  public cstatus: boolean = false;
  locality = [];
  allSelectedLocality = [];
  listofSubDivision = [];
  public listofLocality: any = [];
  public finalDataSet: any = [];
  public sendDataSet: any = [];
  count = 0
  name: string
  public alltask = []
  public allSubtask = []
  allComplete: boolean = false;
  public disable: boolean;
  // public selectedLocalitiesSub = []



  constructor(
    private localityService: LocalityService,
    private subDivisionService: SubdivisionService,
    private dialogRef: MatDialogRef<MultiSelectMatComponent>,
    @Inject(MAT_DIALOG_DATA) public selectedSubDiv = []
  ) 

  {}


  updateAllComplete(task) {
    console.log(this.sendDataSet)
    this.disable = true;


    task.completed =
      task.subtasks != null && task.subtasks.every((t) => t.completed);

    if(this.selectedSubDiv.length){
      console.log("here")
      this.sendDataSet = this.selectedSubDiv;
      this.finalDataSet.forEach((data, index) => {
        if (data.name == task.name) {

          this.finalDataSet[index] = task;
        }        
      });

      if(this.sendDataSet.length != 0){
        console.log("here")

        this.sendDataSet.forEach((data,index)=>{
          if(data.name == task.name){
            this.sendDataSet[index] = task
          }
          else{
            console.log("here")

            if(this.name == task.name){
              this.alltask.forEach((d, index)=>{
                if(d.name == task.name){
                  this.alltask[index] = task
                }
                else{
                  console.log("here")

                  this.alltask.push(task)

                }
              })
            }
            else{
              console.log("here")

              this.alltask.push(task)

            }

          }
        
        })
      }
      this.name = task.name
      
    }
     else {
      console.log("here")
      // this.sendDataSet.push(task);
      this.allSubtask.push(task)
      console.log(this.sendDataSet)
    }
    
  }

  setAll(completed: boolean, task) {
    task.completed = completed;
    this.disable = true;

    if (task.subtasks == null) {
      return;
    }
    task.subtasks.forEach((t) => (t.completed = completed));
    if (completed) {
      if (this.selectedSubDiv.length) {
        this.sendDataSet = this.selectedSubDiv;

        this.sendDataSet.push(task);
      } else {
        if (this.sendDataSet.length) {

          this.sendDataSet.forEach((s) => {
            if (s.name == task.name) {
              this.sendDataSet.pop(task);
            } else {
              this.sendDataSet.push(task);
            }
          });
        } else {
          this.sendDataSet.push(task);
        }
      }
    } else {

      this.finalDataSet.forEach((data, index) => {
        if (data.name == task.name) {
          this.finalDataSet[index] = task;
        }
        else if(data.completed == true){
          this.sendDataSet.push(data)
        }
      });
    }
  }

  selectAllSubDivCheckBoxClicked(completed: boolean) {
    this.disable = true;

    this.allComplete = completed;
    this.finalDataSet.forEach((t) => (t.completed = completed));

    this.finalDataSet.forEach((t) => {
      t.subtasks.forEach((s) => {
        s.completed = completed;
      });
    });

    if (this.allComplete) {
      this.finalDataSet["allComplete"] = completed;
      this.sendDataSet = this.finalDataSet;
    }
  }

  ngOnInit() {
    console.log(this.selectedSubDiv)
    this.fetchLocalityandSubDivision();

  }

  async fetchLocalityandSubDivision() {
    await this.localityService.fetchAllLocalities().then((res) => {
      res.forEach((doc) => {

        const locality = new CheckBoxLocalityData(
          new Locality(doc.data() as ILocality, doc.id),
          false
        );
        this.listofLocality.push(locality.data);
      });
    });

    await this.subDivisionService.fetchAllSubDivisions().then((res) => {
      res.forEach((doc) => {
        const newSubDivison = new Subdivision(
          doc.data() as ISubdivision,
          doc.id
        );

        this.listofSubDivision.push(newSubDivison);

      });
    });

    this.listofLocality.forEach((res) => {
      let array = [];
      this.listofSubDivision.forEach((data) => {

        if (res.key$ == data.localityId) {
          data["completed"] = false;
          array.push(data);
        }
      });
      this.finalDataSet.push({
        name: res.localityName,
        completed: false,
        color: "primary",
        subtasks: array,
      });
    });
    if (this.selectedSubDiv) {
      this.selectedSubDiv.forEach((t) => {
        this.finalDataSet.forEach((data, index) => {
          if (data.name == t.name) {
            this.finalDataSet[index] = t;
          }
        });
      });
    }
    this.dataLoaded = true;
  }

  cancelData() {
    if (this.selectedSubDiv.length) {
      this.dialogRef.close(this.selectedSubDiv);
    }
  }


  sendData() {
    console.log(this.sendDataSet)
    let name
    let subName
    // let testname
    // let test = []
    // test = this.sendDataSet
    
    if(this.alltask.length > 1){
      // console.log(this.alltask)
      // this.sendDataSet = []
      // test.forEach(data=>{
      //   if(data.name != test){
      //     this.sendDataSet.push(data)
      //   }
      //   test = data.name
      // })      
      this.alltask.forEach(data=>{
        if(data.name != name){
          this.sendDataSet.push(data)
        }
        name = data.name
      })
    }
    if(this.allSubtask){
      
        this.allSubtask.forEach(data=>{
          if(data.name != subName){
            this.sendDataSet.push(data)
          }
          subName = data.name
        })
      
    }
    this.dialogRef.close(this.sendDataSet);
  }
}
export class CheckBoxData {
  public data: Subdivision;
  public isChecked: boolean;
  constructor(data: Subdivision, isChecked: boolean) {
    this.data = data;
    this.isChecked = isChecked;
  }
}
export class CheckBoxLocalityData {
  public data: Locality;
  public isChecked: boolean;
  constructor(data: Locality, isChecked: boolean) {
    this.data = data;
    this.isChecked = isChecked;
  }
}
