import { Injectable } from "@angular/core";
import { AngularFirestore } from "@angular/fire/firestore";
import { FirebaseConstant, Status } from "src/app/util/database";
import { Meeting, IMeeting } from "../_models/meeting";
import { MeetingData, IMeetingData } from "../_models/meetingData";
import { Subdivision, ISubdivision } from "../_models/subDivision";
import { HttpClient } from "@angular/common/http";
import { TimeZone, ITimeZone } from "../_models/timeZone";
import * as moment from "moment";
import "moment-timezone";
import { UpcomingMeeting, IUpcomingMeeting } from "../_models/upcommingmeeting";
import { map } from "rxjs/internal/operators/map";
import { AngularFireFunctions } from "@angular/fire/functions";
import { Observable } from "rxjs";
import { DatePipe } from "@angular/common";
import { FilterData } from "../dialogBox/dialog-filter/dialog-filter.component";
import { environment } from "src/environments/environment";
@Injectable({
  providedIn: "root",
})
export class MeetingService {
  constructor(
    private afs: AngularFirestore,
    private http: HttpClient,
    private _angularFireFunctions: AngularFireFunctions,
    private _datePipe: DatePipe
  ) {}
  saveMeeting_(meetingDetail: any) {
    return this.updateTimeZoneAndDate(meetingDetail)
      .then(async (meetingData) => {
        const toNewData = this.toArray(meetingData);
        const apiRes = await this._angularFireFunctions
          .httpsCallable("createMeeting")(toNewData)
          .toPromise();
        return apiRes;
        // return this.afs
        //   .collection(FirebaseConstant.Meeting_COLL_REF)
        //   .add(toNewData);
      })
      .catch((err) => {
        console.log(err);
        throw new Error(err);
      });
  }


  updateMeetingApi(meetingDetail: any, type: number, id: any) {
    const toNewData = {
      doc_id: id,
      meetingNumber: type,
      payload: meetingDetail
    };
    const apiRes = this._angularFireFunctions
      .httpsCallable("updateMeeting")(toNewData)
      .toPromise();
    return apiRes;
   
  }

  updateMeeting(meetingDetail: any, id: string) {
    return this.updateTimeZoneAndDate(meetingDetail).then((meetingData) => {
      const toNewData = this.toArray(meetingData);
      return this.afs
        .collection(FirebaseConstant.Meeting_COLL_REF)
        .doc(id)
        .update(toNewData);
    });
  }

  fetchMeeting(page, startAt: Meeting, meetingType?: string) {
    if (startAt) {
      return this.afs
        .collection(FirebaseConstant.Meeting_COLL_REF, (ref) =>
          ref
            .where("status", "in", [Status.ACTIVE, Status.COMPELTED])
            .orderBy("meetingDate", "desc")
            .startAfter(startAt.meetingDate)
            .limit(page)
        )
        .get()
        .toPromise()
        .then((snap) => {
          const listOfMeeting: Meeting[] = [];
          snap.docs.forEach((doc) =>
            listOfMeeting.push(new Meeting(doc.data() as IMeeting, doc.id))
          );
          return listOfMeeting;
        });
    } else {
      return this.afs
        .collection(FirebaseConstant.Meeting_COLL_REF, (ref) =>
          ref
            .where("status", "in", [Status.ACTIVE, Status.COMPELTED])
            .orderBy("meetingDate", "desc")
            .limit(page)
        )
        .get()
        .toPromise()
        .then((snap) => {
          const listOfMeeting: Meeting[] = [];
          snap.docs.forEach((doc) => {
            listOfMeeting.push(new Meeting(doc.data() as IMeeting, doc.id));
          });
          return listOfMeeting;
        });
    }
  }

  prevPage(page, startAt: Meeting) {
    return this.afs
      .collection(FirebaseConstant.Meeting_COLL_REF, (ref) =>
        ref
          .where("status", "in", [Status.ACTIVE, Status.COMPELTED])
          .orderBy("meetingDate", "desc")
          .endBefore(startAt.meetingDate)
          .limitToLast(page)
      )
      .get()
      .toPromise()
      .then((snap) => {
        const listOfMeeting: Meeting[] = [];
        snap.docs.forEach((doc) =>
          listOfMeeting.push(new Meeting(doc.data() as IMeeting, doc.id))
        );
        return listOfMeeting;
      });
  }

  firstPage(page) {
    return this.afs
      .collection(FirebaseConstant.Meeting_COLL_REF, (ref) =>
        ref
          .where("status", "in", [Status.ACTIVE, Status.COMPELTED])
          .orderBy("meetingDate", "desc")
          .limit(page)
      )
      .get()
      .toPromise()
      .then((snap) => {
        const listOfMeeting: Meeting[] = [];
        snap.docs.forEach((doc) =>
          listOfMeeting.push(new Meeting(doc.data() as IMeeting, doc.id))
        );
        return listOfMeeting;
      });
  }

  lastPage(page) {
    return this.afs
      .collection(FirebaseConstant.Meeting_COLL_REF, (ref) =>
        ref
          .where("status", "in", [Status.ACTIVE, Status.COMPELTED])
          .orderBy("meetingDate", "asc")
          .limit(page)
      )
      .get()
      .toPromise()
      .then((snap) => {
        const listOfMeeting: Meeting[] = [];
        snap.docs.forEach((doc) => {
          listOfMeeting.push(new Meeting(doc.data() as IMeeting, doc.id));
          // if (doc.data().status != Status.DELETED) {
          //   listOfMeeting.push(new Meeting(doc.data() as IMeeting, doc.id));
          // }
        });
        return listOfMeeting;
      });
  }

  getMeetingCount() {
    return this.afs
      .collection(FirebaseConstant.MEETING_COUNT)
      .snapshotChanges()
      .pipe(
        map((res) => res.map((a: any) => a.payload.doc.data().count as any))
      );
  }

  fetchMeetingOfSpecificSubDivision(
    id: string,
    calback: (list: Array<Meeting>) => void
  ) {
    return this.afs
      .collection(FirebaseConstant.Meeting_COLL_REF, (ref) =>
        ref.where("visitingSubDivisionId", "array-contains", id)
      )
      .get()
      .toPromise()
      .then((snap) => {
        const listOfMeeting: Meeting[] = [];
        snap.docs.map((doc) => {
          let meeting = new Meeting(doc.data() as IMeeting, doc.id);
          if (
            (meeting.status != Status.DELETED &&
              meeting.status == Status.ACTIVE) ||
            meeting.status == Status.COMPELTED
          ) {
            listOfMeeting.push(meeting);
          }
        });
        calback(listOfMeeting);
      });
  }

  deleteMeeting(id: string) {
    return this.afs
      .collection(FirebaseConstant.Meeting_COLL_REF)
      .doc(id)
      .update({
        status: Status.DELETED,
      });
  }

  deleteMeetingApi(meetingDetail: any, type: number) {
    const toNewData = {
      doc_id: meetingDetail.id,
      deleteType: type,
      recurrenceDetail: meetingDetail.recurrenceDetail,
      meetingDate: meetingDetail.meetingDate,
    };
    const apiRes = this._angularFireFunctions
      .httpsCallable("deleteMeeting")(toNewData)
      .toPromise();
    return apiRes;
   
  }

  fetchMeetingOfSpecificInterval(startDate, endDate) {
    return this.afs
      .collection(FirebaseConstant.Meeting_COLL_REF, (ref) =>
        ref
          // .where("status", "==", Status.ACTIVE)
          .where("meetingDate", ">=", startDate)
          .where("meetingDate", "<=", endDate)
      )
      .get();
  }
  addMeetingType_(meetingType: string) {
    return this.afs.collection(FirebaseConstant.SubMeeting_COLL_REF).add({
      meetingTypeName: meetingType,
      status: Status.ACTIVE,
    });
  }
  fetchAllMeetingType() {
    return this.afs
      .collection(FirebaseConstant.SubMeeting_COLL_REF, (ref) =>
        ref.where("status", "==", Status.ACTIVE)
      )
      .get()
      ;
  }
  fetchAllMeetingTypeSnap() {
    return this.afs
      .collection(FirebaseConstant.SubMeeting_COLL_REF, (ref) =>
        ref.where("status", "==", Status.ACTIVE)
      )
      .snapshotChanges();
  }
  fetchAllParentMeetingBySchedule() {
    return this.afs
      .collection(FirebaseConstant.Meeting_COLL_REF, (ref) =>
        ref.where("isParent", "==", true).orderBy("parentID")
      )
      .get();
  }
  deleteMeetingType(docId: string) {
    return this.afs
      .collection(FirebaseConstant.SubMeeting_COLL_REF)
      .doc(docId)
      .update({
        status: Status.DELETED,
      });
  }
  toArray(ObjectDAta: any) {
    const newArray: any = {};
    Object.keys(ObjectDAta).forEach((element: string) => {
      if (typeof ObjectDAta[element] !== "undefined" && element !== "$key") {
        newArray[element] = ObjectDAta[element];
      }
    });
    return newArray;
  }
  fetchallParentMeetings(calback: (list: Array<Meeting>) => void) {
    return this.afs
      .collection(
        FirebaseConstant.Meeting_COLL_REF,
        (ref) =>
          ref
            .where("isParent", "==", true)
            // .where('parentID', '==', 'k5YTxBB0m2cbU5mCm0do')
            // .where('status', '==', 'active')
            .where("status", "==", "deleted")
            // .orderBy('parentID')
            // .where('recurrenceDetail', '==', 'none')
            // .where('recurrenceDetail', '==', 'daily')
            // .where('recurrenceDetail', '==', 'weeklyOnDay')
            // .where('recurrenceDetail', '==', 'monthlyOnDay')
            // .where('recurrenceDetail', '==', 'anuallyOnDay')
            // .where('recurrenceDetail', '==', 'everyWeekday')
            .where("recurrenceDetail", "==", "custom")
            .where("endType", "==", "Never")
        // .orderBy('parentID')
      )
      .get()
      .toPromise()
      .then((snap) => {
        const listOfMeeting: Meeting[] = snap.docs.map((doc) => {
          const newMeeting = new Meeting(doc.data() as IMeeting, doc.id);
          return newMeeting;
        });
        calback(listOfMeeting);
      });
  }

  fetchChildrenMeetins(key: string): Promise<Meeting[]> {
    return this.afs
      .collection(FirebaseConstant.Meeting_COLL_REF, (ref) =>
        ref.where("parentID", "==", key).where("status", "==", "active")
      )
      .get()
      .toPromise()
      .then((snap) => {
        const listOfMeeting: Meeting[] = snap.docs.map((doc) => {
          const newMeeting = new Meeting(doc.data() as IMeeting, doc.id);
          return newMeeting;
        });
        return listOfMeeting;
      });
  }
  async regenerateChildren() {
    const weeklySnapshots = await this.afs
      .collection(FirebaseConstant.Meeting_COLL_REF, (ref) =>
        ref
          .where("isParent", "==", false)
          .where("recurrenceDetail", "==", "weeklyOnDay")
      )
      .get()
      .toPromise();
    const customSnapshots = await this.afs
      .collection(FirebaseConstant.Meeting_COLL_REF, (ref) =>
        ref
          .where("isParent", "==", false)
          .where("recurrenceDetail", "==", "custom")
          .where("endType", "==", "Never")
      )
      .get()
      .toPromise();

    const childIds: string[] = [];
    weeklySnapshots.forEach((doc) => {
      childIds.push(doc.id);
    });
    customSnapshots.forEach((doc) => {
      childIds.push(doc.id);
    });

    // delete all children
    const deleteAllChild = this.deleteMultipleMeetings(childIds).then(() => {
      return {};
    });
    // fetch all parent
    const weeklyParentSnapshots = await this.afs
      .collection(FirebaseConstant.Meeting_COLL_REF, (ref) =>
        ref
          .where("isParent", "==", true)
          .where("recurrenceDetail", "==", "weeklyOnDay")
      )
      .get()
      .toPromise();
    const customParentSnapshots = await this.afs
      .collection(FirebaseConstant.Meeting_COLL_REF, (ref) =>
        ref
          .where("isParent", "==", true)
          .where("recurrenceDetail", "==", "custom")
          .where("endType", "==", "Never")
      )
      .get()
      .toPromise();
    const parentObjects: MeetingData[] = [];
    weeklyParentSnapshots.forEach((doc) => {
      const meeting = new MeetingData(doc.data() as IMeetingData, doc.id);
      if (meeting.status !== Status.DELETED) {
        parentObjects.push(meeting);
      }
    });
    customParentSnapshots.forEach((doc) => {
      const meeting = new MeetingData(doc.data() as IMeetingData, doc.id);
      if (meeting.status !== Status.DELETED) {
        parentObjects.push(meeting);
      }
    });
    const addMeeting = deleteAllChild.then(() => {
      parentObjects.forEach(async (newMeetingData: MeetingData) => {
        newMeetingData.status = "active";
        // this.updateTimeZoneAndDate(meetingData).then(async (newMeetingData) => {
        const newMeeting: any = this.toArray(newMeetingData);
        await this.afs
          .collection(FirebaseConstant.Meeting_COLL_REF)
          .doc(newMeetingData.$key)
          .delete()
          .then(async () => {
            delete newMeeting.key$;
            await this.afs
              .collection(FirebaseConstant.Meeting_COLL_REF)
              .add(newMeeting)
              .then((snap) => {});
          });
      });
      // });
    });
    return addMeeting;

    // const parentObjects: MeetingData[] = [];
    // const customParentSnapshots = await this.afs
    //   .collection(FirebaseConstant.Meeting_BACKUP_COLL_REF)
    //   .get()
    //   .toPromise();
    // customParentSnapshots.forEach((doc) => {
    //   const meeting = new MeetingData(doc.data() as IMeetingData, doc.id);
    //   parentObjects.push(meeting);
    // });
    // parentObjects.forEach((meetingData: MeetingData) => {
    //   meetingData.status = "active";
    //   this.updateTimeZoneAndDate(meetingData).then(async (newMeetingData) => {
    //     const newMeeting: any = this.toArray(newMeetingData);
    //     delete newMeeting.key$;
    //     await this.afs
    //       .collection(FirebaseConstant.Meeting_COLL_REF)
    //       .add(newMeeting)
    //       .then((snap) => {
    //         console.log(snap.id);
    //       });
    //     console.log("working here");
    //   });
    // });
    // return {}
  }
  deleteMultipleMeetings(dataIds: string[]): Promise<any[]> {
    const allPromise: Promise<any>[] = [];
    dataIds.forEach((id) => {
      allPromise.push(
        this.afs.collection(FirebaseConstant.Meeting_COLL_REF).doc(id).delete()
      );
    });
    return Promise.all(allPromise);
  }
  deleteScheduleMeeting(dataIds: string[]): Promise<any[]> {
    const allPromise: Promise<any>[] = [];
    dataIds.forEach((id) => {
      allPromise.push(
        this.afs.collection(FirebaseConstant.ScheduleMeeting).doc(id).delete()
      );
    });
    return Promise.all(allPromise);
  }
  updateSubdivisionTimeZone() {
    return this.afs
      .collection(FirebaseConstant.SUBDIVISION_COLL_REF)
      .get()
      .toPromise()
      .then((snap) => {
        const listOfMeeting: Subdivision[] = snap.docs.map((doc) => {
          return new Subdivision(doc.data() as ISubdivision, doc.id);
        });
        listOfMeeting.forEach((subDivision) => {
          this.getTimezone(
            subDivision.location.longitude,
            subDivision.location.latitude
          ).then((timeZone) => {
            return this.afs
              .collection(FirebaseConstant.SUBDIVISION_COLL_REF)
              .doc(subDivision.key$)
              .update({ timeZoneID: timeZone.timeZoneId });
          });
        });
      });
  }

  getHosting(hostingID: string): Promise<Subdivision> {
    return this.afs
      .collection(FirebaseConstant.SUBDIVISION_COLL_REF)
      .doc(hostingID)
      .get()
      .toPromise()
      .then((snap) => {
        return new Subdivision(snap.data() as ISubdivision, snap.id);
      });
  }

  updateTimeZoneAndDate(meeting: MeetingData): Promise<MeetingData> {
    if (meeting.meetingDate && meeting.hostingSubDivisionId) {
      const getHostingData = this.getHosting(meeting.hostingSubDivisionId).then(
        (subdivision) => {
          const meetingDateData = moment(meeting.meetingDate.getTime()).tz(
            subdivision.timeZoneID
          );
          const timePeriod = meeting.meetingTime.split(" ")[1];
          const time = meeting.meetingTime.split(" ")[0];
          let hour = Number(time.split(":")[0]);
          const minute = Number(time.split(":")[1]);
          if (timePeriod === "AM") {
            if (hour == 12) {
              hour = hour - 12;
            }
          }
          if (timePeriod === "PM") {
            if (hour != 12) {
              hour = 12 + hour;
            }
          }
          // if (timePeriod === 'PM') {
          //     hour = 12 + hour;
          // }
          meetingDateData.hours(hour);
          meetingDateData.minutes(minute);
          meeting.meetingDate = meetingDateData.toString();
          if (meeting.endOn) {
            meeting.endOn = moment(meeting.endOn.getTime())
              .tz(subdivision.timeZoneID)
              .toString();
          }
          // meeting.meetingDate = meetingDateData.toDate();
          return meeting;
        }
      );
      return getHostingData;
    } else {
      return new Promise((fullfill) => {
        return fullfill(meeting);
      });
    }
  }

  getTimezone(long: number, lat: number): Promise<TimeZone> {
    return this.http
      .get(
        "https://maps.googleapis.com/maps/api/timezone/json?location=" +
          lat +
          "," +
          long +
          "&timestamp=1458000000&key=AIzaSyDHghS7HqVZaxSBu53-oFcK9XsKCbNIhzU"
      )
      .toPromise()
      .then((data) => {
        return new TimeZone(data as ITimeZone);
      });
  }

  /// testing upcoming meeting functions
  checkforAddedUpcommingMeeting() {
    this.afs
      .collection("UpComingMeeting")
      .get()
      .toPromise()
      .then((snap) => {
        snap.forEach((snapshot) => {
          const meeting = new UpcomingMeeting(
            snapshot.data() as IUpcomingMeeting,
            snapshot.id
          );
          const now = moment();
          const meetingDate = moment(meeting.meetingDate);
          if (meetingDate.isBefore(now)) {
            const upcommingMeetingID = meeting.upcommingMeetingID
              ? meeting.upcommingMeetingID
              : "";
            const parentMeetingID = meeting.parentID ? meeting.parentID : "";
            return this.afs
              .collection("UpComingMeeting")
              .doc(snapshot.id)
              .delete()
              .then(() => {
                return this.completeMeeting(upcommingMeetingID).then(() => {
                  return this.fetchAllMeetingWithPartentId(
                    parentMeetingID,
                    (allMeetings: Meeting[]) => {
                      const recurrenceType: string = meeting.recurrenceDetail;
                      switch (recurrenceType) {
                        // case 'daily': {
                        //   return singlehandleDailyMettings(allMeetings);
                        // }
                        case "weeklyOnDay": {
                          return this.singlehandleWeeklyOnDay(allMeetings);
                        }
                        // case 'monthlyOnDay': {
                        //   return singlehandleMonthlyOnDayMettings(allMeetings);
                        // }
                        // case 'anuallyOnDay': {
                        //   return singlehandleAnuallyOnDayMettings(allMeetings);
                        // }
                        // case 'everyWeekday': {
                        //   return singlehandleEveryWeekdayMettings(allMeetings);
                        // }
                        // case 'custom': {
                        //   return singlehandleCustomMettings(meeting, allMeetings);
                        // }
                        default: {
                          return false;
                        }
                      }
                    }
                  );
                });
              });
          } else {
            return false;
          }
        });
      });
  }
  completeMeeting(id: string) {
    return this.afs
      .collection(FirebaseConstant.Meeting_COLL_REF)
      .doc(id)
      .update({ status: "completed" });
  }
  fetchAllMeetingWithPartentId(
    parentID: string,
    callback: (list: Array<Meeting>) => void
  ) {
    return this.afs
      .collection(FirebaseConstant.Meeting_COLL_REF, (ref) =>
        ref.where("parentID", "==", parentID).where("status", "==", "active")
      )
      .get()
      .toPromise()
      .then((snapshot) => {
        if (snapshot) {
          const meetings = snapshot.docs.map((doc) => {
            return new Meeting(doc.data() as IMeeting, doc.id);
          });
          const sortedMeeting = meetings.sort((a: Meeting, b: Meeting) => {
            return a.meetingDate.getTime() - b.meetingDate.getTime();
          });
          callback(sortedMeeting);
        } else {
          callback([]);
        }
      })
      .catch((error) => {
        callback([]);
      });
  }
  singlehandleWeeklyOnDay(allMeetings: Meeting[]) {
    const newDate: Date = moment(
      allMeetings[allMeetings.length - 1].meetingDate
    )
      .add(1, "week")
      .toDate();
    const newMeeting: any = this.toArray(
      this.getNewMeeting(allMeetings[allMeetings.length - 1], newDate)
    );
    const newUpcomming = this.toArray(
      this.multigetNewUpcomingMeeting(allMeetings[0])
    );
    return this.afs
      .collection(FirebaseConstant.Meeting_COLL_REF)
      .add(newMeeting)
      .then((snap) => {
        return this.afs.collection("UpComingMeeting").add(newUpcomming);
      })
      .catch((err) => {
        return false;
      });
  }
  getNewMeeting(data: Meeting, date: Date): IMeeting {
    const newMeeting: IMeeting = data as IMeeting;
    newMeeting.meetingDate = date;
    newMeeting.status = "active";
    newMeeting.isParent = false;
    newMeeting.parentID = data.parentID;
    return newMeeting;
  }
  multigetNewUpcomingMeeting(data: Meeting): IUpcomingMeeting {
    const newMeeting: IUpcomingMeeting = this.toArray(data) as IUpcomingMeeting;
    newMeeting.meetingDate = data.meetingDate;
    newMeeting.isParent = false;
    newMeeting.parentID = data.parentID;
    newMeeting.upcommingMeetingID = data.key$;
    return newMeeting;
  }
  public async fetchByFilter(page, limit, result?) {
    let data = {
      pageNumber: page,
      limit: limit,
    };
    if (result) {
      let filter = JSON.parse(JSON.stringify(result));
      // filter.duration.start = filter.duration.start.toLocaleDateString();
      // filter.duration.end = filter.duration.end.toLocaleDateString();
      data["filterBy"] = { ...filter };
    }
    return this._angularFireFunctions
      .httpsCallable("filterBy")(data)
      .toPromise();

    // const filteredData = [];
    // while (result.hostingSubDivisionIds.length) {
    //   // firestore limits batches to 10
    //   const batch = result.hostingSubDivisionIds.splice(0, 10);
    //   // add the batch request to to a queue
    //   await this.afs
    //     .collection(FirebaseConstant.Meeting_COLL_REF, (ref) =>
    //       ref
    //         .where("hostingSubDivisionId", "in", batch)
    //         .where("meetingDate", ">=", result.duration.start)
    //         .where("meetingDate", "<=", result.duration.end)
    //         .orderBy("meetingDate", "desc")
    //     )
    //     .get()
    //     .toPromise()
    //     .then((snap) => {
    //       const listOfMeeting: Meeting[] = [];
    //       snap.docs.forEach((doc) =>
    //         listOfMeeting.push(new Meeting(doc.data() as IMeeting, doc.id))
    //       );
    //       let meetingTypeFiltered = listOfMeeting.filter((val) => {
    //         return result.meetingTypeName.includes(val.meetingType)
    //       });
    //       let final = meetingTypeFiltered.filter((val) => {
    //         return this.findCommonElement(result.vistingSubDivisionIds, val.visitingSubDivisionId)
    //       });
    //       if (final.length > 0) {
    //         final.map((res) => {
    //           filteredData.push(res)
    //         })
    //       }
    //     });
    // }
    // return filteredData;
  }

  findCommonElement(array1, array2) {
    // Loop for array1
    for (let i = 0; i < array1.length; i++) {
      // Loop for array2
      for (let j = 0; j < array2.length; j++) {
        // Compare the element of each and
        // every element from both of the
        // arrays
        if (array1[i] === array2[j]) {
          // Return if common element found
          return true;
        }
      }
    }
    // Return if no common element exist
    return false;
  }

  public fetchAllMeeting() {
    return this.afs
      .collection(FirebaseConstant.Meeting_COLL_REF)
      .get()
      .toPromise()
      .then((snap) => {
        const listOfMeeting: Meeting[] = [];

        snap.docs.map((doc) => {
          if (doc.data().status != Status.DELETED) {
            listOfMeeting.push(new Meeting(doc.data() as IMeeting, doc.id));
          }
        });
        return listOfMeeting;
      });
  }

  public fetchMeetingFromApi(filter: FilterData): Observable<any> {
    let formData = {};
    formData = {
      start_date: this._datePipe.transform(
        filter.duration.start,
        "yyyy-MM-dd 00:00:00"
      ),
      end_date: this._datePipe.transform(
        filter.duration.end,
        "yyyy-MM-dd 23:59:59"
      ),
      meetingType:
        filter.meetingTypeName.length > 0 ? filter.meetingTypeName : null,
      hostingSubDivisionId:
        filter.hostingSubDivisionIds.length > 0
          ? filter.hostingSubDivisionIds
          : null,
      visitingSubDivisionId:
        filter.vistingSubDivisionIds.length > 0
          ? filter.vistingSubDivisionIds
          : null,
      timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
    };

    let url = environment.apiUrl;
    return this.http.post(
      url,
      formData
      // {
      // start_date: this._datePipe.transform(filter.duration.start, "yyyy-MM-dd 00:00:00"),
      // end_date: this._datePipe.transform(filter.duration.end, "yyyy-MM-dd 23:59:59"),
      // meetingType: filter.meetingTypeName.length > 0 ? filter.meetingTypeName : null,
      // hostingSubDivisionId: filter.hostingSubDivisionIds.length > 0 ? filter.hostingSubDivisionIds : null,
      // visitingSubDivisionId: filter.vistingSubDivisionIds.length > 0 ? filter.vistingSubDivisionIds : null,
      // timezone: Intl.DateTimeFormat().resolvedOptions().timeZone
      // }
    );
  }
}
