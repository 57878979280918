import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { DashboardComponent } from './dashboard.component';
import { MeetingComponent } from './meeting/meeting.component';
import { SubdivisionComponent } from './subdivision/subdivision.component';
import { LocalityComponent } from './locality/locality.component';
import { NotificationComponent } from './notification/notification.component';
import { SettingComponent } from './setting/setting.component';
import { AdminComponent } from './admin/admin.component';
import { UserComponent } from './user/user.component';
import { ViewSubDivisionComponent } from './view-sub-division/view-sub-division.component';


const routes: Routes = [
  {
    path: '',
    component: DashboardComponent,
    children: [
      { path: '', component:  LocalityComponent},
      { path: 'meetings', component: MeetingComponent },
      { path: 'subdivisions', component: SubdivisionComponent },
      { path: 'localities', component: LocalityComponent },
      { path: 'notifications', component: NotificationComponent },
      { path: 'settings', component: SettingComponent },
      { path: 'admin', component: AdminComponent },
      { path: 'users', component: UserComponent },
      { path: 'subdivisions/:id', component: ViewSubDivisionComponent}
    ]
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class DashboardRoutingModule {}
