import {
  Component,
  ElementRef,
  NgZone,
  OnInit,
  ViewChild,
  Inject
} from '@angular/core';
import { FormGroup, FormBuilder } from '@angular/forms';
import {MAT_DIALOG_DATA} from '@angular/material/dialog';
import { MouseEvent } from '@agm/core';
import { Locality, ILocality } from '../../_models/locality';
import * as firebase from 'firebase/app';
import { LocalityService } from '../../locality/locality.service';
import { SubdivisionService } from '../../subdivision/subdivision.service';
import { Subdivision } from '../../_models/subDivision';
import {} from 'googlemaps';
import { MapsAPILoader } from '@agm/core';
import { ToastrService } from 'ngx-toastr';
import { MeetingService } from '../../meeting/meeting.service';

@Component({
  selector: 'app-dialog-add-sub-division',
  templateUrl: './dialog-add-sub-division.component.html',
  styleUrls: ['./dialog-add-sub-division.component.scss']
})
export class DialogAddSubDivisionComponent implements OnInit {
  public localities: Locality[] = [];
  public subDivisionInfo: FormGroup;
  public subDivison: Subdivision;
  public markers: Imarker[] = [];
  public zoom = 10;
  public latitude = -31.952850;
  public longitude = 115.857310;
  @ViewChild('search', { static: true })
  public searchElementRef: ElementRef;
  public isEditEnabled = false;
  public timeZone;
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private formBuilder: FormBuilder,
    private localityService: LocalityService,
    private subDivisionService: SubdivisionService,
    private mapsAPILoader: MapsAPILoader,
    private ngZone: NgZone,
    private toastr: ToastrService,
    private meetingService: MeetingService,
  ) {}

  ngOnInit() {
    this.setupForm();
    this.fetchLocality();
    if (this.data.action === 'edit') {
      this.isEditEnabled = true;
      this.subDivison = this.data.subDivision;
      this.subDivisionInfo.setValue({
        subDivisionName: this.subDivison.subDivisionName,
        localityId: this.subDivison.localityId,
        address: this.subDivison.address
      });
      this.markers = [];
      this.markers.push({
        lat: this.subDivison.location.latitude,
        lng: this.subDivison.location.longitude,
        draggable: true
      });
      this.latitude = this.subDivison.location.latitude;
      this.longitude = this.subDivison.location.longitude;
      this.zoom = 16;
    } else {
      this.setCurrentPosition();
    }
  }
  private checkRequestOfAddOfSubDivFromLocality(data:any){
    console.log(data)
    if(data.isFromLocality){
      this.subDivisionInfo.patchValue({localityId:data.localityId},{onlySelf:true})
    }
  }
  private setCurrentPosition() {
    if ('geolocation' in navigator) {
      navigator.geolocation.getCurrentPosition(position => {
        this.latitude = position.coords.latitude;
        this.longitude = position.coords.longitude;
        this.zoom = 12;
      });
    }
  }
  addSubDivision() {
     const subDivision = {
      subDivisionName: this.subDivisionInfo.value.subDivisionName,
      localityId: this.subDivisionInfo.value.localityId,
      address: this.subDivisionInfo.value.address,
      location: new firebase.firestore.GeoPoint(
        this.markers[0].lat,
        this.markers[0].lng
      ),
    timeZoneID : this.timeZone
      };
    if (this.data.action === 'edit') {
      this.subDivisionService.updateSubDivision_(subDivision as Subdivision, this.subDivison.key$)
      .then(res => {
        this.toastr.success(
          'Success',
          'Successfully Updated SubDivision '
        );
      })
      .catch(err => {
        this.toastr.error(
          'Error',
          `${err.message}`
        );
      });
    } else {
      this.subDivisionService
      .addSubDivision_(subDivision as Subdivision)
      .then(res => {
        this.toastr.success(
          'Success',
          'Your New Sub Division is Added Successfully'
        );
      })
      .catch(err => {
        this.toastr.error(
          'Error',
          `${err.message}`
        );
      });
    }
  }
  fetchLocality() {
    this.localityService.fetchLocalities().subscribe(res => {
      res.forEach(doc => {
        this.localities.push(
          new Locality(doc.payload.doc.data() as ILocality, doc.payload.doc.id)
        );
      });
    this.checkRequestOfAddOfSubDivFromLocality(this.data)

    });
  }
  setupForm() {
    this.subDivisionInfo = this.formBuilder.group({
      subDivisionName: [''],
      localityId: [''],
      address: ['']
    });
    this.mapsAPILoader.load().then(() => {
      const autocomplete = new google.maps.places.Autocomplete(
        this.searchElementRef.nativeElement,
        {
          types: ['address']
        }
      );
      autocomplete.addListener('place_changed', () => {
        this.ngZone.run(() => {
          const place: google.maps.places.PlaceResult = autocomplete.getPlace();
          if (place.geometry === undefined || place.geometry === null) {
            return;
          }
          this.subDivisionInfo.value.address = place.formatted_address;
          this.latitude = place.geometry.location.lat();
          this.longitude = place.geometry.location.lng();
          this.markers = [];
          this.markers.push({
            lat: place.geometry.location.lat(),
            lng: place.geometry.location.lng(),
            draggable: true
          });
          this.zoom = 12;
        });
      });
    });
    this.meetingService.getTimezone(this.longitude, this.latitude).then((data)=>{
      this.timeZone = data.timeZoneId;
   })
  }
  clickedMarker(label: string, index: number) {
    console.log(`clicked the marker: ${label || index}`);
  }

  mapClicked($event: MouseEvent) {
    this.markers = [];
    this.markers.push({
      lat: $event.coords.lat,
      lng: $event.coords.lng,
      draggable: true
    });
    this.fetchLocationName($event.coords.lat, $event.coords.lng);
  }

  markerDragEnd(marker: Imarker, $event: MouseEvent) {
    this.fetchLocationName($event.coords.lat, $event.coords.lng);
  }

  fetchLocationName(latData: number, lngData: number) {
    const geocoder = new google.maps.Geocoder();
    const latlng = { lat: latData, lng: lngData };
    geocoder.geocode({ location: latlng }, (results, status) => {
      if (status === 'OK') {
        const result = results[0];
        this.subDivisionInfo.value.address = result.formatted_address;
        this.searchElementRef.nativeElement.value = result.formatted_address;
      }
    });
  }
}

interface Imarker {
  lat: number;
  lng: number;
  label?: string;
  draggable: boolean;
}
