import { Component, OnInit, Inject } from "@angular/core";
import { FormGroup, FormBuilder } from "@angular/forms";
import { DialogMeetingRecurrenceComponent } from "../dialog-meeting-recurrence/dialog-meeting-recurrence.component";
import { MatDialog } from "@angular/material";
import { MAT_DIALOG_DATA } from "@angular/material/dialog";
import {
  Status,
  MEETINGTYPES,
  UPDATE_MEETING_BODY,
} from "src/app/util/database";
import { MeetingService } from "../../meeting/meeting.service";
import { SubdivisionService } from "../../subdivision/subdivision.service";
import { Subdivision, ISubdivision } from "../../_models/subDivision";
import { Meeting, IMeeting } from "../../_models/meeting";
import { IMeetingType, MeetingType } from "../../_models/meetingType";
import { DialogMatSelectComponent } from "../dialog-mat-select/dialog-mat-select.component";
import { MultiSelectMatComponent } from "../multi-select-mat/multi-select-mat.component";
import * as moment from "moment";
import { ToastrService } from "ngx-toastr";
import {
  MeetingDialogData,
  DeleteMetingDialogComponent,
} from "../delete-meting-dialog/delete-meting-dialog.component";
import { AngularFireFunctions } from "@angular/fire/functions";
import { NgxSpinnerService } from "ngx-spinner";
import { Router } from "@angular/router";
import { MultiSelectMatOldComponent } from "../multi-select-mat-old/multi-select-mat-old.component";

@Component({
  selector: "app-dialog-add-meeting",
  templateUrl: "./dialog-add-meeting.component.html",
  styleUrls: ["./dialog-add-meeting.component.scss"],
})
export class DialogAddMeetingComponent implements OnInit {
  public meetingInfo: FormGroup;
  public meeting: Meeting;
  public newDate: Date = new Date();
  public listOfSubDivision: Subdivision[] = [];
  public listOfMeetingType: MeetingType[] = [];
  public finalDataSet: IMeeting;
  public hostingSubDivision: Subdivision[] = [];
  public hostingBtnLabel = "Select Hosting Sub-Division";
  public visitingBtnLabel = "Select Visiting Sub-Division/s";

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private formBuilder: FormBuilder,
    public dialog: MatDialog,
    private meetingSerivices: MeetingService,
    private subDivisionService: SubdivisionService,
    private toastr: ToastrService,
    private spinner: NgxSpinnerService,
    private fns: AngularFireFunctions,
    private router: Router
  ) {}

  ngOnInit() {
    console.log("data",this.data)
    this.setupForm();
    this.fetchSubDivisions(() => {
      this.fetchMeetingType(() => {
        if (this.data && this.data.action === "edit") {
          this.meeting = this.data.meeting;
          this.hostingBtnLabel = this.listOfSubDivision.find(
            (e) => e.key$ === this.meeting.hostingSubDivisionId
          ).subDivisionName;
          this.visitingBtnLabel = "";
          const getListOfIdFromSet: string[] = [];
          let count = 0;
          this.meeting.visitingSubDivisionId.forEach((doc) => {
            this.visitingBtnLabel += this.listOfSubDivision.find(
              (e) => e.key$ === doc
            ).subDivisionName;
            getListOfIdFromSet.push(doc);
            if (count !== this.meeting.visitingSubDivisionId.length - 1) {
              this.visitingBtnLabel += ", ";
            }
            count++;
          });
          this.meetingInfo.setValue({
            meetingType: this.meeting.meetingType,
            hostingSubDivisionId: this.listOfSubDivision.find(
              (e) => e.key$ === this.meeting.hostingSubDivisionId
            ).key$,
            visitingSubDivisionId: this.meeting.visitingSubDivisionId,
            meetingDate: this.meeting.meetingDate,
            meetingTime: this.meeting.meetingTime,
            recurrenceDetail: this.meeting.recurrenceDetail,
          });
          this.finalDataSet = this.meetingInfo.value;
          this.newDate = this.meeting.meetingDate;
        }
      });
    });
  }
  openMatSelectDialog() {
    const dialogRef = this.dialog.open(DialogMatSelectComponent, {
      width: "400px",
      minHeight: "200px",
    });
    dialogRef.afterClosed().subscribe((result) => {
      this.meetingInfo.controls.hostingSubDivisionId.patchValue(result.key$);
      this.hostingBtnLabel = "";
      this.hostingBtnLabel = result.subDivisionName;
    });
  }
  openMultipleMatSelect() {
    const dialogRef = this.dialog.open(MultiSelectMatOldComponent, {
      width: "400px",
      minHeight: "200px",
    });
    dialogRef.afterClosed().subscribe((result) => {
      console.log(result)
      this.visitingBtnLabel = "";
      const getListOfIdFromSet: string[] = [];
      let count = 0;
      result.forEach((doc) => {
        this.visitingBtnLabel += doc.subDivisionName;
        getListOfIdFromSet.push(doc.key$);
        if (count !== result.size - 1) {
          this.visitingBtnLabel += ", ";
        }
        count++;
      });
      this.meetingInfo.controls.visitingSubDivisionId.patchValue(
        getListOfIdFromSet
      );
    });
  }
  fetchMeetingType(callback: () => void) {
    this.meetingSerivices.fetchAllMeetingType().subscribe((res) => {
      res.forEach((doc) => {
        this.listOfMeetingType.push(
          new MeetingType(doc.data() as IMeetingType, doc.id)
        );
      });
      callback();
    });
  }
  fetchSubDivisions(callback: () => void) {
    this.subDivisionService.fetchSubDivisions().subscribe((res) => {
      res.forEach((doc) => {
        this.listOfSubDivision.push(
          new Subdivision(
            doc.payload.doc.data() as ISubdivision,
            doc.payload.doc.id
          )
        );
      });
      callback();
    });
  }
  filterDataOfSubDivisionForVisiting() {
    return this.listOfSubDivision.filter(
      (x) => x.key$ !== this.meetingInfo.value.hostingSubDivisionId
    );
  }
  EndDateChange(event) {
    this.newDate = new Date(event.value.getTime());
  }
  setupForm() {
    this.meetingInfo = this.formBuilder.group({
      meetingType: [""],
      hostingSubDivisionId: [""],
      visitingSubDivisionId: [""],
      meetingDate: [""],
      meetingTime: [""],
      recurrenceDetail: [""],
    });
  }
  mergeDateAndTime(time: string, date: Date): Date {
    const dateString = moment(date).format("ddd DD-MMM-YYYY");
    return moment(`${dateString}, ${time}`, "ddd DD-MMM-YYYY, hh:mm A")
      .utc()
      .toDate();
  }

  addMeeting() {
    if (this.data && this.data.action === "edit") {
      this.finalDataSet = { ...this.finalDataSet, ...this.meetingInfo.value };
    }
    this.finalDataSet.visitingSubDivisionId.push(
      this.finalDataSet.hostingSubDivisionId
    );
    this.finalDataSet.meetingDate = this.mergeDateAndTime(
      this.finalDataSet.meetingTime,
      this.finalDataSet.meetingDate
    );
    this.finalDataSet.status = Status.ACTIVE;
    this.finalDataSet.isParent = true;
    if (this.data && this.data.action === "edit") {
      this.updateMeeting(this.meeting, this.finalDataSet);
    } else {
      this.spinner.show();
      this.meetingSerivices
        .saveMeeting_(this.finalDataSet)
        .then((res) => {
          this.toastr.success("Your Meeting Was Added Successfully.");
          // enhancement required
          this.spinner.hide();
          this.router.navigate([this.router.url]);
          // window.location.reload();
        })
        .catch((err) => {
          this.toastr.error("Error", err.message);
        });
    }
  }

  updateMeeting(meeting: Meeting, finalDataset: any) {
    const dialogData: MeetingDialogData = {
      title: "Update Confirmation",
      body: UPDATE_MEETING_BODY(meeting),
      isDeleteAble: true,
      deleteNumber: 0,
    };
    const dialogRef = this.dialog.open(DeleteMetingDialogComponent, {
      data: dialogData,
      width: "450px",
      minHeight: "100px",
    });

    dialogRef.afterClosed().subscribe((result: MeetingDialogData) => {
      if (result.isDeleteAble) {
        console.log("here")
        // this.spinner.hide();
        this.spinner.show();
        this.finalDataSet["meetingDate"] =
          this.finalDataSet["meetingDate"].toISOString();
        if (this.finalDataSet["endOn"]) {
          this.finalDataSet["endOn"] = this.finalDataSet["endOn"].toISOString();
        }

        this.meetingSerivices
          .updateMeetingApi(finalDataset, result.deleteNumber, this.meeting.id)
          .then((res) => {
            if (res) {
              // this.spinner.show();
              this.toastr.success(
                "Success",
                "Your Meeting Was Updated Successfully."
              );
              this.spinner.hide();
              console.log("here")
              this.router.navigate([this.router.url]);
              // window.location.reload();
              // this.meetingListData = [];
              // this.toastr.success("Meeting Deleted Successfully.");
              // this.router.navigate([this.router.url]);
            } else {
              // this.toastr.error(res.message);
              this.toastr.error(res.message);
              this.spinner.hide();
            }
          });
      }
    });

    // dialogRef.afterClosed().subscribe((result: MeetingDialogData) => {
    //   if (result.isDeleteAble) {
    //     switch (result.deleteNumber) {
    //       case 1:
    //         this.spinner.show();
    //         return this.meetingSerivices
    //           .updateMeeting(this.finalDataSet, this.meeting.key$)
    //           .then((res) => {
    //             this.spinner.hide();
    //             this.toastr.success(
    //               "Success",
    //               "Your Meeting Was Updated Successfully."
    //             );
    //             window.location.reload();
    //           })
    //           .catch((err) => {
    //             this.spinner.hide();
    //             this.toastr.error("Error", err.message);
    //           });
    //       case 2:
    //         this.finalDataSet.meetingDate =
    //           this.finalDataSet.meetingDate.getTime();
    //         this.spinner.show();

    //         return this.fns
    //           .httpsCallable("updateMeeting")({
    //             meetingNumber: 2,
    //             meetingID: this.meeting.key$,
    //             data: this.finalDataSet,
    //           })
    //           .subscribe((res) => {
    //             this.spinner.hide();
    //             this.toastr.success("Your Meeting Was Updated Successfully.");
    //             window.location.reload();
    //           });
    //       case 3:
    //         this.spinner.show();

    //         this.finalDataSet.meetingDate =
    //           this.finalDataSet.meetingDate.getTime();
    //         return this.fns
    //           .httpsCallable("updateMeeting")({
    //             meetingNumber: 3,
    //             meetingID: this.meeting.key$,
    //             data: this.finalDataSet,
    //           })
    //           .subscribe((res) => {
    //             this.toastr.success("Your Meeting Was Updated Successfully.");
    //             this.spinner.hide();

    //             window.location.reload();
    //           });
    //       default:
    //         break;
    //     }
    //   }
    // });
  }

  showRecurrenceDialog() {
    let newData = null;
    if (this.data && this.data.action === "edit") {
      newData = this.meeting;
    }
    const dialogRef = this.dialog.open(DialogMeetingRecurrenceComponent, {
      data: newData,
      width: "400px",
      minHeight: "200px",
    });
    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        this.finalDataSet = { ...this.meetingInfo.value, ...result };
      }
    });
  }
  changeToString(): string {
    const newmoment = moment(this.newDate);
    switch (this.weekOfMonth(newmoment)) {
      case 1:
        return "First";
      case 2:
        return "Second";
      case 3:
        return "Third";
      case 4:
        return "Fourth";
      default:
        return;
    }
  }
  weekOfMonth(m) {
    return m.week() - moment(m).startOf("month").week() + 1;
  }
  recurrenceOptionSelected(selectedOption: string) {
    if (selectedOption === MEETINGTYPES.CUSTOM) {
      this.showRecurrenceDialog();
    } else {
      this.finalDataSet = this.meetingInfo.value;
    }
  }
  openrecurrence() {
    if (
      this.data &&
      this.data.action === "edit" &&
      this.meeting.recurrenceDetail === "custom"
    ) {
      this.showRecurrenceDialog();
    }
  }
}
