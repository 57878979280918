import {
  Component,
  OnInit,
  Inject,
  ViewEncapsulation,
  ViewChild
} from '@angular/core';
import { LocalityService } from '../../locality/locality.service';
import {
  MAT_DIALOG_DATA,
  MatPaginator,
  MatSort,
  MatTableDataSource,
  MatDialog
} from '@angular/material';
import { Locality } from '../../_models/locality';
import { AngularFirestore } from '@angular/fire/firestore';
import {
  FirebaseConstant,
  DELETE_MEETING_TYPE_TITLE,
  DELETE_MEETING_TYPE_BODY
} from 'src/app/util/database';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { IMeetingType, MeetingType } from '../../_models/meetingType';
import { MeetingService } from '../../meeting/meeting.service';
import {
  DialogData,
  ConfirmationDialogComponent
} from '../confirmation-dialog/confirmation-dialog.component';
import { Notification } from '../../_models/notification';

@Component({
  selector: 'app-add-meeting-type',
  templateUrl: './add-meeting-type.component.html',
  styleUrls: ['./add-meeting-type.component.scss']
})
export class AddMeetingTypeComponent implements OnInit {
  public meetingTypeName: string;
  public dataSourceForTable;
  public displayedColumns: string[] = ['meetingTypeName', 'actionsColumn'];
  @ViewChild(MatPaginator, { static: false }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: false }) sort: MatSort;
  constructor(
    private meetingService: MeetingService,
    private afs: AngularFirestore,
    private toastr: ToastrService,
    private spinner: NgxSpinnerService,
    public dialog: MatDialog,
    @Inject(MAT_DIALOG_DATA) public meetingType: MeetingType
  ) {}

  ngOnInit() {
    this.fetchAllMeetingType();
  }
  deleteMeetingType(type: MeetingType) {
    const dialogData: DialogData = { title: '', body: '', isDeleteAble: false };
    dialogData.title = DELETE_MEETING_TYPE_TITLE;
    dialogData.body = DELETE_MEETING_TYPE_BODY(type);
    dialogData.isDeleteAble = true;
    const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
      width: '400px',
      height: 'auto',
      data: dialogData
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result.isDeleteAble) {
        this.meetingService
          .deleteMeetingType(type.key$)
          .then(() => {
            this.toastr.success(
              'Success',
              `A New Meeting Type Named ${type.meetingTypeName} is deleted`
            );
          })
          .catch(err => {
            this.toastr.error('Error', err.message);
          });
      }
    });
  }
  setDataToTable(listOfMeeting: MeetingType[]) {
    this.dataSourceForTable = new MatTableDataSource<MeetingType>(
      listOfMeeting
    );
    this.dataSourceForTable.paginator = this.paginator;
    this.dataSourceForTable.sort = this.sort;
  }

  fetchAllMeetingType() {
    this.spinner.show();
    this.meetingService.fetchAllMeetingTypeSnap().subscribe(res => {
      const listOfMeetingType: MeetingType[] = [];
      res.forEach(doc => {
        listOfMeetingType.push(
          new MeetingType(
            doc.payload.doc.data() as IMeetingType,
            doc.payload.doc.id
          )
        );
      });
      console.log(listOfMeetingType)
      this.setDataToTable(listOfMeetingType);
      this.spinner.hide();
    });
  }
  addMeetingType() {
    if (this.meetingTypeName) {
      this.meetingService
        .addMeetingType_(this.meetingTypeName)
        .then(res => {
          this.meetingTypeName = '';
          this.toastr.success(
            'Success',
            `A New Meeting Type Named ${this.meetingTypeName} is Added`
          );
        })
        .catch(err => {
          this.toastr.error('Error', err.message);
        });
    }
  }
}
