import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder } from '@angular/forms';
import { AuthService } from '../service/auth.service';
import { AngularFireAuth } from '@angular/fire/auth';
import { Router } from '@angular/router';
import { User } from '../model/user';
import { NgxSpinnerService } from 'ngx-spinner';
import { MatDialog } from '@angular/material';
import { DialogResetPasswordComponent } from '../authDialogBox/dialog-reset-password/dialog-reset-password.component';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {
  public loginInfo: FormGroup;
  public user: any;
  public show: boolean = false;

  constructor(
    private FORMBUILDER: FormBuilder,
    private AUTHSERVICE: AuthService,
    private ROUTER: Router,
    private spinner: NgxSpinnerService,
    public dialog: MatDialog,
    public AUTH: AngularFireAuth,
    private toastr: ToastrService
  ) { }

  ngOnInit() {
    this.setupForm();
  }
  setupForm() {
    this.loginInfo = this.FORMBUILDER.group({
      email: [''],
      password: ['']
    });
  }
  openEmailInputDialog() {
    this.dialog.open(DialogResetPasswordComponent);
  }
  signIn() {
    this.spinner.show();
    const loginData = new User(this.loginInfo.value);
    this.AUTHSERVICE.loginWithEmail(loginData)
      .then(res => {
        this.AUTH.auth.currentUser.getIdTokenResult().then(idTokenResult => {
          if (!!idTokenResult.claims.role.admin) {
            this.ROUTER.navigate(['/']);
          } else {
            this.toastr.error('Error', 'Unauthorized Admin');
            this.AUTH.auth.signOut();
          }
          if (!!idTokenResult.claims.role.admin) {
            this.ROUTER.navigate(['/']);
          } else {
            this.toastr.error('Error', 'Unauthorized Admin');
            this.AUTH.auth.signOut();
          }
        }).catch(error => {
          this.AUTH.auth.signOut().then(() => {
            this.ROUTER.navigate(['/login']);
          });
        });
        this.spinner.hide();
      })
      .catch(err => {
        this.toastr.error('Error', err.message);
        this.spinner.hide();
      });
  }

  showHidePwd() {
    this.show = !this.show;

  }

}
