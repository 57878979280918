import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { LoginComponent } from './login/login.component';
import { AuthRoutingModule } from './authrouting.module';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { DialogResetPasswordComponent } from './authDialogBox/dialog-reset-password/dialog-reset-password.component';
import { MatDialogModule, MatButtonModule } from '@angular/material';
@NgModule({
  declarations: [LoginComponent, DialogResetPasswordComponent],
  imports: [
    CommonModule,
    AuthRoutingModule,
    ReactiveFormsModule,
    FormsModule,
    MatDialogModule,
    MatButtonModule
  ],
  entryComponents: [DialogResetPasswordComponent]
})
export class AuthModule {}
