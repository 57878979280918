import { ToastrService } from "ngx-toastr";
import { Router } from "@angular/router";
import { Locality, ILocality } from "./../_models/locality";
import { LocalityService } from "./../locality/locality.service";
import { Component, OnInit, ViewChild } from "@angular/core";
import { MatPaginator } from "@angular/material/paginator";
import { MatTableDataSource } from "@angular/material/table";
import { MatTableExporterDirective } from "mat-table-exporter";
import { MatDialog } from "@angular/material";
import { DialogAddMeetingComponent } from "../dialogBox/dialog-add-meeting/dialog-add-meeting.component";
import { MeetingService } from "./meeting.service";
import { IMeeting, Meeting } from "../_models/meeting";
import { ISubdivision, Subdivision } from "../_models/subDivision";
import { MatSort } from "@angular/material/sort";
import { SubdivisionService } from "../subdivision/subdivision.service";
import {
  DeleteMetingDialogComponent,
  MeetingDialogData,
} from "../dialogBox/delete-meting-dialog/delete-meting-dialog.component";
import { DELETE_MEETING_BODY } from "src/app/util/database";
import { NgxSpinnerService } from "ngx-spinner";
import { AngularFireFunctions } from "@angular/fire/functions";
import { MeetingMenuService } from "../template/meeting-menu/meeting-menu.service";
import * as moment from "moment";
import { FilterData } from "../dialogBox/dialog-filter/dialog-filter.component";
import { map, startWith, switchMap } from "rxjs/operators";
import { merge, Observable } from "rxjs";
import { DateRange } from "@uiowa/date-range-picker";
import { DatePipe } from "@angular/common";
@Component({
  selector: "app-meeting",
  templateUrl: "./meeting.component.html",
  styleUrls: ["./meeting.component.scss"],
})
export class MeetingComponent implements OnInit {
  public displayedColumns: string[] = [
    "meetingType",
    "meetingDate",
    "meetingTime",
    "hostingLocalityName",
    "hostingSubDivisionName",
    "visitingSubDivisionName",
    // "visitingSubDivisionId",
    "recurrenceDetail",
    "actionsColumn",
  ];
  private subdivisionList: Subdivision[] = [];
  private localityList: Locality[] = [];
  private uniqueSubdivisionIDs: Set<string> = new Set();
  public dataSourceForTable = new MatTableDataSource<Meeting>();
  public isShowCalender = false;
  public searchFilterValue: string;
  private meetingListData: Meeting[] = [];
  @ViewChild(MatPaginator, { static: false }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: false }) sort: MatSort;
  @ViewChild(MatTableExporterDirective, { static: false })
  exporter: MatTableExporterDirective;

  // pagination
  startAt = 0;
  lastMeetingDate: Meeting;
  firstMeetingDate: Meeting;
  page = 1;
  pageSize: number;
  pageSizes = [50, 100];
  // meetingCount: number = 0;
  meetingCount$: Observable<any>;
  lastPageMeetings: number;

  public totalCount: number;
  public filterValue;
  public nextWeekDate = new Date();
  public dateRange;
  public localData;
  public filterVal;
  public previousDate;
  public searchName;

  constructor(
    public meetingService: MeetingService,
    public dialog: MatDialog,
    private subdivsionService: SubdivisionService,
    private spinner: NgxSpinnerService,
    private MenuService: MeetingMenuService,
    private fns: AngularFireFunctions,
    private _localityService: LocalityService,
    private router: Router,
    private toastr: ToastrService
  ) {
    /**
     * Generate a date range starting from today and a week from today.
     */
    this.nextWeekDate.setDate(this.nextWeekDate.getDate() + 7);
    this.dateRange = new DateRange(new Date(), this.nextWeekDate);
  }

  async ngOnInit() {
    this.spinner.show();
    this.pageSize = this.pageSizes[0];
    await this.getAllLocalities();
    await this.getAllSubdivisions(() => {});
    // this.fetchingTotalMeetingCount();
    this.localData = JSON.parse(localStorage.getItem("token"));
    this.previousDate = JSON.parse(localStorage.getItem("previousSearchDate"));
    console.log(this.localData);
    console.log(this.previousDate);
    console.log(this.dateRange);

    if (this.localData) {
      console.log("here")
      this.filterVal = {
        duration: this.localData.duration,
        meetingTypeName: this.localData.meetingTypeName,
        hostingSubDivisionIds: this.localData.hostingSubDivisionIds,
        vistingSubDivisionIds: this.localData.vistingSubDivisionIds,
      };
      if (this.previousDate) {
        console.log("here")
        let start;
        let end;
        let searchDate;
        console.log(start);
        this.filterVal = {
          duration: this.localData.duration? this.localData.duration: this.previousDate.dateRange,
          meetingTypeName: this.localData ? this.localData.meetingTypeName : [],
          hostingSubDivisionIds: this.localData
            ? this.localData.hostingSubDivisionIds
            : [],
          vistingSubDivisionIds: this.localData
            ? this.localData.vistingSubDivisionIds
            : [],
        };
        if (
          this.previousDate.dateRange.start ||
          this.previousDate.dateRange.end
        ) {
          start = new Date(this.previousDate.dateRange.start);
          console.log(start);
          end = new Date(this.previousDate.dateRange.end);
          searchDate = {
            start: start,
            end: end,
          };
          console.log(searchDate);
          this.dateRange = searchDate;
        }
        console.log(this.dateRange);
        // this.dateRange = this.previousDate.dateRange.toDate();
        if (this.previousDate.searchFilterValue) {
          this.applyFilter(this.previousDate.searchFilterValue);
          this.searchName = this.previousDate.searchFilterValue;
        }
        localStorage.removeItem("previousSearchDate");
      }
    } else if (this.previousDate) {
      let start;
      let end;
      let searchDate;
      console.log(start);
      this.filterVal = {
        duration: this.previousDate.dateRange,
        meetingTypeName: this.localData ? this.localData.meetingTypeName : [],
        hostingSubDivisionIds: this.localData
          ? this.localData.hostingSubDivisionIds
          : [],
        vistingSubDivisionIds: this.localData
          ? this.localData.vistingSubDivisionIds
          : [],
      };
      if (
        this.previousDate.dateRange.start ||
        this.previousDate.dateRange.end
      ) {
        start = new Date(this.previousDate.dateRange.start);
        console.log(start);
        end = new Date(this.previousDate.dateRange.end);
        searchDate = {
          start: start,
          end: end,
        };
        console.log(searchDate);
        this.dateRange = searchDate;
      }
      console.log(this.dateRange);
      // this.dateRange = this.previousDate.dateRange.toDate();
      if (this.previousDate.searchFilterValue) {
        this.applyFilter(this.previousDate.searchFilterValue);
        this.searchName = this.previousDate.searchFilterValue;
      }
      localStorage.removeItem("previousSearchDate");
    } else {
      console.log("here");
      this.filterVal = {
        duration: this.dateRange,
        meetingTypeName: [],
        hostingSubDivisionIds: [],
        vistingSubDivisionIds: [],
      };
    }
    // this.filterValue = {
    //   duration: this.dateRange,
    //   meetingTypeName: [],
    //   hostingSubDivisionIds: [],
    //   vistingSubDivisionIds: [],
    // };
    this.filterByDialog(this.filterVal);
    // this.fetchAllMeet();
    this.MenuService.showHideCalender().subscribe(
      (item) => (this.isShowCalender = item)
    );
    this.MenuService.filterData().subscribe((result) => {
      this.page = 1;
      this.paginator.pageIndex = 0;
      this.filterValue = result;
      this.filterData(result);
    });
    this.MenuService.meetingAddEmitter().subscribe((result) => {
      if (result) {
        // console.log("meeting add emitter ", result, this.router.url);
      }
    });
  }

  dateRangeSelected(e) {
    if (this.dateRange.start && this.dateRange.end) {
      console.log("here");
      console.log("dateRange", this.dateRange);
      console.log(this.localData);
      this.localData = JSON.parse(localStorage.getItem("token"));

      this.filterByDialog({
        duration: this.dateRange,
        meetingTypeName: this.localData ? this.localData.meetingTypeName : [],
        hostingSubDivisionIds: this.localData
          ? this.localData.hostingSubDivisionIds
          : [],
        vistingSubDivisionIds: this.localData
          ? this.localData.vistingSubDivisionIds
          : [],
      });
    }
  }
  // ngAfterViewInit() {
  //   merge(this.paginator.page)
  //     .pipe(
  //       startWith({}),
  //       switchMap(() => {
  //         this.spinner.show();
  //         switch (this.page) {
  //           case "next":
  //             return this.meetingService.fetchMeeting(
  //               this.paginator.pageSize,
  //               this.lastMeetingDate,
  //               this.page
  //             );

  //           case "prev":
  //             return this.meetingService.prevPage(
  //               this.paginator.pageSize,
  //               this.firstMeetingDate
  //             );

  //           case "first":
  //             return this.meetingService.firstPage(this.paginator.pageSize);

  //           case "last":
  //             return this.meetingService.lastPage(this.lastPageMeetings);

  //           default:
  //             return this.meetingService.fetchMeeting(
  //               this.paginator.pageSize,
  //               null
  //             );
  //         }
  //       }),
  //       map((data: Meeting[]) => {
  //         this.spinner.hide();
  //         if (data === null) {
  //           return [];
  //         }
  //         // pagination
  //         this.startAt = this.paginator.pageSize * this.paginator.pageIndex;
  //         this.lastMeetingDate = data[data.length - 1];
  //         this.firstMeetingDate = data[0];

  //         data.forEach((meeting) => {
  //           this.uniqueSubdivisionIDs.add(meeting.hostingSubDivisionId);
  //           return meeting.visitingSubDivisionId.forEach((id) => {
  //             this.uniqueSubdivisionIDs.add(id);
  //           });
  //         });
  //         // sorting
  //         this.meetingListData = data.sort((meeting1, meeting2) => {
  //           return (
  //             meeting1.meetingDate.getTime() - meeting2.meetingDate.getTime()
  //           );
  //         });
  //         return data;
  //       })
  //     )
  //     .subscribe((data) => {
  //       this.dataSourceForTable.data = data;
  //       this.dataSourceForTable.sort = this.sort;
  //     });
  // }

  async fetchingTotalMeetingCount() {
    this.meetingCount$ = this.meetingService.getMeetingCount();
  }

  mergeDateAndTime(time: string, date: Date): Date {
    const dateString = moment(date).format("ddd DD-MMM-YYYY");
    return moment(`${dateString}, ${time}`, "ddd DD-MMM-YYYY, hh:mm A")
      .utc()
      .toDate();
  }
  exportToExcel() {
    this.exporter.exportTable("xlsx", { fileName: "Meetings" });
  }
  exportToCSV() {
    this.exporter.exportTable("csv", { fileName: "Meetings" });
  }

  filterData(result: any) {
    result.showAllData ? this.showAllData() : this.filterByDialog(result.data);
  }
  async filterByDialog(result?: FilterData) {
    this.spinner.show();
    /** ------ for testing ------ */
    await this.meetingService.fetchMeetingFromApi(result).subscribe(
      (res) => {
        this.totalCount = res.count;
        res.data.map((meeting) => {
          meeting.hostingLocalityName = this.getSpecificLocality(
            meeting.hostingSubDivisionId
          );
          meeting.hostingSubDivisionName = this.getSubdivision(
            meeting.hostingSubDivisionId,
            null,
            null
          );
          meeting.visitingSubDivisionName = [];
          meeting.visitingSubDivisionId.map((id) => {
            meeting.visitingSubDivisionName.push(
              this.getSubdivision(
                id,
                meeting.hostingSubDivisionId,
                meeting.visitingSubDivisionId.length
              )
            );
            meeting.recurrenceDetailName = this.getRecurranceDetail(meeting);
          });
        });
        this.setDataToTable(res.data as Meeting[]);
        if (this.searchFilterValue) {
          console.log(this.searchFilterValue);
          this.applyFilter(this.searchFilterValue);
        }
        this.spinner.hide();
      },
      (err) => {
        this.spinner.hide();
      }
    );

    // ----- using cloudfunction for pagination ----
    // const temp = await this.meetingService.fetchByFilter(
    //   this.page,
    //   this.pageSize,
    //   result ? result : null
    // );
    // if (temp && temp.data) {
    //   temp.data.map((data) => {
    //     console.log(data)
    //     data.meetingDate = new Date(data.meetingDate._seconds * 1000)
    //     data = new Meeting(data as IMeeting, data.id)
    //   })
    // }
    // ----- using cloudfunction for pagination ----

    // await this.meetingService.fetchByFilter(
    //   result
    // )
    // this.meetingListData.forEach((doc) => {
    //   if (
    //     result.hostingSubDivisionIds.includes(doc.hostingSubDivisionId) &&
    //     moment(doc.meetingDate).isBetween(
    //       moment(result.duration.start),
    //       moment(result.duration.end).add(1, "day")
    //     )
    //   ) {
    //     if (result.meetingTypeName.includes(doc.meetingType)) {
    //       result.vistingSubDivisionIds.forEach((visitingId) => {
    //         if (doc.visitingSubDivisionId.includes(visitingId)) {
    //           temp.push(doc);
    //         }
    //       });
    //     }
    //   }
    // });

    // this.totalCount = temp.meta.count;
    // this.setDataToTable(temp.data);
    // this.spinner.hide();
  }
  showAllData() {
    // this.setDataToTable(this.meetingListData);
    this.page = 1;
    this.pageSize = this.pageSizes[0];
    this.filterByDialog();
  }
  filterByDialogHostingSubDiv(result: any) {
    const temp: Meeting[] = [];
    this.meetingListData.forEach((doc) => {
      result.forEach((res) => {
        if (doc.hostingSubDivisionId === res.key$ && !temp.includes(doc)) {
          temp.push(doc);
        }
      });
    });
    this.setDataToTable(temp);
  }
  filterByDialogVisitingSubDiv(result: any) {
    const temp: Meeting[] = [];
    this.meetingListData.forEach((doc) => {
      result.forEach((res) => {
        if (
          doc.visitingSubDivisionId.includes(res.key$) &&
          !temp.includes(doc)
        ) {
          temp.push(doc);
        }
      });
    });
    this.setDataToTable(temp);
  }

  async getAllLocalities() {
    return await this._localityService.fetchAllLocalities().then((_res) => {
      _res.forEach((snap) => {
        this.localityList.push(new Locality(snap.data() as ILocality, snap.id));
      });
    });
  }

  getSpecificLocality(id: string) {
    const subDivision = this.subdivisionList.find((x) => x.key$ === id);
    return subDivision
      ? this.localityList.find((x) => x.key$ === subDivision.localityId)
          .localityName
      : null;
  }
  async getAllSubdivisions(callback: () => void) {
    return await this.subdivsionService.fetchAllSubDivisions().then((_res) => {
      _res.forEach((snap) => {
        this.subdivisionList.push(
          new Subdivision(snap.data() as ISubdivision, snap.id)
        );
      });
      callback();
    });
  }
  /**
   * It returns locality name
   * @param subDivisionId
   */
  async getLocality(subDivisionId: string) {
    return this._localityService.fetchLocality(
      (await this.subdivsionService.fetchSpecifcSubDivision(subDivisionId))
        .localityId
    );
  }
  getRecurranceDetail(element: Meeting) {
    const dayforMeeting = moment(element.meetingDate).format("dddd");
    switch (element.recurrenceDetail) {
      case "none":
        return "None";
      case "daily":
        return "Daily";
      case "weeklyOnDay":
        return "Weekly On " + dayforMeeting;
      case "monthlyOnDay":
        return "Monthly On " + dayforMeeting;
      case "anuallyOnDay":
        return "Anually On " + dayforMeeting;
      case "everyWeekday":
        return "Monday To Friday";
      case "custom":
        return "Custom";
      default:
        break;
    }
  }

  deleteMeeting(meeting: Meeting) {
    const dialogData: MeetingDialogData = {
      title: "Delete Confirmation",
      body: DELETE_MEETING_BODY(meeting),
      isDeleteAble: true,
      deleteNumber: 0,
    };

    const dialogRef = this.dialog.open(DeleteMetingDialogComponent, {
      data: dialogData,
      width: "450px",
      minHeight: "100px",
    });
    dialogRef.afterClosed().subscribe((result: MeetingDialogData) => {
      if (result.isDeleteAble) {
        this.spinner.show();
        console.log("date range", this.dateRange);
      console.log("search filter value", this.searchFilterValue);
      let dataStore = {
        dateRange: this.dateRange,
        searchFilterValue: this.searchFilterValue,
      };
      // this.previousDate = this.dateRange
      localStorage.setItem("previousSearchDate", JSON.stringify(dataStore));
        this.meetingService
          .deleteMeetingApi(meeting, result.deleteNumber)
          .then((res) => {
            if (res) {
              this.meetingListData = [];
              this.toastr.success("Meeting Deleted Successfully.");
              this.spinner.hide();
              this.router.navigate([this.router.url]);
            } else {
              this.toastr.error(res.message);
            }
          });
      }
    });
  }
  setDataToTable(listOfMeeting: Meeting[]) {
    this.dataSourceForTable = new MatTableDataSource<Meeting>(listOfMeeting);
    this.dataSourceForTable.paginator = this.paginator;
    this.dataSourceForTable.sort = this.sort;
  }

  applyFilter(filterValue: string) {
    // if(!this.searchFilterValue){
    //   this.searchFilterValue = filterValue;
    // }
    this.searchFilterValue = filterValue;
    this.dataSourceForTable.filter = filterValue.trim().toLowerCase();
    this.dataSourceForTable.filterPredicate = this.getFilterPredicate();
  }

  /** Custom search for multiple column search */
  getFilterPredicate() {
    return (row: Meeting, filters: string) => {
      const matchFilter = [];
      // Fetch data from row
      const columnMeetingType = row.meetingType.trim().toLowerCase();
      const columnHostingLocalityName = row.hostingLocalityName
        .trim()
        .toLowerCase();
      const columnVisitingSubDivisionName = row.visitingSubDivisionName.map(
        (name) => {
          return name.toLowerCase();
        }
      );
      const columnHostingSubDivisionName = row.hostingSubDivisionName
        .trim()
        .toLowerCase();
      const columnRecurrenceDetail = row.recurrenceDetail.trim().toLowerCase();
      // verify fetching data by our searching values
      const customFilterMT = columnMeetingType.includes(filters);
      const customFilterHS = columnHostingSubDivisionName.includes(filters);
      const customFilterHL = columnHostingLocalityName.includes(filters);
      const customFilterVS = columnVisitingSubDivisionName.includes(filters);
      const customFilterRD = columnRecurrenceDetail.includes(filters);
      // push boolean values into array
      matchFilter.push(customFilterMT);
      matchFilter.push(customFilterHS);
      matchFilter.push(customFilterHL);
      matchFilter.push(customFilterVS);
      matchFilter.push(customFilterRD);
      // return true if all values in array is true
      // else return false
      return matchFilter.includes(true);
    };
  }

  showCalender() {
    this.isShowCalender = !this.isShowCalender;
  }

  getSubdivision(id: string, hostingId: string, count: number) {
    if (id === hostingId) {
      const subdivision = this.subdivisionList.find((x) => x.key$ === id);
      return subdivision ? subdivision.subDivisionName : "";
    } else if (hostingId == null && count == null) {
      const subdivision = this.subdivisionList.find((x) => x.key$ === id);
      return subdivision ? subdivision.subDivisionName : "";
    } else if (count > 1) {
      const subdivision = this.subdivisionList.find((x) => x.key$ === id);
      return subdivision ? subdivision.subDivisionName + ", " : "";
    } else {
      const subdivision = this.subdivisionList.find((x) => x.key$ === id);
      return subdivision ? subdivision.subDivisionName : "";
    }
  }
  editMeeting(meetingData: Meeting) {
    const dialogRef = this.dialog.open(DialogAddMeetingComponent, {
      data: { action: "edit", meeting: meetingData },
      width: "450px",
      minHeight: "100px",
    });

    dialogRef.afterClosed().subscribe((result) => {
      // this.meetingListData = [];
      // this.fetchAllData();\
      console.log("date range", this.dateRange);
      console.log("search filter value", this.searchFilterValue);
      let dataStore = {
        dateRange: this.dateRange,
        searchFilterValue: this.searchFilterValue,
      };
      // this.previousDate = this.dateRange
      localStorage.setItem("previousSearchDate", JSON.stringify(dataStore));
      // if(this.dateRange){
      //   this.dateRangeSelected(this.dateRange)
      // }
      // ddsa
    });
  }

  goTo(event: any) {
    this.page = event.pageIndex + 1;
    this.pageSize = event.pageSize;
    if (this.filterValue) {
      this.filterByDialog(this.filterValue.data);
    } else {
      this.filterByDialog();
    }
    // const lastPageIndex = Math.ceil(event.pageIndex + 1);
    // const lastPage = Math.ceil(event.length / event.pageSize);
    // const lastPageMeetingCount = event.length % event.pageSize;
    // if (lastPageMeetingCount === 0) {
    //   this.lastPageMeetings = event.pageSize;
    // } else {
    //   this.lastPageMeetings = lastPageMeetingCount;
    // }
    // if (event.previousPageIndex > event.pageIndex && event.pageIndex === 0) {
    //   this.page = "first";
    // } else if (
    //   event.previousPageIndex < event.pageIndex &&
    //   lastPage === lastPageIndex
    // ) {
    //   this.page = "last";
    // } else if (event.previousPageIndex > event.pageIndex) {
    //   this.page = "prev";
    // } else if (event.previousPageIndex == event.pageIndex) {
    //   this.page = "";
    // } else if (event.previousPageIndex < event.pageIndex) {
    //   this.page = "next";
    // }
  }

  fetchAllMeet() {
    this.meetingService.fetchAllMeeting().then((res) => {});
  }
}

export enum MeetingRecurranceType {
  none,
  daily,
  weeklyOnDay,
  monthlyOnDay,
  anuallyOnDay,
  everyWeekday,
  custom,
}
