import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';

export interface MeetingDialogData {
  title: string;
  body: string;
  isDeleteAble: boolean;
  deleteNumber: number;
}
@Component({
  selector: 'app-delete-meting-dialog',
  templateUrl: './delete-meting-dialog.component.html',
  styleUrls: ['./delete-meting-dialog.component.scss']
})
export class DeleteMetingDialogComponent implements OnInit {
  constructor(
    public dialogRef: MatDialogRef<DeleteMetingDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: MeetingDialogData
  ) {}
  public meetingRadio: number;
  ngOnInit() {}
  closeDialogBox() {
    this.dialogRef.close(this.data);
  }
  closeWithYesDialogBox() {
    this.data.deleteNumber = this.meetingRadio;
    this.data.isDeleteAble = true;
    this.dialogRef.close(this.data);
  }
}
