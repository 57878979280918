import { Component, Inject, OnInit } from '@angular/core';
import { DialogMeetingTypeComponent } from '../dialog-meeting-type/dialog-meeting-type.component';
import { MultiSelectMatComponent } from '../multi-select-mat/multi-select-mat.component';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { DateRange } from '@uiowa/date-range-picker';
import { Subdivision } from '../../_models/subDivision';
import { MeetingType } from '../../_models/meetingType';

@Component({
  selector: 'app-dialog-filter',
  templateUrl: './dialog-filter.component.html',
  styleUrls: ['./dialog-filter.component.scss']
})
export class DialogFilterComponent implements OnInit {
  public dateRange = new DateRange(new Date(), new Date());
  public hostingSubDiv: Subdivision[] = [];
  public visitingSubDiv: Subdivision[] = [];
  public meetingTypes: Set<MeetingType> = new Set();
  public hostingSubDivIds: string[] = [];
  public visitingSubDivIds: string[] = [];
  public meetingTypesName: string[] = [];
  public hostingLabel = 'Choose Hosting Localities/Sub-Divisions';
  public visitingLabel = 'Choose Visiting Localities/Sub-Divisions';
  public meetingTypeLabel = 'Choose Meeting Type';
  public filterMeetingType= '';
  public previousHostingLabel= ""
  public data
  public previousDate = new DateRange(new Date(), new Date());
  public previousSendDataSet = []
  public previousSubDivisionSendDataSet = []
  public previousSendMeetingType = []
  public localData
  public dateWasChanged: boolean = false;
  constructor(
    public dialog: MatDialog,
    private dialogRef: MatDialogRef<MultiSelectMatComponent>,

    ) { }
  ngOnInit() {
    let sdata = localStorage.getItem('token')
    this.data = JSON.parse(sdata)
    this.localData = this.data
    console.log(this.data)
    if(this.data){
      this.previousDate["end"] = new Date(this.data.duration.end)
      this.previousDate["start"] = new Date(this.data.duration.start)

      this.dateRange = this.previousDate
      this.hostingLabel = this.data.data.previousHostingLabel
      this.visitingLabel = this.data.data.previousVisitingLabel
      this.meetingTypeLabel  = this.data.data.previousMeetingTypeLabel

      if(this.data.hostingLocality || this.data.hostingSubDiv || this.data.typeOfMeeting){
        this.previousSendDataSet = this.data.hostingLocality
        this.previousSubDivisionSendDataSet = this.data.hostingSubDiv
        this.previousSendMeetingType = this.data.typeOfMeeting
        
        
      }
    }
    

  }


  filterByDialogMeetingType() {
    const dialogRef = this.dialog.open(DialogMeetingTypeComponent, {
      width: '400px',
      minHeight: '200px',
      data: this.previousSendMeetingType
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.previousSendMeetingType = result;

        this.meetingTypes = result;
        this.meetingTypeLabel = '';
        const getListOfIdFromSet: string[] = [];
        let count = 0;
        result.forEach(doc => {
          this.meetingTypeLabel += doc.meetingTypeName;
          getListOfIdFromSet.push(doc.meetingTypeName);
          if (count !== result.size - 1) {
            this.meetingTypeLabel += ', ';
          }
          count++;
        });
        this.meetingTypesName = getListOfIdFromSet;

      }
    });
  }

    dateRangeChanged(e) {
      if (!this.dateWasChanged) {
        console.log(this.dateRange);
        this.dateWasChanged = true;
        this.dateRange = e;
      } else {
        //pass
        this.dateRange = e;
      }
    }


  filterByDialogHostingSubDiv() {
    const dialogRef = this.dialog.open(MultiSelectMatComponent, {
      width: '400px',
      minHeight: '200px',
      data: this.previousSendDataSet,
      // data: {
      //   dataKey: this.hostingSubDiv
      // }
    });
    dialogRef.afterClosed().subscribe(result => {
      let subDivision = []
      this.previousSendDataSet = result
      if (result) {
        result.forEach((t)=>{
          t.subtasks.forEach((data)=>{
            if(data.completed == true){
              subDivision.push(data)

            }

          })
        })
        this.hostingSubDiv = subDivision;
        this.hostingLabel = '';
        const getListOfIdFromSet: string[] = [];
        let count = 0;
        subDivision.forEach(doc => {
          this.hostingLabel += doc.subDivisionName;
          getListOfIdFromSet.push(doc.key$);
          if (count !== result.size - 1) {
            this.hostingLabel += ', ';
          }
          count++;
        });
        this.hostingSubDivIds = getListOfIdFromSet;
      }
    });
  }

  filterByDialogVisitingSubDiv() {
    const dialogRef = this.dialog.open(MultiSelectMatComponent, {
      width: '400px',
      minHeight: '200px',
      data: this.previousSubDivisionSendDataSet,

    });
    dialogRef.afterClosed().subscribe(result => {
      if (result) {
      let subDivision = []
      this.previousSubDivisionSendDataSet = result
      if (result) {
        result.forEach((t)=>{
          t.subtasks.forEach((data)=>{
            if(data.completed == true){
              subDivision.push(data)

            }

          })
        })
        this.visitingSubDiv = subDivision;
        this.visitingLabel = '';
        const getListOfIdFromSet: string[] = [];
        let count = 0;
        subDivision.forEach(doc => {
          this.visitingLabel += doc.subDivisionName;
          getListOfIdFromSet.push(doc.key$);
          if (count !== result.size - 1) {
            this.visitingLabel += ', ';
          }
          count++;
        });
        this.visitingSubDivIds = getListOfIdFromSet;
      }
    }
    });
  }
  submitFilter() {
    let selectedMeetingType = [];
    //if this.data is null then send alert and terminate

    if (
      this.visitingSubDiv.length ||
      this.hostingSubDiv.length ||
      this.meetingTypes.size ||
      (this.dateWasChanged && this.dateRange)
    ) {
      this.dateRange.start.setHours(0, 0, 0);
      this.dateRange.end.setHours(23, 59, 59);
      this.previousSendMeetingType.forEach((data) => {
        console.log(data);
        selectedMeetingType.push(data);
      });
      if (this.data == null) {
        this.data = {
          meetingTypeName: null,
          hostingSubDivisionsIds: null,
          visitingSubDivisionsIds: null,
        };
      }
      this.dialogRef.close({
        hostingSubDivisionIds: this.hostingSubDivIds.length
          ? this.hostingSubDivIds
          : this.data.hostingSubDivisionIds,
        vistingSubDivisionIds: this.visitingSubDivIds.length
          ? this.visitingSubDivIds
          : this.data.vistingSubDivisionIds,
        meetingTypeName: this.meetingTypesName.length
          ? this.meetingTypesName
          : this.data.meetingTypeName,
        duration: this.dateRange,
        data: {
          previousHostingLabel: this.hostingLabel,
          previousVisitingLabel: this.visitingLabel,
          previousMeetingTypeLabel: this.meetingTypeLabel,
        },
        hostingLocality: this.previousSendDataSet,
        hostingSubDiv: this.previousSubDivisionSendDataSet,
        typeOfMeeting: selectedMeetingType,
        // previousData: this.localData
      });
    }
  }
}
export interface FilterData {
  hostingSubDivisionIds: string[];
  vistingSubDivisionIds: string[];
  meetingTypeName: string[];
  duration: DateRange;
}
