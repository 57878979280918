import { Component, OnInit, ViewChild } from '@angular/core';
import { User } from '../_models/user';
import { UserService } from '../user/user.service';
import { Locality, ILocality } from '../_models/locality';
import { LocalityService } from '../locality/locality.service';
import { Subdivision, ISubdivision } from '../_models/subDivision';
import { SubdivisionService } from '../subdivision/subdivision.service';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { AngularFireFunctions } from '@angular/fire/functions';
import { IUser } from 'src/app/auth/model/user';
import { ToastrService } from 'ngx-toastr';
import { MultiSelectMatComponent } from '../dialogBox/multi-select-mat/multi-select-mat.component';
import {
  MatDialog,
  MatPaginator,
  MatSort,
  MatTableDataSource
} from '@angular/material';
import { NgxSpinnerService } from 'ngx-spinner';
import { NotificationService } from './notification.service';
import { Notification, INotification } from '../_models/notification';
import {
  DialogData,
  ConfirmationDialogComponent
} from '../dialogBox/confirmation-dialog/confirmation-dialog.component';
import {
  DELETE_NOTIFICATION_TITLE,
  DELETE_NOTIFICATION_BODY
} from 'src/app/util/database';
import { MultiSelectMatOldComponent } from '../dialogBox/multi-select-mat-old/multi-select-mat-old.component';

@Component({
  selector: 'app-notification',
  templateUrl: './notification.component.html',
  styleUrls: ['./notification.component.scss']
})
export class NotificationComponent implements OnInit {
  listOfUsers: User[];
  listOfLocality: Locality[];
  subDivision: Subdivision[] = [];
  public notificationGroup: FormGroup;
  public listOfFcmToken: Set<string> = new Set();
  public listOfUserIDS: Set<string> = new Set();
  public selectedSubDivId: Set<string> = new Set();
  public listOfNotification: Notification[] = [];
  public subDivisionBtnLabel = 'Select Recipients';
  public dataSourceForTable;
  public isShowNotifcationTable = false;
  public isShowNotificationHistory = false;
  public clickDialogCount = 0;
  public displayedColumns: string[] = [
    'title',
    'message',
    'scheduleDate',
    'scheduleTime',
    'actionsColumn'
  ];
  public getListOfIdFromSet: string[] = [];
  @ViewChild(MatPaginator, { static: false }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: false }) sort: MatSort;
  constructor(
    private userService: UserService,
    private localityService: LocalityService,
    private subDivisionService: SubdivisionService,
    private formBuilder: FormBuilder,
    private fns: AngularFireFunctions,
    private toastr: ToastrService,
    public dialog: MatDialog,
    private spinner: NgxSpinnerService,
    private notificationService: NotificationService
  ) {}

  ngOnInit() {
    this.setupForm();
  }
  setDataToTable(listOfNotification: Notification[]) {
    this.dataSourceForTable = new MatTableDataSource<Notification>(
      listOfNotification
    );
    //sorting scheduleDate
    this.dataSourceForTable.filteredData.forEach(data => {
      this.dataSourceForTable.filteredData.sort(function(date1:any,date2:any) {
        var date1Data = new Date(date1.scheduleDate),
        date2Data = new Date(date2.scheduleDate);
    // Compare the 2 dates
      if(date1Data < date2Data) return -1;
      if(date1Data > date2Data) return 1;
      return 0;
      })
    });
    setTimeout(() => this.dataSourceForTable.paginator = this.paginator);
    // this.dataSourceForTable.paginator = this.paginator;
    this.dataSourceForTable.sort = this.sort;
  }
  scheduleNotification() {
    this.notificationService
      .scheduleNotification(this.notificationGroup.value)
      .then(res => {
        this.toastr.success('Success', `Notification Scheduled Successfully`);
        window.location.reload();
        this.notificationGroup.reset();
        this.subDivisionBtnLabel = 'Select Recipients';
      })
      .catch(err => {
        this.toastr.error('Error', err.message);
      });
  }
  hideTable() {
    this.isShowNotifcationTable = false;
    this.isShowNotificationHistory = false;
  }
  showSchedule() {
    this.fetchAllScheduleNotifications();
  }
  fetchAllScheduleNotifications() {
    this.spinner.show();
    this.notificationService.fetchNotification().subscribe(res => {
      this.listOfNotification = [];
      res.forEach(doc => {
        this.listOfNotification.push(
          new Notification(
            doc.payload.doc.data() as INotification,
            doc.payload.doc.id
          )
        );
      });
      this.spinner.hide();
      this.setDataToTable(this.listOfNotification);
      this.isShowNotifcationTable = true;
      this.isShowNotificationHistory = false;
    });
  }
  openMultipleMatSelect() {
    const dialogRef = this.dialog.open(MultiSelectMatOldComponent, {
      width: '400px',
      minHeight: '200px',
      disableClose: true,
      data: this.notificationGroup.value.subDivisionId
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result.length) {
        this.clickDialogCount === 0 ? this.subDivisionBtnLabel = '' : '';
        let count = 0;
        result.forEach(doc => {
          this.subDivisionBtnLabel += doc.subDivisionName;
          this.getListOfIdFromSet.push(doc.key$);
          if (count !== result.size - 1) {
            this.subDivisionBtnLabel += ', ';
          }
          count++;
        });
        this.clickDialogCount++;
        this.notificationGroup.controls.subDivisionId.patchValue(
          this.getListOfIdFromSet
        );
      }
    });
  }
  setupForm() {
    this.notificationGroup = this.formBuilder.group({
      title: ['', Validators.required],
      message: ['', Validators.required],
      subDivisionId: ['', Validators.required],
      isSceduleNotificationEnable: [''],
      scheduleTime: [''],
      scheduleDate: ['']
    });
  }

  deleteScheduleNotification(notification: Notification) {
    const dialogData: DialogData = { title: '', body: '', isDeleteAble: false };
    dialogData.title = DELETE_NOTIFICATION_TITLE;
    dialogData.body = DELETE_NOTIFICATION_BODY(notification);
    dialogData.isDeleteAble = true;
    const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
      width: '400px',
      height: 'auto',
      data: dialogData
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.notificationService
          .deleteNotification(notification)
          .then(() => {
            this.toastr.success(
              'Success',
              `Scheduled Notification Deleted Successfully`
            );
          })
          .catch(err => {
            this.toastr.error('Error', err.message);
          });
      }
    });
  }
  fetchFcmTokensOfSubDivUser(subDivisionId: string[]) {
    let count = 0;
    const tempFcm: string[] = [];
    const tempUserID: string[] = [];
    this.spinner.show();
    subDivisionId.forEach(subdiv => {
      this.userService.fetchSpecificSubDivisionUsers(subdiv).subscribe(docs => {
        docs.forEach(doc => {
          if (doc.get('fcmToken')) {
            tempFcm.push(doc.get('fcmToken'));
          }
          tempUserID.push(doc.id);
        });
        if (count === subDivisionId.length - 1) {
          tempFcm.forEach(fcm => {
            this.listOfFcmToken.add(fcm);
          });
          tempUserID.forEach(uid => {
            this.listOfUserIDS.add(uid);
          });
          this.spinner.hide();
          this.callOfSendNotification(this.listOfFcmToken, this.listOfUserIDS);
        }
        count++;
      });
    });
  }
  sendNotification() {
    if (this.notificationGroup.value.subDivisionId.length >= 1) {
      this.fetchFcmTokensOfSubDivUser(
        this.notificationGroup.value.subDivisionId
      );
    }
  }
  callOfSendNotification(fcm: Set<string>, uid: Set<string>) {
    const tempFCM: string[] = Array.from(fcm);
    const tempUID: string[] = Array.from(uid);
    const data = {
      fcm: tempFCM,
      userID: tempUID
    };
    if (fcm.size) {
      this.fns
        .httpsCallable('sendNotification')({
          ...this.notificationGroup.value,
          fcmUID: data
        })
        .subscribe(res => {
          this.toastr.success(
            'Success',
            `Notification Send to ${fcm.size} Users Successfully`
          );
          window.location.reload();
          this.listOfFcmToken.clear();
          this.selectedSubDivId.clear();
          this.notificationGroup.reset();
          this.subDivisionBtnLabel = 'Select Recipients';
        });
    } else {
      this.toastr.warning('Fail', `No any Users has Notification registered`);
      this.listOfFcmToken.clear();
      this.selectedSubDivId.clear();
      this.notificationGroup.reset();
    }
  }
  viewNotification(){
    this.fetchNotificationHistory();
  }
  fetchNotificationHistory(){
    this.spinner.show();
    this.notificationService.fetchNotificationHistory().subscribe(res => {
      this.listOfNotification = [];
      res.forEach(doc => {
        this.listOfNotification.push(
          new Notification(
            doc.payload.doc.data() as INotification,
            doc.payload.doc.id
          )
        );
      });
      // getting scheduled time to dispay 
      this.listOfNotification.forEach(datas => {
        let scheduledDate = datas.scheduleDate;
        let hours = scheduledDate.getHours();
        let minutes:any = scheduledDate.getMinutes();
        let ampm = hours >= 12 ? 'pm' : 'am';
        hours = hours % 12;
        hours = hours ? hours : 12; // the hour '0' should be '12'
        minutes = minutes < 10 ? '0'+minutes : minutes;
        let strTime = hours + ':' + minutes + ' ' + ampm;
        datas.scheduleTime = strTime
      })
      this.spinner.hide();
      this.setDataToTableHistory(this.listOfNotification);
      this.isShowNotificationHistory = true;
      this.isShowNotifcationTable = false;
    });
  }
  setDataToTableHistory(listOfNotification: Notification[]) {
    this.dataSourceForTable = new MatTableDataSource<Notification>(
      listOfNotification
    );
    //sorting scheduleDate
    this.dataSourceForTable.filteredData.forEach(data => {
      this.dataSourceForTable.filteredData.sort(function(date1:any,date2:any) {
        var date1Data = new Date(date1.scheduleDate),
        date2Data = new Date(date2.scheduleDate);
    // Compare the 2 dates
      if(date1Data < date2Data) return 1;
      if(date1Data > date2Data) return -1;
      return 0;
      })
    });
    setTimeout(() => this.dataSourceForTable.paginator = this.paginator);
    // this.dataSourceForTable.paginator = this.paginator;
    this.dataSourceForTable.sort = this.sort;
  }
  deleteNotificationHistory(notification: Notification) {
    const dialogData: DialogData = { title: '', body: '', isDeleteAble: false };
    dialogData.title = DELETE_NOTIFICATION_TITLE;
    dialogData.body = DELETE_NOTIFICATION_BODY(notification);
    dialogData.isDeleteAble = true;
    const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
      width: '400px',
      height: 'auto',
      data: dialogData
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.notificationService
          .deleteSentNotification(notification)
          .then(() => {
            this.toastr.success(
              'Success',
              `Scheduled Notification Deleted Successfully`
            );
          })
          .catch(err => {
            this.toastr.error('Error', err.message);
          });
      }
    });
  }
}
