import { firestore } from 'firebase';

export interface INotification {
    title: string
    message:string
    scheduleDate:any
    scheduleTime:string
    subDivisionId:string[]
    status: string;
  }
  export class Notification implements INotification {
    key$: string;
    title: string
    message:string
    scheduleDate:Date
    scheduleTime:string
    subDivisionId:string[]
    status: string;
    constructor(notification: INotification, id: string) {
      this.key$ = id;
      this.title=notification.title
      this.message=notification.message
      this.scheduleDate=notification.scheduleDate.toDate()
      this.scheduleTime=notification.scheduleTime
      this.subDivisionId=notification.subDivisionId
      this.status=notification.status
  }
}