export interface IMeetingType {
    meetingTypeName: string;
    status: string;
  }

  export class MeetingType implements MeetingType {
    key$: string;
    status: string;
    meetingTypeName: string;

    constructor(locality: IMeetingType, id: string) {
      this.key$ = id;
      this.meetingTypeName = locality.meetingTypeName;
      this.status = locality.status;
    }
  }
  export class filterByMeetingType implements filterByMeetingType {
    key$: string;
    status: string;
    meetingTypeName: string;
    public isChecked: boolean;

    constructor(locality: IMeetingType, id: string, isChecked: boolean) {
      this.key$ = id;
      this.meetingTypeName = locality.meetingTypeName;
      this.status = locality.status;
      this.isChecked = isChecked;
    }
  }