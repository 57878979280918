import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators, AbstractControl } from '@angular/forms';
import { AdminService } from '../admin/admin.service';
import { AngularFireAuth } from '@angular/fire/auth';
import { IUser } from '../_models/user';
import { AngularFirestore } from '@angular/fire/firestore';
import { FirebaseConstant } from 'src/app/util/database';
import { MatDialog } from '@angular/material';
import { EnterOldPasswordComponent } from '../dialogBox/enter-old-password/enter-old-password.component';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-setting',
  templateUrl: './setting.component.html',
  styleUrls: ['./setting.component.scss']
})
export class SettingComponent implements OnInit {
  public settingInfo: FormGroup;
  public isPasswordClicked:boolean=false
  public loggedInUserInfo:IUser;
  public show: boolean = false;
  public reShow: boolean = false
  constructor(private adminService:AdminService,
              private formBuilder: FormBuilder,
              private auth:AngularFireAuth,
              private afs:AngularFirestore,
              private toastr: ToastrService,
              public dialog: MatDialog,) { }

  ngOnInit() {
    this.setupForm();
  }
  setupForm() {
    this.adminService.fetchSpecificAdmin(this.auth.auth.currentUser.uid)
    .subscribe(res=>{
      this.loggedInUserInfo=res.data() as IUser
      this.settingInfo = this.formBuilder.group({
        firstName: [this.loggedInUserInfo.firstName,Validators.required],
        lastName: [this.loggedInUserInfo.lastName,Validators.required],
        email: [this.loggedInUserInfo.email,Validators.required],
        passwords: this.formBuilder.group({
          password: [""],
          verifyPassword: [{value:"",disabled:!this.isPasswordClicked}]
      }, {validator: this.passwordConfirming}),

      });
    })
  
  }
  passwordConfirming(c: AbstractControl): { invalid: boolean } {
    if (c.get('password').value !== c.get('verifyPassword').value) {
        return {invalid: true};
    }
    
}
  enableVerify(){
    this.isPasswordClicked=true
    this.settingInfo.get('passwords').get('verifyPassword').enable({onlySelf:false})
  }
  fetchPassword(){
    let uid=this.auth.auth.currentUser.uid
    if(this.loggedInUserInfo.email!=this.settingInfo.value.email){
      const dialogRef = this.dialog.open(EnterOldPasswordComponent);
      dialogRef.afterClosed().subscribe(result => {
        console.log(this.isPasswordClicked)
        this.changeEmailInAuth(this.settingInfo.value.email,result
          ,uid)
      })
     }
      if(this.isPasswordClicked){
      const dialogRef = this.dialog.open(EnterOldPasswordComponent);
      dialogRef.afterClosed().subscribe(result => {
        console.log(this.isPasswordClicked)
        this.changePassword(this.settingInfo.value.passwords.password,result)
      })
     }
      if(this.loggedInUserInfo.firstName!=this.settingInfo.value.firstName){
         this.changeFirstName(this.settingInfo.value.firstName,uid)
     }
      if(this.loggedInUserInfo.lastName!=this.settingInfo.value.lastName){
       this.changeLastName(this.settingInfo.value.lastName,uid)
       
     }
   
  }
  changeFirstName(fName:string,id:string){
    this.afs.collection(FirebaseConstant.ADMIN_COLL_REF)
    .doc(id).update({"firstName":fName}).then(()=>{
      this.toastr.success('Success', `First Name Edited to ${fName} Successfully`);
    })
    .catch(err=>{
      this.toastr.error('Error', err.message);
  })
  }
  changeLastName(lName:string,id:string){
    console.log("hasjhdk: "+lName)
    this.afs.collection(FirebaseConstant.ADMIN_COLL_REF)
    .doc(id).update({"lastName":lName}).then(()=>{
      this.toastr.success('Success', `Last Name Edited to ${lName} Successfully`);
    })
    .catch(err=>{
      this.toastr.error('Error', err.message);
  })
  }
  changePassword(newPassword:string,currentPassword:string){
    this.auth.auth.signInWithEmailAndPassword(this.loggedInUserInfo.email,currentPassword)
      .then(credential=>{
        credential.user.updatePassword(newPassword)
        .then(() => {
          
        })
        .catch(err=>{
      this.toastr.error('Error', err.message);
        })
      })
    .then(()=>{}
    )
  }
  changeEmailInAuth(newEmail:string,password:string,id:string){
    this.auth.auth.signInWithEmailAndPassword(this.loggedInUserInfo.email,password)
      .then(credential=>{
        credential.user.updateEmail(newEmail)
        .then(() => {
          this.changeEmailInDb(newEmail,id)
        })
        .catch(err=>{
      this.toastr.error('Error', err.message);
        })
      })
    .then(()=>{}
    )
    .catch(err=>{
      this.toastr.error('Error', err.message);
    })
  }
  changeEmailInDb(newEmail:string,id:string){
    this.afs.collection(FirebaseConstant.ADMIN_COLL_REF)
    .doc(id).update({"email":newEmail}).then(()=>{
      this.toastr.success('Success', `Email of Admin is Edited Successfully`);
    })
    .catch(err=>{
      this.toastr.error('Error', err.message);
  })
  }

  
  showHidePwd() {
    this.show = !this.show;

  }

  reShowHidePwd() {
    this.reShow = !this.reShow
  }

}
