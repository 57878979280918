import { Subdivision } from '../dashboard/_models/subDivision';
import { Locality } from '../dashboard/_models/locality';
import { Meeting } from '../dashboard/_models/meeting';
import { User } from '../dashboard/_models/user';
import { MeetingType } from '../dashboard/_models/meetingType';
import { Notification } from '../dashboard/_models/notification';

export class Database {
}
export enum FirebaseConstant {
    USER_COLL_REF= 'User',
    ADMIN_COLL_REF= 'Admin',
    LOCALITY_COLL_REF= 'localities',
    SUBDIVISION_COLL_REF= 'subdivisions',
    Meeting_COLL_REF= 'meetings',
    Meeting_BACKUP_COLL_REF= 'meetingsback',
    SubMeeting_COLL_REF= 'meetingtypes',
    NotificationSchedule_COLL_REF= 'scheduleNotifications',
    ScheduleMeeting= 'scheduleMeeting',
    upComingMeeting = 'UpComingMeeting',
    NotificationHistory = 'notification',
    MEETING_COUNT = 'meetingCount'
}
export enum Status {
    ACTIVE= 'active',
    DELETED= 'deleted',
    COMPELTED= 'completed'
}
export enum MEETINGTYPES {
    CUSTOM= 'custom'
}

export const DELETE_LOCALITY_TITLE = 'Delete Confirmation';
export const DELETE_SUBDIVISION_TITLE = 'Delete Confirmation';
export const DELETE_MEETING_TITLE = 'Delete Confirmation';
export const DELETE_USER_TITLE = 'Delete Confirmation';
export const DELETE_MEETING_TYPE_TITLE = 'Delete Confirmation';
export const DELETE_ADMIN_TITLE = 'Delete Confirmation';
export const DELETE_NOTIFICATION_TITLE = 'Delete Confirmation';

export const DELETE_LOCALITY_BODY = (locality: Locality) => {
    return 'Are you sure you want to delete ' +
    locality.localityName + '?\n' ;
};
export const DELETE_NOTIFICATION_BODY = (notification: Notification) => {
    return 'Are you sure you want to delete ' +
    notification.title + '?\n' ;
};
export const DELETE_MEETING_TYPE_BODY = (meetingType: MeetingType) => {
    return 'Are you sure you want to delete ' +
    meetingType.meetingTypeName + '?\n' ;
};
export const UPDATE_MEETING_TYPE_BODY = (meetingType: MeetingType) => {
    return 'Are you sure you want to update ' +
    meetingType.meetingTypeName + '?\n' ;
};
// tslint:disable-next-line:max-line-length
export const DELETE_LOCALITY_CONFIRAMTION_BODY = 'You can not delete this locality. This locality has current data associated with it. Please check that any associated sub-divisions, users and meetings are deleted or changed before deleting this locality.';
export const DELETE_SUBDIVISION_BODY = (subdivision: Subdivision) => {
    return 'Are you sure you want to delete ' +
    subdivision.subDivisionName + '?\n';
};
// tslint:disable-next-line:max-line-length
export const DELETE_SUBDIVISION_CONFIRMATION_BODY = 'You can not delete this sub-division. This sub-division has current data associated with it. Please check that any associated users and meetings are deleted or changed before deleting this sub-division.';
export const DELETE_MEETING_BODY = (meeting: Meeting) => {
    return 'Are you sure you want to delete ' +
    meeting.meetingType + '?\n' ;
};export const UPDATE_MEETING_BODY = (meeting: Meeting) => {
    return 'Are you sure you want to update ' +
    meeting.meetingType + '?\n' ;
};
export const DELETE_USER_BODY = (user: User) => {
    return 'Are you sure you want to delete ' +
    user.fullName + '?\n' ;
};
export const DELETE_ADMIN_BODY = (admin: User) => {
    return 'Are you sure you want to delete ' +
    admin.fullName + '?\n' ;
};
