import { Component, OnInit, Inject } from '@angular/core';
import { SubdivisionService } from '../../subdivision/subdivision.service';
import { Locality } from '../../_models/locality';
import { MAT_DIALOG_DATA, MatDialog } from '@angular/material';
import { Subdivision, ISubdivision } from '../../_models/subDivision';
import { DialogAddSubDivisionComponent } from '../dialog-add-sub-division/dialog-add-sub-division.component';

@Component({
  selector: 'app-dialog-show-associated-sub-divs',
  templateUrl: './dialog-show-associated-sub-divs.component.html',
  styleUrls: ['./dialog-show-associated-sub-divs.component.scss']
})
export class DialogShowAssociatedSubDivsComponent implements OnInit {
  subDivs: Subdivision[] = [];

  constructor(
    private subDivisionService: SubdivisionService,
    public dialog: MatDialog,
    @Inject(MAT_DIALOG_DATA) public locality: Locality
  ) {}
  ngOnInit() {
    this.fetchSpecificSubDivs_(this.locality);
  }
  fetchSpecificSubDivs_(locality: Locality) {
    this.subDivisionService
      .fetchSubDivisionsOfSpecificLocalitySnap(locality.key$)
      .subscribe(res => {
        this.subDivs=[]
        res.forEach(doc => {
          this.subDivs.push(
            new Subdivision(doc.payload.doc.data() as ISubdivision, doc.payload.doc.id)
          );
        });
      });
  }
  showAddSubDivDialog(){
    const dialogRef = this.dialog.open(DialogAddSubDivisionComponent, {
      data: { 
        isFromLocality:true,
        localityId:this.locality.key$
       },
      width: '450px',
      minHeight: '100px',
      
    });
    dialogRef.afterClosed().subscribe(resultAfter => {
     
  });
  }
}
