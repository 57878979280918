import { Component, OnInit, Inject } from '@angular/core';
import { FormGroup, FormBuilder, AbstractControl } from '@angular/forms';
import { AngularFireFunctions } from '@angular/fire/functions';
import { NgxSpinnerService } from 'ngx-spinner';
import { MAT_DIALOG_DATA } from '@angular/material';
import { User } from '../../_models/user';
import { AngularFirestore } from '@angular/fire/firestore';
import { FirebaseConstant } from 'src/app/util/database';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-dialog-add-admin',
  templateUrl: './dialog-add-admin.component.html',
  styleUrls: ['./dialog-add-admin.component.scss']
})
export class DialogAddAdminComponent implements OnInit {
  public adminInfo: FormGroup;
  public show: boolean = false;
  public reShow: boolean = false;
  public emailAddressExists: Boolean
  constructor(
    private formBuilder: FormBuilder,
    private fns: AngularFireFunctions,
    private spinner: NgxSpinnerService,
    private toastr: ToastrService,
    private afs: AngularFirestore,
    @Inject(MAT_DIALOG_DATA) public admin: User
  ) { }

  ngOnInit() {
    if (this.admin) {
      this.setupForm(this.admin);
    } else {
      this.setupForm();
    }
  }
  setupForm(adminData?: User) {
    this.adminInfo = this.formBuilder.group({
      firstName: [adminData ? this.admin.firstName : ''],
      lastName: [adminData ? this.admin.lastName : ''],
      email: [adminData ? this.admin.email : ''],
      passwords: this.formBuilder.group(
        {
          password: [''],
          verifyPassword: ['']
        },
        { validator: this.passwordConfirming }
      )
    });
  }
  passwordConfirming(c: AbstractControl): { invalid: boolean } {
    if (c.get('password').value !== c.get('verifyPassword').value) {
      return { invalid: true };
    }
  }

  checkEmailAlreadyExist(email: string) {
    this.fns.httpsCallable('checkEmail')(email).subscribe((res) => {
      if (res.status === '404') {
        console.log('exist')
        this.emailAddressExists = true
      }
      else {
        console.log('doesnot')
        this.emailAddressExists = false
      }
    })
  }

  updateAdmin() {
    if (this.admin.firstName !== this.adminInfo.value.firstName) {
      console.log('1');
      this.saveFirstNameToDb(this.adminInfo.value.firstName, this.admin.key$);
    }
    if (this.admin.lastName !== this.adminInfo.value.lastName) {
      console.log('2');

      this.saveLastNameToDb(this.adminInfo.value.lastName, this.admin.key$);
    }
  }
  saveFirstNameToDb(fName: string, id) {
    this.afs
      .collection(FirebaseConstant.ADMIN_COLL_REF)
      .doc(id)
      .update({ firstName: fName })
      .then(() => {
        this.toastr.success('Success', `First Name Edited to ${fName} Successfully`);
      })
      .catch(err => {
        this.toastr.error('Error', `Error in editing First Name`);
      });
  }
  saveLastNameToDb(lName: string, id) {
    this.afs
      .collection(FirebaseConstant.ADMIN_COLL_REF)
      .doc(id)
      .update({ lastName: lName })
      .then(() => {
        this.toastr.success('Success', `Last Name Edited to ${lName} Successfully`);
      })
      .catch(err => {
        this.toastr.error('Error', `Error in editing Last Name`);
      });
  }
  getEditData() {
    this.adminInfo.value.firstName = this.admin.firstName;
    this.adminInfo.value.lastName = this.admin.lastName;
    this.adminInfo.value.email = this.admin.email;
  }
  addAdmin() {
    this.spinner.show();
    this.adminInfo.value.password = this.adminInfo.value.passwords.password;
    console.log(this.adminInfo.value);
    this.fns
      .httpsCallable('createAdmin')(this.adminInfo.value)
      .subscribe(res => {
        this.spinner.hide();
        this.toastr.success('Success', `A New Admin is Added Successfully`);
      });
  }

  showHidePwd() {
    this.show = !this.show;

  }

  reShowHidePwd() {
    this.reShow = !this.reShow
  }



}
