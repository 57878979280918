import { Component, OnInit, Inject } from '@angular/core';
import { FormGroup, FormBuilder } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import * as moment from 'moment';
import { Meeting } from '../../_models/meeting';
import { isThisMinute } from 'date-fns';
import { throwError } from 'rxjs';

@Component({
  selector: 'app-dialog-meeting-recurrence',
  templateUrl: './dialog-meeting-recurrence.component.html',
  styleUrls: ['./dialog-meeting-recurrence.component.scss']
})
export class DialogMeetingRecurrenceComponent implements OnInit {
  public recurrenceInfo: FormGroup;
  public daysData = {
    sun: false,
    mon: false,
    tue: false,
    wed: false,
    thu: false,
    fri: false,
    sat: false
  };
  public selectedDaysOfWeek: number[] = [];
  constructor(
    @Inject(MAT_DIALOG_DATA) public meetingdata: Meeting,
    private formBuilder: FormBuilder,
    private dialogRef: MatDialogRef<DialogMeetingRecurrenceComponent>
  ) {}

  ngOnInit() {
    this.setupForm();
  }
  setRepeatOn(date: Date): string {
    return moment(date)
      .format('ddd')
      .toLowerCase();
  }
  setupForm() {
    this.recurrenceInfo = this.formBuilder.group({
      repeatingNumber: [1],
      repeatBy: ['week'],
      endType: [''],
      endAfter: [15],
      endOn: [''],
      monthlyRepeatingType: ['sameDay']
    });
    if (this.meetingdata !== null && this.meetingdata.recurrenceDetail === 'custom') {
      if (this.meetingdata.repeatOn) {
        this.selectedDaysOfWeek = this.meetingdata.repeatOn;
      }
      this.setValueFrom();
    }
  }
  setValueFrom() {
    this.meetingdata.repeatOn.forEach(index => {
      this.daysData[Object.keys(this.daysData)[index]] = true;
    });
    const endonDate = this.meetingdata.endOn;
    this.recurrenceInfo.setValue({
      repeatingNumber: this.meetingdata.repeatingNumber,
      repeatBy: this.meetingdata.repeatBy,
      endType: this.meetingdata.endType,
      endAfter: this.meetingdata.endAfter,
      endOn: endonDate,
      monthlyRepeatingType: this.meetingdata.repeatTypeData
    });
  }
  sendRecurrenceData() {
    this.recurrenceInfo.value.repeatOn = this.selectedDaysOfWeek;
    if (this.meetingdata) {
      const formData: any = { ...this.meetingdata, ...this.recurrenceInfo.value }
      this.dialogRef.close(formData);
    } else {
      this.dialogRef.close(this.recurrenceInfo.value);
    }
  }
  addDay(data: string) {
    let repeatedDayboolean = true;
    this.daysData[data] = !this.daysData[data];
    if (this.selectedDaysOfWeek.length > 0) {
      let dayData = this.retunNumberOfWeek(data);
      this.selectedDaysOfWeek.forEach((v, index) => {
        if (v == dayData) {
          repeatedDayboolean = false;
          this.selectedDaysOfWeek.splice(index, 1);
        }
      })
      if (repeatedDayboolean == true) {
        this.selectedDaysOfWeek.push(this.retunNumberOfWeek(data));
      }
    }
    else {
      this.selectedDaysOfWeek.push(this.retunNumberOfWeek(data));
    }
  }
  setIsRepeated(data: string): boolean {
    if (data === 'Never') {
      return false;
    } else {
      return true;
    }
  }
  retunNumberOfWeek(data: string): number {
    if (data === 'sun') {
      return 0;
    } else if (data === 'mon') {
      return 1;
    } else if (data === 'tue') {
      return 2;
    } else if (data === 'wed') {
      return 3;
    } else if (data === 'thu') {
      return 4;
    } else if (data === 'fri') {
      return 5;
    } else {
      return 6;
    }
  }
}
