import { Component, OnInit, Inject } from "@angular/core";
import { SubdivisionService } from "../../subdivision/subdivision.service";
import { Subdivision, ISubdivision } from "../../_models/subDivision";
import { Locality, ILocality } from "../../_models/locality";
import { LocalityService } from "../../locality/locality.service";
import {
  MatCheckboxChange,
  MatDialogRef,
  ThemePalette,
} from "@angular/material";
import { MAT_DIALOG_DATA } from "@angular/material/dialog";

export interface Task {
  name: string;
  completed: boolean;
  color: ThemePalette;
  subtasks?: Task[];
}
@Component({
  selector: "app-multi-select-mat-old",
  templateUrl: "./multi-select-mat-old.component.html",
  styleUrls: ["./multi-select-mat-old.component.scss"],
})
export class MultiSelectMatOldComponent implements OnInit {
  public listOfSubDivision: CheckBoxData[] = [];
  public listOfLocality: CheckBoxLocalityData[] = [];
  public listOfCheckData: Set<CheckBoxData> = new Set();
  public dataLoaded = false;
  constructor(
    private localityService: LocalityService,
    private subDivisionService: SubdivisionService,
    private dialogRef: MatDialogRef<MultiSelectMatOldComponent>,
    @Inject(MAT_DIALOG_DATA) public selectedSubDiv=[]
  ) {}

  ngOnInit() {
    this.fetchLocality();
  }
  selectAllCheckBoxClicked(event, locality: Locality) {
    if (event.checked) {
      this.listOfSubDivision.forEach((data, index) => {
        if (data.data.localityId === locality.key$) {
          this.listOfSubDivision[index].isChecked = true;
          this.listOfCheckData.add(data);
        }
      });
    } else {
      this.listOfSubDivision.forEach((data, index) => {
        if (data.data.localityId === locality.key$) {
          this.listOfSubDivision[index].isChecked = false;
          this.listOfCheckData.delete(data);
        }
      });
    }
  }

  // cancelData(){
  //   this.dialogRef.close()
  // }
  checkBoxClicked(event, subDivisionId) {
    if (event.checked) {
      this.listOfCheckData.add(subDivisionId);
    } else {
      this.listOfCheckData.delete(subDivisionId);
    }
  }
  fetchSubDivision() {
    this.subDivisionService.fetchSubDivisions().subscribe(res => {
      res.forEach(doc => {
        let checkStatus=this.selectedSubDiv?this.selectedSubDiv.includes(doc.payload.doc.id):false
        const newSubDivison = new Subdivision(
          doc.payload.doc.data() as ISubdivision,
          doc.payload.doc.id
        );
        this.listOfSubDivision.push(new CheckBoxData(newSubDivison,checkStatus));
      });
      this.dataLoaded = true;
    });
  }
  fetchLocality() {
    this.localityService.fetchLocalities().subscribe(res => {
      res.forEach(doc => {
        this.listOfLocality.push(
          new CheckBoxLocalityData(
            new Locality(
              doc.payload.doc.data() as ILocality,
              doc.payload.doc.id
            ),false
          )
        );
      });
      this.fetchSubDivision();
    });
  }
  sendData() {
    const tempData = [];
    this.listOfCheckData.forEach(doc => {
      tempData.push(doc.data);
    });
    this.dialogRef.close(tempData);
    
  }
  selectAllSubDivCheckBoxClicked(event) {
    if (event.checked) {
      this.listOfLocality.forEach((data, index) => {
        this.listOfLocality[index].isChecked = true;
      });
      this.listOfSubDivision.forEach((data, index) => {
        this.listOfSubDivision[index].isChecked = true;
        this.listOfCheckData.add(data);
      });
    } else {
      this.listOfLocality.forEach((data, index) => {
        this.listOfLocality[index].isChecked = false;
      });
      this.listOfSubDivision.forEach((data, index) => {
        this.listOfSubDivision[index].isChecked = false;
        this.listOfCheckData.delete(data);
      });
    }
  }
}
export class CheckBoxData {
  public data: Subdivision;
  public isChecked: boolean;
  constructor(data: Subdivision,isChecked:boolean) {
    this.data = data;
    this.isChecked = isChecked;
  }
}
export class CheckBoxLocalityData {
  public data: Locality;
  public isChecked: boolean;
  constructor(data: Locality,isChecked:boolean) {
    this.data = data;
    this.isChecked = isChecked;
  }
}