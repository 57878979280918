import * as firebase from 'firebase/app';
export interface ISubdivision {
  subDivisionName: string;
  localityId: string;
  location: firebase.firestore.GeoPoint;
  status: string;
  address: string;
  timeZoneID: string;
}
export class Subdivision implements ISubdivision {
  key$: string;
  subDivisionName: string;
  status: string;
  localityId: string;
  location: firebase.firestore.GeoPoint;
  address: string;
  timeZoneID: string;
  localityName?: string;
  constructor(subDivision: ISubdivision, id: string) {
    this.key$ = id;
    this.subDivisionName = subDivision.subDivisionName;
    this.localityId = subDivision.localityId;
    this.location = subDivision.location;
    this.status = subDivision.status;
    this.address = subDivision.address;
    this.timeZoneID = subDivision.timeZoneID;
  }
}
