import { Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material';

@Component({
  selector: 'app-enter-old-password',
  templateUrl: './enter-old-password.component.html',
  styleUrls: ['./enter-old-password.component.scss']
})
export class EnterOldPasswordComponent implements OnInit {
  public password:string
  constructor(private dialogRef: MatDialogRef<EnterOldPasswordComponent>) { }

  ngOnInit() {
  }
  closeWithYesDialogBox(){
    this.dialogRef.close(this.password);
  }

}
