import { Injectable } from "@angular/core";
import { AngularFirestore } from "@angular/fire/firestore";
import { FirebaseConstant, Status } from "src/app/util/database";
import { Locality, ILocality } from "../_models/locality";

@Injectable({
  providedIn: "root",
})
export class LocalityService {
  constructor(private afs: AngularFirestore) {}
  addLocality_(name: string) {
    return this.afs.collection(FirebaseConstant.LOCALITY_COLL_REF).add({
      localityName: name,
      status: Status.ACTIVE,
    });
  }
  fetchLocality(id: string): Promise<Locality> {
    return this.afs
      .collection(FirebaseConstant.LOCALITY_COLL_REF)
      .doc(id)
      .get()
      .toPromise()
      .then((doc) => {
        return new Locality(doc.data() as ILocality, doc.id);
      });
  }
  fetchLocalities() {
    return this.afs
      .collection(FirebaseConstant.LOCALITY_COLL_REF, (ref) =>
        ref.where("status", "==", Status.ACTIVE).orderBy("localityName")
      )
      .snapshotChanges();
  }
  fetchAllLocalities() {
    return this.afs
      .collection(FirebaseConstant.LOCALITY_COLL_REF, (ref) =>
        ref.where("status", "==", Status.ACTIVE).orderBy("localityName")
      )
      .get()
      .toPromise();
  }
  deleteLocality(id: string) {
    return this.afs
      .collection(FirebaseConstant.LOCALITY_COLL_REF)
      .doc(id)
      .update({ status: Status.DELETED });
  }
  hasAssociatiedSubdivisoin(id: string): Promise<boolean> {
    return this.afs
      .collection(FirebaseConstant.SUBDIVISION_COLL_REF, (ref) =>
        ref.where("localityId", "==", id).where("status", "==", Status.ACTIVE)
      )
      .get()
      .toPromise()
      .then((snap) => {
        if (snap.empty) {
          return false;
        } else {
          return true;
        }
      });
  }
}
