import { ILocality } from "./locality";
import * as moment from "moment";

export interface IMeeting {
  meetingType: string;
  hostingSubDivisionId: string;
  visitingSubDivisionId: string[];
  meetingDate: any;
  meetingTime: string;
  recurrenceDetail: string;
  recurrenceDetailName?: string;
  endType?: string;
  endAfter?: number;
  endOn?: any;
  repeatBy?: string;
  repeatingNumber?: number;
  repeatOn?: number[];
  status?: string;
  isParent?: boolean;
  parentID?: string;
  meetingdatehr?: string;
  id?: string;
}
export class Meeting implements IMeeting {
  key$: string;
  id: string;
  meetingType: string;
  hostingSubDivisionId: string;
  hostingSubDivisionName?: string;
  hostingLocalityName?: string;
  visitingSubDivisionId: string[];
  visitingSubDivisionName?: string[];
  meetingDate: Date;
  meetingTime: string;
  recurrenceDetail: string;
  recurrenceDetailName?: string;
  endType?: string;
  endAfter?: number;
  endOn?: Date;
  repeatBy?: string;
  repeatingNumber?: number;
  repeatOn?: number[];
  repeatTypeData?: string;
  parentID?: string;
  status?: string;
  locality: ILocality;
  meetingdatehr?: string;
  constructor(meeting: IMeeting, id: string) {
    this.key$ = id;
    this.id = id;
    this.meetingType = meeting.meetingType;
    this.hostingSubDivisionId = meeting.hostingSubDivisionId;
    this.visitingSubDivisionId = meeting.visitingSubDivisionId;
    // this.meetingDate = meeting.meetingDate.toDate();
    this.meetingDate = toDate(meeting.meetingDate);

    this.meetingTime = meeting.meetingTime;
    this.recurrenceDetail = meeting.recurrenceDetail;
    this.endType = meeting.endType;
    this.endAfter = meeting.endAfter;
    if (!meeting.endOn) {
      this.endOn = meeting.endOn;
    } else {
      this.endOn = meeting.endOn.toDate();
    }
    this.status = meeting.status;
    this.repeatBy = meeting.repeatBy;
    this.repeatingNumber = meeting.repeatingNumber;
    this.repeatOn = meeting.repeatOn;
    this.parentID = meeting.parentID;
    this.meetingdatehr = meeting.meetingdatehr;
    this.repeatTypeData = this.getRepeatTypeData(this.recurrenceDetail);
  }

  getRepeatTypeData(repeatType: string) {
    switch (repeatType) {
      case "none":
        return "None";
      case "daily":
        return "Daily";
      case "weeklyOnDay":
        return "Weekly On " + moment(this.meetingDate).format("dddd");
      case "monthlyOnDay":
        return "Monthly On " + moment(this.meetingDate).format("dddd");
      case "anuallyOnDay":
        return "Anually On Day" + moment(this.meetingDate).format("dddd");
      case "everyWeekday":
        return "None";
      case "custom":
        return "Custom";
      default:
        return "None";
    }
  }
}

function toDate(data: any): Date {
  return new Date(data);
}
