import { Component, OnInit } from "@angular/core";
import { MatDialog } from "@angular/material";
import { DialogMeetingTypeComponent } from "../../dialogBox/dialog-meeting-type/dialog-meeting-type.component";
import { MultiSelectMatComponent } from "../../dialogBox/multi-select-mat/multi-select-mat.component";
import { MeetingMenuService, MeetingSortType } from "./meeting-menu.service";
import { DialogAddMeetingComponent } from "../../dialogBox/dialog-add-meeting/dialog-add-meeting.component";
import { AddMeetingTypeComponent } from "../../dialogBox/add-meeting-type/add-meeting-type.component";
import {
  DialogFilterComponent,
  FilterData,
} from "../../dialogBox/dialog-filter/dialog-filter.component";
import { Router } from "@angular/router";

@Component({
  selector: "app-meeting-menu",
  templateUrl: "./meeting-menu.component.html",
  styleUrls: ["./meeting-menu.component.scss"],
})
export class MeetingMenuComponent implements OnInit {
  public isShowCalender = false;
  public isFilterEnabled = false;
  filterData: any;
  public localData= []
  public localStoreHostingSubdivisionIds = []
  public localStoreVisitingSubdivisionIds = []

  constructor(
    public dialog: MatDialog,
    private MenuService: MeetingMenuService,
    private router: Router
  ) {}

  ngOnInit() {
    // let sdata = localStorage.getItem('token')
    //   this.filterData = JSON.parse(sdata)
    // // console.log(this.filterData.hostingSubDivisionIds)
    // if(this.filterData){
    //   this.localStoreHostingSubdivisionIds.push(this.filterData.hostingSubDivisionIds);
    //   this.localStoreVisitingSubdivisionIds.push(this.filterData.visitingSubDivIds)

    // }
  
    if(localStorage.getItem('token'))
    {
      this.isFilterEnabled = true
    }
  }
  showCalender() {
    this.isShowCalender = !this.isShowCalender;
    this.MenuService.changeCanlenderView();
  }
  removeFilter() {
    this.isFilterEnabled = !this.isFilterEnabled;
    localStorage.removeItem('token')
    //this fixes multiple meeting request issue
    window.location.reload();
    
    this.router.navigate(['/meetings']);
    this.MenuService.sendFilterDataType({ showAllData: true });
  }
  openFilterDialog() {
    console.log(this.filterData)
    const dialogRef = this.dialog.open(DialogFilterComponent, {
      width: "600px",
      minHeight: "200px",
      data: {
        dataKey: this.filterData
      }
    });
    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        
        localStorage.setItem('token',JSON.stringify(result))

        console.log("filter by result",result)
        this.filterData = result;
        this.isFilterEnabled = true;
        this.MenuService.sendFilterDataType({
          data: result,
          showAllData: false,
        });
      }
    });
  }
  filterByDialogMeetingType() {
    const dialogRef = this.dialog.open(DialogMeetingTypeComponent, {
      width: "400px",
      minHeight: "200px",
    });
    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        result.type = MeetingSortType.meetingType;
        this.MenuService.sendFilterDataType({
          data: result,
          showAllData: false,
        });
      }
    });
  }

  filterByDialogHostingSubDiv() {
    const dialogRef = this.dialog.open(MultiSelectMatComponent, {
      width: "400px",
      minHeight: "200px",
    });
    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        result.type = MeetingSortType.hosting;
        this.MenuService.sendFilterDataType(result);
      }
    });
  }

  showAllData() {
    const result = {
      type: MeetingSortType.all,
    };
    return this.MenuService.sendFilterDataType(result);
  }
  filterByDialogVisitingSubDiv() {
    const dialogRef = this.dialog.open(MultiSelectMatComponent, {
      width: "400px",
      minHeight: "200px",
    });
    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        result.type = MeetingSortType.visiting;
        this.MenuService.sendFilterDataType(result);
      }
    });
  }
  onAddMeeting() {
    const dialogRef = this.dialog.open(DialogAddMeetingComponent, {
      width: "450px",
      minHeight: "200px",
      disableClose: true,
    });

    dialogRef.afterClosed().subscribe((result) => {
      result && this.MenuService.meetingAdded();
    });
  }

  addSubMeeting() {
    const dialogRef = this.dialog.open(AddMeetingTypeComponent, {
      width: "900px",
      minHeight: "200px",
    });

    dialogRef.afterClosed().subscribe((result) => {});
  }
}
