export interface ITimeZone {
    dstOffset: number;
    rawOffset: number;
    status: string;
    timeZoneId: string;
    timeZoneName: string;
}
export class TimeZone implements ITimeZone {
    dstOffset: number;
    rawOffset: number;
    status: string;
    timeZoneId: string;
    timeZoneName: string;
  constructor(data: ITimeZone) {
    this.dstOffset = data.dstOffset;
    this.rawOffset = data.rawOffset;
    this.status = data.status;
    this.timeZoneId = data.timeZoneId;
    this.timeZoneName = data.timeZoneName;
  }
}
