import { Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material';
import { AngularFireAuth } from '@angular/fire/auth';
import { ToastrService } from 'ngx-toastr';
import { NgxSpinnerService } from 'ngx-spinner';

@Component({
  selector: 'app-dialog-reset-password',
  templateUrl: './dialog-reset-password.component.html',
  styleUrls: ['./dialog-reset-password.component.scss']
})
export class DialogResetPasswordComponent implements OnInit {
  public email:string
  constructor(public dialogRef: MatDialogRef<DialogResetPasswordComponent>,
              private auth:AngularFireAuth,
              private spinner: NgxSpinnerService,
              private toastr: ToastrService) { }

  ngOnInit() {
  }
  closeWithYesDialogBox(){
    this.spinner.show()
    this.auth.auth.sendPasswordResetEmail(this.email)
      .then(()=>{
        this.spinner.hide()
        this.dialogRef.close();
        this.toastr.success('Send Reset Link', `The Reset Link is Send To The ${this.email}`);
      })
      .catch((err)=>{
        this.spinner.hide()
        this.toastr.error('Error', err.message);
        console.log(err)
      })
  }

}
