import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import { AngularFireAuth } from '@angular/fire/auth';
import { User, IUser } from '../model/user';
import { FirebaseConstant } from '../../util/database';

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  constructor(
    private AUTHF: AngularFireAuth,
    private FIRSTORE: AngularFirestore
  ) {}

  loginWithEmail(loginData: IUser) {
    return this.AUTHF.auth.signInWithEmailAndPassword(
      loginData.email,
      loginData.password
    );
  }
  signOut(){
    return this.AUTHF.auth.signOut()
  }
  fetchUserInfo(uid: string) {
    return this.FIRSTORE.collection(FirebaseConstant.USER_COLL_REF)
      .doc(uid)
      .get();
  }
}
