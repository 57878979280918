import { Component, OnInit, ViewChild } from "@angular/core";
import { MatPaginator } from "@angular/material/paginator";
import { MatTableDataSource } from "@angular/material/table";
import { MatDialog } from "@angular/material";
import { AngularFireFunctions } from "@angular/fire/functions";
import { NgxSpinnerService } from "ngx-spinner";
import { UserService } from "../user/user.service";
import { MouseEvent } from "@agm/core";
import { ActivatedRoute } from "@angular/router";
import { SubdivisionService } from "../subdivision/subdivision.service";
import { Subdivision } from "../_models/subDivision";
import { HttpClient } from "@angular/common/http";
import { DialogAddMeetingComponent } from "../dialogBox/dialog-add-meeting/dialog-add-meeting.component";
import { MeetingService } from "../meeting/meeting.service";
import { Meeting } from "../_models/meeting";
import { MatSort } from "@angular/material/sort";
import {
  DeleteMetingDialogComponent,
  MeetingDialogData,
} from "../dialogBox/delete-meting-dialog/delete-meting-dialog.component";
import { DialogData } from "../dialogBox/confirmation-dialog/confirmation-dialog.component";
import { DELETE_MEETING_BODY } from "src/app/util/database";
import { DialogAddSubDivisionComponent } from "../dialogBox/dialog-add-sub-division/dialog-add-sub-division.component";
import { FilterData } from "../dialogBox/dialog-filter/dialog-filter.component";
import { DateRange } from "@uiowa/date-range-picker";

@Component({
  selector: "app-view-sub-division",
  templateUrl: "./view-sub-division.component.html",
  styleUrls: ["./view-sub-division.component.scss"],
})
export class ViewSubDivisionComponent implements OnInit {
  public displayedColumns: string[] = [
    "meetingType",
    "meetingDate",
    "meetingTime",
    "hostingSubDivisionName",
    "visitingSubDivisionId",
    "recurrenceDetail",
    "actionsColumn",
  ];
  private subdivisionList: Subdivision[] = [];
  private uniqueSubdivisionIDs: Set<string> = new Set();
  public dataSourceForTable;
  public isShowCalender = false;
  private meetingListData: Meeting[] = [];
  @ViewChild(MatPaginator, { static: false }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: false }) sort: MatSort;
  constructor(
    public dialog: MatDialog,
    private route: ActivatedRoute,
    private subDivisionService: SubdivisionService,
    private spinner: NgxSpinnerService,
    private meetingService: MeetingService,
    private fns: AngularFireFunctions
  ) {}
  public markers: Imarker[] = [];
  subDivision: Subdivision;
  // google maps zoom level
  public zoom = 8;
  public subDivisionID;
  // initial center position for the map
  public lat = 27.70169;
  public lng = 85.3206;
  public address: string;
  ngOnInit() {
    this.route.params.subscribe((params) => {
      this.fetchSubDivInfo(params.id);
      this.fetchMeetingOfSpecificSubDivision(params.id);
      this.subDivisionID = params.id;
    });
  }
  editMeeting(meetingData: Meeting) {
    const dialogRef = this.dialog.open(DialogAddMeetingComponent, {
      data: { action: "edit", meeting: meetingData },
      width: "450px",
      minHeight: "100px",
    });

    dialogRef.afterClosed().subscribe((result) => {
      console.log("The dialog was closed");
    });
  }

  dateIncrease() {
    const date = new Date(); // Now
    date.setDate(date.getDate() + 30); // Set now + 30 days as the new date
    console.log(date);
    return date;
  }

  fetchMeetingOfSpecificSubDivision(id: string) {
    this.spinner.show();
    
    this.meetingService
      .fetchMeetingFromApi({
        hostingSubDivisionIds: [],
        vistingSubDivisionIds: [id],
        meetingTypeName: [],
        duration: new DateRange(new Date(), this.dateIncrease()),
      } as FilterData)
      .subscribe((meetingList: any) => {
        meetingList.data.map((meeting) => {
          this.uniqueSubdivisionIDs.add(meeting.hostingSubDivisionId);
          return meeting.visitingSubDivisionId.forEach((idKey) => {
            this.uniqueSubdivisionIDs.add(idKey);
          });
        });
        try{
          this.getAllSubdivisions(() => {
          this.setDataToTable(meetingList.data);

        });
        }
        catch(err){
          console.log("err",err)
        }
        // this.getAllSubdivisions(() => {
        //   this.setDataToTable(data.data);
        // });
        // this.setDataToTable(data.data);

        this.spinner.hide();
      });

    // this.meetingService
    //   .fetchMeetingOfSpecificSubDivision(id, (meetingList) => {
    //     meetingList.map((meeting) => {
    //       this.uniqueSubdivisionIDs.add(meeting.hostingSubDivisionId);
    //       return meeting.visitingSubDivisionId.forEach((idKey) => {
    //         this.uniqueSubdivisionIDs.add(idKey);
    //       });
    //     });
    //     this.meetingListData = meetingList.sort((meeting1, meeting2) => {
    //       return (
    //         meeting1.meetingDate.getTime() - meeting2.meetingDate.getTime()
    //       );
    //     });
    //   })
    //   .then(() => {
    //     this.getAllSubdivisions(() => {
    //       this.setDataToTable(this.meetingListData);
    //     });
    //     this.spinner.hide();
    //   });
  }
  getAllSubdivisions(callback: () => void) {
    let count = 0;
    this.uniqueSubdivisionIDs.forEach((id) => {
      this.subDivisionService.fetchSpecifcSubDivision(id).then((snap) => {
        this.subdivisionList.push(snap);
        count++;
        if (count === this.uniqueSubdivisionIDs.size) {
          callback();
        }
      });
    });
  }
  deleteMeeting(meeting: Meeting) {
    const dialogData: MeetingDialogData = {
      title: "Delete Confirmation",
      body: DELETE_MEETING_BODY(meeting),
      isDeleteAble: true,
      deleteNumber: 0,
    };
    const dialogRef = this.dialog.open(DeleteMetingDialogComponent, {
      data: dialogData,
      width: "450px",
      minHeight: "100px",
    });
    dialogRef.afterClosed().subscribe((result: MeetingDialogData) => {
      if (result.isDeleteAble) {
        switch (result.deleteNumber) {
          case 1:
            return this.meetingService.deleteMeeting(meeting.key$).then(() => {
              this.meetingListData = [];
              this.fetchMeetingOfSpecificSubDivision(this.subDivisionID);
            });
          case 2:
            return this.fns
              .httpsCallable("deleteMeeting")({
                meetingNumber: 2,
                meetingID: meeting.key$,
              })
              .subscribe((res) => {
                this.meetingListData = [];
                this.fetchMeetingOfSpecificSubDivision(this.subDivisionID);
              });
          case 3:
            return this.fns
              .httpsCallable("deleteMeeting")({
                meetingNumber: 3,
                meetingID: meeting.key$,
              })
              .subscribe((res) => {
                this.meetingListData = [];
                this.fetchMeetingOfSpecificSubDivision(this.subDivisionID);
              });
          default:
            break;
        }
      }
    });
  }

  setDataToTable(listOfMeeting: Meeting[]) {
    this.dataSourceForTable = new MatTableDataSource<Meeting>(listOfMeeting);
    this.dataSourceForTable.paginator = this.paginator;
    this.dataSourceForTable.sort = this.sort;
  }
  addMeeting() {
    const dialogRef = this.dialog.open(DialogAddMeetingComponent, {
      width: "450px",
      minHeight: "200px",
    });

    dialogRef.afterClosed().subscribe((result) => {
      this.spinner.show();
      setTimeout(() => {
        this.spinner.hide();
        this.fetchMeetingOfSpecificSubDivision(this.subDivisionID);
      }, 4000);
    });
  }
  fetchSubDivInfo(id) {
    this.subDivisionService.fetchSpecifcSubDivision(id).then((doc) => {
      this.subDivision = doc;
      this.lat = doc.location.latitude;
      this.lng = doc.location.longitude;
      this.markers.push({
        lat: doc.location.latitude,
        lng: doc.location.longitude,
        draggable: false,
      });
    });
  }
  getSubdivision(id: string) {
    const subdivision = this.subdivisionList.find((x) => x.key$ === id);
    return subdivision.subDivisionName;
  }
  applyFilter(filterValue: string) {
    this.dataSourceForTable.filter = filterValue.trim().toLowerCase();
  }
  clickedMarker(label: string, index: number) {
    console.log(`clicked the marker: ${label || index}`);
  }

  mapClicked($event: MouseEvent) {
    if (this.markers.length === 0) {
      this.markers.push({
        lat: $event.coords.lat,
        lng: $event.coords.lng,
        draggable: true,
      });
    }
  }

  markerDragEnd(m: Imarker, $event: MouseEvent) {
    console.log("dragEnd", m, $event);
  }

  editSubDivision() {
    const dialogRef = this.dialog.open(DialogAddSubDivisionComponent, {
      data: { action: "edit", subDivision: this.subDivision },
      width: "450px",
      minHeight: "100px",
    });

    dialogRef.afterClosed().subscribe((result) => {
      console.log("The dialog was closed");
    });
  }
}
interface Imarker {
  lat: number;
  lng: number;
  label?: string;
  draggable: boolean;
}
