import { Component, OnInit, ViewChild } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { MatDialog, MatSort } from '@angular/material';
import { DialogAddLocalityComponent } from '../dialogBox/dialog-add-locality/dialog-add-locality.component';
import { LocalityService } from './locality.service';
import { Locality, ILocality } from '../_models/locality';
// tslint:disable-next-line:max-line-length
import { DialogShowAssociatedSubDivsComponent } from '../dialogBox/dialog-show-associated-sub-divs/dialog-show-associated-sub-divs.component';
import {
  ConfirmationDialogComponent,
  DialogData
} from '../dialogBox/confirmation-dialog/confirmation-dialog.component';
import {
  DELETE_LOCALITY_CONFIRAMTION_BODY,
  DELETE_LOCALITY_TITLE,
  DELETE_LOCALITY_BODY
} from 'src/app/util/database';
import { NgxSpinnerService } from 'ngx-spinner';
import { MatTableExporterDirective } from 'mat-table-exporter';
@Component({
  selector: 'app-locality',
  templateUrl: './locality.component.html',
  styleUrls: ['./locality.component.scss']
})
export class LocalityComponent implements OnInit {
  displayedColumns: string[] = ['name', 'viewColumn', 'actionsColumn'];
  dataSourceForTable;
  @ViewChild(MatPaginator, { static: false }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: false }) sort: MatSort;
  @ViewChild(MatTableExporterDirective, { static: false })
  exporter: MatTableExporterDirective;
  constructor(
    public dialog: MatDialog,
    private localityService: LocalityService,
    private spinner: NgxSpinnerService
  ) {}
  tempMeeting = [];

  ngOnInit() {
    this.fetchLocality();
    // this.testDb
    //   .collection('meetings', ref => ref.where('isParent', '==', true))
    //   .snapshotChanges()
    //   .subscribe(res => {
    //     this.tempMeeting = [];
    //     res.forEach(doc => {
    //       this.tempMeeting.push(doc.payload.doc.data());
    //     });
    //     if (this.tempMeeting[0].recurrenceDetail === 'custom') {
    //       if (this.tempMeeting[0].repeatBy === 'day') {
    //         this.collectdataForDay(this.tempMeeting[0]);
    //       } else if (this.tempMeeting[0].repeatBy === 'year') {
    //         this.collectdataForYear(this.tempMeeting[0]);
    //       } else if (this.tempMeeting[0].repeatBy === 'week') {
    //         this.collectdataForWeek(this.tempMeeting[0]);
    //       } else if (this.tempMeeting[0].repeatBy === 'month') {
    //         this.collectdataForMonth(this.tempMeeting[0]);
    //       }
    //     }
    //   });
  }

  exportToExcel() {
    this.exporter.exportTable('xlsx', {fileName: 'Localities'} );
  }
  exportToCSV() {
    this.exporter.exportTable('csv', {fileName: 'Localities'});
  }

  editLocality(locality: Locality) {
    const dialogRef = this.dialog.open(DialogAddLocalityComponent, {
      width: '450px',
      minHeight: '100px',
      data: locality
    });

    dialogRef.afterClosed().subscribe(result => {
      console.log('The dialog was closed');
    });
  }
  fetchLocality() {
    this.spinner.show();
    this.localityService.fetchLocalities().subscribe(res => {
      const listOfAdmin: Locality[] = [];
      res.forEach(doc => {
        listOfAdmin.push(
          new Locality(doc.payload.doc.data() as ILocality, doc.payload.doc.id)
        );
      });
      this.setDataToTable(listOfAdmin);
      this.spinner.hide();
    });
  }
  fetchSpecificSubDivs(locality: Locality) {
    const dialogRef = this.dialog.open(DialogShowAssociatedSubDivsComponent, {
      width: '400px',
      minHeight: '100px',
      data: locality
    });

    dialogRef.afterClosed().subscribe(result => {
      console.log('The dialog was closed');
    });
  }

  deleteLocality(locality: Locality) {
    const dialogData: DialogData = { title: '', body: '', isDeleteAble: false };
    dialogData.title = DELETE_LOCALITY_TITLE;
    this.localityService.hasAssociatiedSubdivisoin(locality.key$).then(res => {
      if (res) {
        dialogData.body = DELETE_LOCALITY_CONFIRAMTION_BODY;
      } else {
        dialogData.body = DELETE_LOCALITY_BODY(locality);
        dialogData.isDeleteAble = true;
      }
      const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
        width: '400px',
        height: 'auto',
        data: dialogData
      });

      dialogRef.afterClosed().subscribe(result => {
        if (result.isDeleteAble) {
          this.localityService
            .deleteLocality(locality.key$)
            .then(() => {})
            .catch(err => {});
        }
      });
    });
  }
  setDataToTable(listOfAdmin: Locality[]) {
    this.dataSourceForTable = new MatTableDataSource<Locality>(listOfAdmin);
    this.dataSourceForTable.paginator = this.paginator;
    this.dataSourceForTable.sort = this.sort;
  }
  applyFilter(filterValue: string) {
    this.dataSourceForTable.filter = filterValue.trim().toLowerCase();
  }
  addLocality() {
    const dialogRef = this.dialog.open(DialogAddLocalityComponent, {
      width: '450px',
      minHeight: '100px'
    });

    dialogRef.afterClosed().subscribe(result => {
      console.log('The dialog was closed');
    });
  }
}
