import { Renderer2 } from '@angular/core';
import { Component, OnInit } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/auth';
import { Router } from '@angular/router';
import { collapseAnimation } from 'angular-calendar';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss']
})
export class DashboardComponent implements OnInit {

  public currentUser: any;
  hideMenu

  constructor(private fireAuth: AngularFireAuth, private ROUTER: Router, private renderer: Renderer2) { }

  async ngOnInit() {
    await this.fireAuth.user.subscribe(res => {
      this.currentUser = res;
    });
  }
  logout() {
    this.fireAuth.auth.signOut().then(() => {
      localStorage.removeItem('token')
      this.ROUTER.navigate(['/login']);
    });
  }

  showAndHideMenu() {
    this.hideMenu = !this.hideMenu;
    if (!this.hideMenu) {
      this.hideMenu = false;
      let data = document.getElementsByClassName('hiddenMenu')
      const child = data[0];
      this.renderer.setStyle(child, 'display', 'block');
    } else {
      this.hideMenu = true;
      let data = document.getElementsByClassName('hiddenMenu')
      const child = data[0];
      this.renderer.setStyle(child, 'display', 'block');

    }

  }

}
