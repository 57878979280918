import { Meeting, IMeeting } from './meeting';

export enum RecurranceType {
    none,
    daily,
    weeklyOnDay,
    monthlyOnDay,
    anuallyOnDay,
    everyWeekday,
    custom
}
export interface IUpcomingMeeting {
    createdDate: Date;
    visitingSubDivisionId: Array<string>;
    hostingSubDivisionId: string;
    meetingType: string;
    meetingDate: any;
    meetingTime: string;
    isRepeating: boolean;
    repeatingNumber: number;
    repeatBy: string;
    repeatOn: Array<number>;
    isEnding: boolean;
    endOn?: any;
    endAfter: number;
    events: Array<Date>;
    status?: string;
    isParent: boolean;
    parentID?: string;
    upcommingMeetingID?: string;
    recurrenceDetail: string;
    endType: string;
    monthlyRepeatingType: string;
}

export class UpcomingMeeting extends Meeting {
    upcommingMeetingID?: string;
    constructor(data: IUpcomingMeeting, id: string) {
        const newData = data as IMeeting;
        super(newData, id);
        this.upcommingMeetingID = data.upcommingMeetingID;
    }
}
