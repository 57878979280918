export interface ILocality {
  localityName: string;
  status: string;
}
export class Locality implements ILocality {
  key$: string;
  status: string;
  localityName: string;
  constructor(locality: ILocality, id: string) {
    this.key$ = id;
    this.localityName = locality.localityName;
    this.status = locality.status;
  }
}
